var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ethos-container",class:{
    bordered: (!_vm.hasChildRoute || _vm.reuse) && !_vm.noBorder,
    'm-3': !_vm.hasChildRoute,
  }},[(!_vm.reuse)?_c('router-view'):_vm._e(),_c('add-new-evidence',{ref:"evidenceForm",attrs:{"case-id":_vm.caseId,"project-id":_vm.projectId,"header":"Add !!!"}}),(!_vm.hasChildRoute || _vm.reuse)?_c('div',{staticClass:"paginated-table"},[(!_vm.reuse)?_c('div',{staticClass:"my-3 d-flex align-items-center"},[_c('h2',{staticClass:"mr-3 d-inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.evidenceNameLabel)+" ")]),_c('base-button',{staticClass:"m-0",attrs:{"size":"sm","type":"primary","link":""},on:{"click":_vm.showEvidence}},[_vm._v(" Add New "+_vm._s(_vm.evidenceNameLabel)+" "),_c('plus-icon',{staticClass:"ml-3",attrs:{"size":"1.5x"}})],1)],1):_vm._e(),_c('el-table',{class:{
        'mt-4': _vm.reuse,
      },style:(!_vm.noBorder ? 'width: 100%' : ''),attrs:{"data":_vm.tableData},on:{"row-click":function (row) { return _vm.handleEvidenceView(row); }}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name","min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return _c('div',{},[(['In Progress'].includes(row.__status))?_c('div',[_vm._v(" Processing "),_c('base-progress',{staticClass:"w-100",attrs:{"type":'primary',"value":row.__progress,"value-position":"none"}})],1):_c('div',[_vm._v(" "+_vm._s(row.name)+" ")])])}}],null,false,333936366)}),_c('el-table-column',{attrs:{"prop":"originalFileName","label":"Original File Name","min-width":"300","sortable":""}}),_c('el-table-column',{attrs:{"label":"Created On","width":"250","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$date(scope.row.createdOn).tz(_vm.userTimezone).format("MMM DD, YYYY [at] hh:mm a"))+" ")]}}],null,false,4063497971)}),_c('el-table-column',{attrs:{"prop":"createdBy","label":"Created By","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"modifiedOn","label":"Modified On","width":"250","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$date(scope.row.modifiedOn).tz(_vm.userTimezone).format("MMM DD, YYYY [at] hh:mm a"))+" ")]}}],null,false,2817931346)}),_c('el-table-column',{attrs:{"prop":"modifiedBy","label":"Modified By","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"align":"center","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('base-button',{attrs:{"size":"sm","type":"primary","simple":""},on:{"click":function($event){return _vm.handleEvidenceView(scope.row)}}},[_vm._v(" View "+_vm._s(_vm.evidenceNameLabel)+" ")])]}}],null,false,466563279)})],1),(_vm.caseId === null)?_c('table-pagination',{ref:"pagination",attrs:{"pagination":_vm.pagination},on:{"changed":_vm.handlePaginationChange}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }