import {getItemIndex} from "src/util/util";

const getters = {
  searchRadioOpts: (state) => {
    return state.searchRadioOpts;
  },
  config: (state) => {
    return state.config;
  },
  autoApprovalRoles: (state) => {
    return state.config.autoApprovalRoles;
  },
  caseNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "caseNameLabel");
    return state.config && state.config.labels[index].value;
  },
  casesNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "casesNameLabel");
    return state.config && state.config.labels[index].value;
  },
  projectNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "projectNameLabel");
    return state.config && state.config.labels[index].value;
  },
  evidenceNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "evidenceNameLabel");
    return state.config && state.config.labels[index].value;
  },
  peopleNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "peopleNameLabel");
    return state.config && state.config.labels[index].value;
  },
  personNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "personNameLabel");
    return state.config && state.config.labels[index].value;
  },
  starredNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "starredNameLabel");
    return state.config && state.config.labels[index].value;
  },
  incidentNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "incidentNameLabel");
    return state.config && state.config.labels[index].value;
  },
  timelineNameLabel: (state) => {
    const index = getItemIndex(state.config.labels, "timelineNameLabel");
    return state.config && state.config.labels[index].value;
  },
  displayNotes: (state) => {
    return state.config && state.config.displayNotes === true;
  },
  displayIncidents: (state) => {
    return state.config && state.config.displayIncidents === true;
  },
  displayIncidentColors: (state) => {
    return state.config && state.config.displayIncidentColors === true;
  },
  getConfigLabels: (state) => {
    return state.config && state.config.labels;
  },
  userTimezone: (state) => {
    return state.config && state.config.timeZone;
  },
  availableLanguages: (state) => {
    return state.config && state.config.availableLanguages;
  },
  availableClipStates: (state) => {
    return state.config && state.config.availableClipStates || [];
  },
  availableWorkflows: (state) => {
    return state.config && state.config.availableWorkflows;
  },
  selectedWorkflows: (state) => {
    return state.config && state.config.selectedWorkflows;
  },
  availableGroupedWorkflows: (state) => {
    return state.config && state.config.availableGroupedWorkflows;
  },
  availableResultWorkflows: (state) => {
    return state.config && state.config.availableResultWorkflows;
  },
  selectedResultWorkflows: (state) => {
    return state.config && state.config.selectedResultWorkflows;
  },
  availablePeopleGroups: (state) => {
    return state.config && state.config.availablePeopleGroups || [];
  },
  availableCaseRoles: (state) => {
    return state.config && state.config.availableCaseRoles || [];
  },
  highlightPaths: (state, getters) => {
    return state.highlightPaths;
  },
  getEvidence: (state, getters) => {
    return state.evidence;
  },
  getCases: (state, getters) => {
    return state.cases;
  },
  getCaseSummaries: (state, getters) => {
    return state.caseSummaries;
  },
  getCaseEvidenceSummaries: (state, getters) => {
    return state.caseEvidenceSummaries;
  },
  getCaseClipSummaries: (state, getters) => {
    return state.caseClipSummaries;
  },
  getCaseActorAttributions: (state, getters) => {
    return state.caseActorAttributions;
  },
  getCaseUserAttributions: (state, getters) => {
    return state.caseUserAttributions;
  },
  getActors: (state, getters) => {
    return state.actors;
  },
  getActorMap: (state, getters) => {
    return state.actorMap;
  },
  getUserMap: (state, getters) => {
    return state.userMap;
  },
  getTranscriptions: (state, getters) => {
    return state.transcriptions;
  },
  getSearches: (state, getters) => {
    return state.searches;
  },
  visibleSearches(state, getters) {
    return getters.getSearches.filter((s) => s.active);
  },
  visibleEvidenceIds(state, getters) {
    return getters.visibleSearches.reduce((t, s) => {
      if (!s || !s.evidence) return t;
      s.evidence.forEach((id) => {
        if (t.indexOf(id) === -1) t.push(id);
      });
      return t;
    }, []);
  },
  visibleEvidence(state, getters) {
    return getters.visibleEvidenceIds.map((id) => state.evidence[id]);
  },
  visibleCaseIds(state, getters) {
    return getters.visibleSearches.reduce((t, s) => {
      if (!s || !s.cases) return t;
      s.cases.forEach((id) => {
        if (t.indexOf(id) === -1) t.push(id);
      });
      return t;
    }, []);
  },
  visibleCases(state, getters) {
    return getters.visibleCaseIds.map((id) => state.cases[id]);
  },
  visibleTranscriptionIds(state, getters) {
    return getters.visibleSearches.reduce((t, s) => {
      if (!s || !s.transcriptions) return t;
      s.transcriptions.forEach((id) => {
        if (t.indexOf(id) === -1) t.push(id);
      });
      return t;
    }, []);
  },
  visibleTranscriptions(state, getters) {
    return getters.visibleTranscriptionIds.map((id) => state.transcriptions[id]);
  },
  getSearchKeyword: (state, getters) => {
    return state.searchKeyword;
  },
  getUploadResponse: (state, getters) => {
    return state.uploadResponses;
  },
  getWorkflowResponse: (state, getters) => {
    return state.workflowResponses;
  },
  evidenceListRefreshToken: (state, getters) => {
    return state.evidenceListRefreshToken;
  },
  loadedPeople: (state) => {
    return state.loadedPeople;
  },
  loadedPeopleGroups: (state) => {
    return state.loadedPeopleGroups;
  },
  peopleList: (state) => {
    return state.people;
  },
  peopleByGroup: (state, getters) => {
    // Prep folders
    const grouped = {};
    getters.availablePeopleGroups.forEach((g) => grouped[g] = []);

    // Map people
    Object.keys(state.people).forEach((id) => {
      const p = state.people[id];
      p.folders && p.folders.split(",").forEach((f) => f && f.length > 1 && grouped[f] && grouped[f].push(p));
    });

    return grouped;
  },
  breadcrumbHints: (state) => {
    return state.breadcrumbHints;
  },
  codeWordGroups: (state) => {
    return state.codeWordGroups;
  },
};

export default getters;
