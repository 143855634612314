<template>
  <div
    class="text-white w-100"
  >
    <case-back-div
      class="mb-5"
      to="PeopleV2"
    />

    <template
      v-if="people"
    >
      <div class="mb-3 mt-4">
        <label
          for="real-name"
          class="form-label"
        > Full Name </label>
        <input
          id="real-name"
          v-model="fullName"
          type="text"
          class="form-control"
          placeholder="Full Name"
        >
      </div>

      <div
        v-if="userType === 'subject'"
        class="mb-3"
      >
        <label
          for="display-name"
          class="form-label"
        > Aliases (comma-separated)</label>
        <input
          id="display-name"
          v-model="aliasCsv"
          type="text"
          class="form-control"
          placeholder="Aliases"
        >
      </div>

      <div
        v-if="false"
        class="mb-5"
      >
        <label
          for="description"
          class="form-label"
        > Description </label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control"
          placeholder="Description"
        >
      </div>

      <div class="mb-5 row">
        <div class="col">
          <h4>{{ casesNameLabel }}</h4>
          <div
            v-if="people && people.cases && people.cases.length > 0"
            class="d-flex flex-wrap"
          >
            <el-tag
              v-for="c in people.cases"
              :key="c.id"
              class="cursor-pointer"
              effect="dark"
              type="primary"
              @click="handleCase(c.id)"
            >
              {{ c.name }}
            </el-tag>
          </div>
          <div v-else>
            No assigned {{ casesNameLabel }}
          </div>
        </div>
        <div class="col">
          <h4>{{ evidenceNameLabel }}</h4>
          <div
            v-if="people && people.evidence && people.evidence.length > 0"
            class="d-flex flex-wrap"
          >
            <el-tag
              v-for="ev in people.evidence"
              :key="ev.id"
              class="cursor-pointer"
              effect="dark"
              type="gray"
              @click="handleEvidence(ev.id)"
            >
              {{ ev.name }}
            </el-tag>
          </div>
          <div v-else>
            No assigned {{ evidenceNameLabel }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col">
          <h4> Created On </h4>
          <p>{{ $date(people.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}</p>
        </div>
        <div class="col">
          <h4> Modified On </h4>
          <p>{{ $date(people.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}</p>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col">
          <h4> Created By </h4>
          <p>{{ people.createdBy }}</p>
        </div>
        <div class="col">
          <h4>Modified By</h4>
          <p>{{ people.modifiedBy }}</p>
        </div>
      </div>

      <div
        v-if="false"
        class="row"
      >
        <base-button
          class="mr-auto ml-3"
          type="secondary"
          simple
          :disabled="!hasChanges"
          :loading="saving"
          @click="reset"
        >
          Discard Changes
        </base-button>
        <base-button
          class="ml-auto mr-3"
          type="primary"
          :disabled="!hasChanges"
          :loading="saving"
          @click="save"
        >
          Save Changes
        </base-button>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {getPeopleById, updateIndividual} from "../../../../api";
import {isDefined} from "../../../../api/helpers";
import BaseButton from "../../../../components/BaseButton.vue";
import CaseBackDiv from "../../../../components/DashboardV2/Case/CaseBackDiv.vue";
export default {
  components: {BaseButton, CaseBackDiv},
  name: "people-individual",
  props: {
    people: {
      type: Object,
      default() {
        return null;
      },
    },
    userType: {
      type: String,
      default: null,
    },
  },
  watch: {
    people: {
      handler(n, o) {
        if (n && (!o || n.id !== o.id)) {
          this.reset();
          this.reload();
        }
      },
      immediate: true, // Fire on load
    },
  },
  computed: {
    ...mapGetters("data", [
      "casesNameLabel",
      "evidenceNameLabel",
      "userTimezone",
    ]),
    hasChanges() {
      if (!this.people) return false;
      return (
        this.fullName !== this.people.fullName ||
        this.aliasCsv !== this.people.aliasCsv ||
        this.description !== this.people.description
      );
    },
  },
  data() {
    return {
      saving: false,
      updating: false,
      fullName: "",
      aliasCsv: "",
      description: "",
    };
  },
  methods: {
    ...mapMutations("data", ["putPeople"]),
    reload() {
      const id = this.people && this.people.id;
      if (!isDefined(id)) return false;
      this.updating = true;
      getPeopleById(id).then((p) => {
        this.putPeople(p);
        const currentId = this.people && this.people.id;
        if (currentId === id || !isDefined(currentId)) {
          this.$emit("updated", p);
          this.updating = false;
        }
      }).catch((ex) => {
        this.$notifyError(`Unable to load more information for ${people.fullName}`, ex);
        if (currentId === id || !isDefined(currentId)) {
          this.updating = false;
        }
      });
    },
    reset() {
      if (!this.people) return false;
      this.fullName = this.people.fullName;
      this.aliasCsv = this.people.aliasCsv;
      this.description = this.people.description;
    },
    // This function called when you make changes and save.
    save() {
      const people = this.people;
      const id = people && people.id;
      if (this.saving || !people || !isDefined(id)) return;
      this.saving = true;
      const aliasCsv = this.aliasCsv !== people.aliasCsv ? this.aliasCsv : null;
      const fullName = this.fullName !== people.fullName ? this.fullName : null;
      const description = this.description !== people.description ? this.description : null;
      updateIndividual(id, fullName, aliasCsv, description, null, null, null, null, null, null).then((p) => {
        this.$notifySuccess(`Successfully Updated ${people.fullName}`);
        this.putPeople([p]);
        this.$emit("updated", p);
        this.saving = false;
      }).catch((ex) => {
        this.$notifyError(`Unable to Update ${people.fullName}`, ex);
        this.saving = false;
      });
    },
    handleCase(caseId) {
      this.$router.push(`/v2/case/${caseId}`);
    },
    handleEvidence(evidenceId) {
      this.$router.push(`/v2/case/${caseId}/view-evidence/${evidenceId}`);
    },
  },
};
</script>
