<template>
  <div>
    <div
      v-if="!user || !user.email"
      class="mb-3"
    >
      <label
        for="email"
        class="form-label"
      > Email </label>
      <input
        id="email"
        v-model="email"
        type="email"
        class="form-control"
        placeholder="Email"
      >
    </div>

    <div class="mb-3">
      <label
        for="title"
        class="form-label"
      > Title </label>
      <input
        id="title"
        v-model="title"
        type="text"
        class="form-control"
        placeholder="Title"
      >
    </div>

    <div class="mb-3">
      <label
        for="first"
        class="form-label"
      > First Name </label>
      <input
        id="first"
        v-model="firstName"
        type="text"
        class="form-control"
        placeholder="First Name"
      >
    </div>

    <div class="mb-3">
      <label
        for="last"
        class="form-label"
      > Last Name </label>
      <input
        id="last"
        v-model="lastName"
        type="text"
        class="form-control"
        placeholder="Last Name"
      >
    </div>

    <el-select
      v-model="roles"
      class="select-primary dark"
      effect="dark"
      popper-class="select-primary"
      :placeholder="rolesPlaceholder"
      multiple
    >
      <el-option
        v-for="item in roleOptions"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>

    <div class="d-flex justify-content-between mt-4">
      <base-button
        v-if="canDisableUser"
        :loading="isLoading"
        size="sm"
        type="error"
        class="btn-fixed"
        @click="disableUser"
      >
        Disable
      </base-button>
      <div v-else />
      <base-button
        class="btn-fixed"
        :loading="isLoading"
        :disabled="saveIsDisabled"
        size="sm"
        type="primary"
        @click="save"
      >
        Save
      </base-button>
    </div>
  </div>
</template>

<script>
import {deleteUser, putUser} from "../../../../api";
import {Roles} from "../../../../util/enums";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      email: "",
      title: "",
      firstName: "",
      lastName: "",
      roles: [],
      roleOptions: [Roles.admin, Roles.editor, Roles.viewer],
    };
  },
  computed: {
    saveIsDisabled() {
      return !this.email || this.email.length < 5 || this.roles.length < 1;
    },
    canDisableUser() {
      return this.user && this.user.roles.length > 0;
    },
    rolesPlaceholder() {
      return !this.user || this.canDisableUser ? "Assign Roles" : "Assign Roles To Enable";
    },
  },
  mounted() {
    if (!this.user) return;
    this.email = this.user.email;
    this.title = this.user.title;
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.roles = this.user.roles;
  },
  methods: {
    save() {
      if (this.user) {
        // Patch?
      } else {
        // Put

      }
      if (this.isLoading) return;
      this.isLoading = true;
      putUser(this.email, this.title, this.firstName, this.lastName, this.roles)
        .then((response) => {
          this.$notifySuccess(`${this.email} ${this.user ? "Updated" : "Added"} Successfully`);
          this.$emit("complete", response);
        })
        .catch((ex) => {
          this.$notifyError("Adding New User Failed", ex);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    disableUser() {
      if (this.isLoading) return;
      this.isLoading = true;
      deleteUser(this.user.email)
        .then((response) => {
          this.$notifySuccess(`${this.email} Deleted Successfully`);
          this.$emit("deleted", this.email);
        })
        .catch((ex) => {
          this.$notifyError("Deleting User Failed", ex);
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
