export const ethosRouteNames = {
  Home: "Home",
  Cases: "Cases",
  Case: "Case",
  Contact: "Contact",
  Error: "Error",
  Project: "Project",
  ProjectEvidence: "Project_Evidence",
  ProjectPeople: "Project_People",
  Evidence: "Evidence",
  EvidencePeople: "Evidence_People",
  EvidenceNotes: "Evidence_Notes",
  UploadManager: "UploadManager",
  ViewEvidence: "ViewEvidence",
  Transcript: "Transcript",
  Incidents: "Incidents",
  Notes: "Notes",
  Signals: "Signals",
  People: "People",
  Search: "Search",
  Settings: "Settings",
  Support: "Support",
  Clips: "Clips",
  Clip: "Clip",
  Admin: "Admin",
  UserManagement: "UserManagement",
  MainContent: "MainContent",
  Sidebar: "Sidebar",
  DashboardV2: "Dashboard",
  NewCase: "NewCase",
  ViewCase: "ViewCase",
  CaseEvidence: "CaseEvidence",
  CaseKeywords: "CaseKeywords",
  CaseExhibits: "CaseExhibits",
  CasePeople: "CasePeople",
  CaseNotes: "CaseNotes",
  CaseEvidenceList: "CaseEvidenceList",
  CaseViewEvidence: "CaseViewEvidence",
  CaseSearch: "CaseSearch",
  CaseV2: "CaseV2",
  PeopleV2: "PeopleV2",

  TranscriptV2: "TranscriptV2",
  IncidentsV2: "IncidentsV2",
  NotesV2: "NotesV2",
  EvidenceNotesV2: "Evidence_NotesV2",
  EvidencePeopleV2: "Evidence_PeopleV2",
  SignalsV2: "SignalsV2",
  PeopleV2: "PeopleV2",
  SearchV2: "SearchV2",
  SettingsV2: "SettingsV2",
  SupportV2: "SupportV2",
  ClipsV2: "ClipsV2",
  ClipV2: "ClipV2",
  AdminV2: "AdminV2",
  UserManagementV2: "UserManagementV2",
};
