import {authenticate, postEvidence, runWorkflow, getWorkflow} from "../api";
import {bytesToMegaBytes} from "src/util/util";
import {v4 as uuidv4} from "uuid";
import {isDefined} from "../api/helpers";

const actions = {
  async authenticateAsync({commit}) {
    const auth = await authenticate();
    const noConfig = Object.assign({}, auth);
    if (noConfig.config) delete noConfig.config;
    commit("auth/setAuth", noConfig);
    commit("data/setConfig", auth && auth.config);
  },

  async runWorkflows({commit}, {evidenceId, evidenceName, workflows, dateAdded}) {
    await doRunWorkflows(commit, evidenceId, evidenceName, workflows, dateAdded);
  },

  async uploadEvidence(
    {commit},
    {
      id,
      name,
      language,
      selectedCase,
      minSpeakerCount,
      maxSpeakerCount,
      storagePath,
      file,
      lastModifiedDate,
      dateAdded,
      workflows,
      notify,
    }
  ) {
    const notification = {
      id: dateAdded,
      closeOnClick: false,
      showClose: false,
      persist: true,
      count: 1,
      title: "Uploading Files",
      message: "Please do not refresh the page until completed. You may use a second tab for other tasks.",
    };
    notify.$n(notification);
    const payload = {
      id,
      dateAdded,
      name: name && name.length > 0 ? name : file.name,
      size: bytesToMegaBytes(file.size),
      status: "Queued",
      progress: 0,
      evidenceId: null,
    };
    let fakePreparingProgress = null;
    try {
      commit("data/setUploadResponse", payload);
      const uploadProgressCb = (e) => doUploadProgress(e, payload);
      const uploadController = new AbortController();
      const uploadOpts = {
        uploadProgressCb,
        uploadController,
      };
      payload.uploadController = uploadController;
      // fake progress is used for preparing step which occurs after the upload.
      // during preparing, the server is then prepping file and uploading to s3
      let step = 0.3;
      let curr = 0;
      fakePreparingProgress = window.setInterval(() => {
        if (payload.status == "Preparing") {
          // payload.progress = Math.min(99, payload.progress + Math.floor(Math.random() * 2) + 1);
          curr += step;
          payload.progress = Math.round(Math.atan(curr) / (Math.PI / 2) * 100 * 1000) / 1000;
          if (curr >= 40) {
            step = 0.1;
          }
        }
        commit("data/setUploadResponse", payload);
      }, 1000);
      const response = await postEvidence(
        name && name.length > 0 ? name : null,
        language && language.length > 0 ? language : null,
        selectedCase && selectedCase.length > 0 ?
          selectedCase :
          null,
        minSpeakerCount,
        maxSpeakerCount,
        storagePath && storagePath.length > 0 ?
          storagePath :
          null,
        file,
        lastModifiedDate,
        uploadOpts
      );

      window.clearInterval(fakePreparingProgress);
      payload.status = "Complete";
      payload.progress = 100;
      payload.evidenceId = response.id;
      commit("data/setUploadResponse", payload);
      commit("data/setEvidenceAdded", payload.evidenceId);
      await doRunWorkflows(commit, response.id, payload.name, workflows, dateAdded);
    } catch (ex) {
      if (fakePreparingProgress) {
        window.clearInterval(fakePreparingProgress);
      }
      if (ex?.code == "ERR_CANCELED") {
        payload.status = "Canceled";
      } else {
        payload.status = "Failed";
        console.error("failed to upload evidence", ex);
      }
      payload.progress = 100;
      commit("data/setUploadResponse", payload);
    }
    try {
      notify.$s.decrement(notification.id);
    } catch (ex) {
      console.error("Failed to decrement counter", ex);
    }
  },

  async pollWorkflow({commit}, workflowPayload) {
    if (workflowPayload && workflowPayload.workflowId) {
      try {
        const result = await getWorkflow(workflowPayload.workflowId);

        if (result.failureReason) throw new Error(result.failureReason);
        if (result.completeTime) {
          console.log(`Workflow: ${workflowPayload.name} - Completed at: ${result.completeTime}`);
          workflowPayload.status = "Complete";
          workflowPayload.progress = 100;
          commit("data/setWorkflowResponse", workflowPayload);
        } else {
          workflowPayload.progress = Math.min(99, workflowPayload.progress + Math.floor(Math.random() * 9) + 1);
          console.log(`Workflow: ${workflowPayload.name} - ${workflowPayload.progress}% - Last Checked at: ${new Date()}`);
          commit("data/setWorkflowResponse", workflowPayload);
          return false;
        }
      } catch (ex2) {
        // Mark as failure
        console.error(`Workflow: ${workflowPayload.name} - Failed to complete`, ex2);
        workflowPayload.status = "Failed";
        workflowPayload.progress = 100;
        commit("data/setWorkflowResponse", workflowPayload);
      };
      return true;
    }
  },
};

const doUploadProgress = (e, item) => {
  if (e.progress > 0 && e.progress < 1) item.status = "Uploading";
  item.progress = parseInt( e.progress * 100 );
  if (e.progress == 1) {
    item.status = "Preparing";
    item.progress = 0;
  }
};

const doRunWorkflows = async (commit, evidenceId, evidenceName, workflows, dateAdded) => {
  if (
    !isDefined(commit) ||
    !isDefined(evidenceId) ||
    !isDefined(evidenceName) ||
    !isDefined(workflows) ||
    !Array.isArray(workflows) ||
    workflows.length < 1
  ) return;

  // Run workflows if there are any
  const workflowPromises = [];
  for (let i = 0; i < workflows.length; i++) {
    const workflowName = workflows[i];

    // Update the payload to confirm we're working on this workflow
    const workflowPayload = {
      id: uuidv4(),
      evidenceId: evidenceId,
      evidenceName: evidenceName,
      dateAdded,
      name: workflowName,
      status: "Requesting",
      progress: 0,
      workflowId: null,
    };
    commit("data/setWorkflowResponse", workflowPayload);

    // Trigger the workflow
    const workflowPromise = runWorkflow(
      workflowName.replace(/\W/g, ""),
      evidenceId
    ).then((workflowId) => {
      // Mark as successful
      console.log(`Workflow: ${workflowName} - now running with ref: ${workflowId}`);
      workflowPayload.workflowId = workflowId;
      workflowPayload.status = "In Progress";
      workflowPayload.progress = Math.floor(Math.random() * 11);
      commit("data/setWorkflowResponse", workflowPayload);
      return Promise.resolve(workflowId);
    }).catch((ex2) => {
      // Mark as failure
      console.error(`Workflow: ${workflowName} - Failed to run`, ex2);
      workflowPayload.status = "Failed";
      workflowPayload.progress = 100;
      commit("data/setWorkflowResponse", workflowPayload);
    });

    // Store the promise
    workflowPromises.push(workflowPromise);
  }

  // Await all workflow outcomes
  await Promise.allSettled(workflowPromises);
};

export default actions;
