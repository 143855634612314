<template>
  <div v-if="caseModel">
    <div class="d-flex p-5 items-center align-items-center">
      <h6 class="text-white mb-0">
        {{ caseItem.title }}
      </h6>
      <div class="flex-grow-1" />
      <base-button
        type="simple"
        class="m-0"
        @click="openCase"
      >
        Open Case
        <material-icon class="ml-1">
          arrow_forward
        </material-icon>
      </base-button>
    </div>
    <hr class="h-divider m-0">
    <div class="d-flex p-5 items-center align-items-center">
      <div>
        <h6 class="text-white mb-0">
          Case Status
        </h6>
        <p class="mb-0 text-lightblue">
          As of 1 minute ago
        </p>
      </div>
      <div class="flex-grow-1" />
      <p class="mr-1 mb-0 text-lightblue">
        Cards per page: 4
      </p>
      <material-icon
        class="ml-1 text-white"
        size="30px"
      >
        chevron_left
      </material-icon>
      <material-icon
        class="ml-1 text-white"
        size="30px"
      >
        chevron_right
      </material-icon>
    </div>
    <div
      class="px-2 pb-6 row ml-0"
      style="width: 100%"
    >
      <div
        v-for="(item, i) in 4"
        :key="i"
        class="col"
      >
        <case-status-card-item />
      </div>
    </div>
    <hr class="h-divider m-0">
    <div class="p-5">
      <h6 class="text-white mb-0">
        Activity Feed
      </h6>
      <p class="mb-0 text-lightblue mb-5">
        10 new notifications [Content Cards]
      </p>
      <case-evidence-card-item />
      <case-transcriptions-card-item
        v-for="(item, i) in transcriptions"
        :key="i"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getCase} from "../../../api";
import BaseButton from "../../BaseButton.vue";
import MaterialIcon from "../MaterialIcon.vue";
import CaseEvidenceCardItem from "./CaseEvidenceCardItem.vue";
import CaseStatusCardItem from "./CaseStatusCardItem.vue";
import CaseTranscriptionsCardItem from "./CaseTranscriptionsCardItem.vue";
export default {
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  components: {
    BaseButton,
    MaterialIcon,
    CaseStatusCardItem,
    CaseEvidenceCardItem,
    CaseTranscriptionsCardItem,
  },
  data() {
    return {
      caseModel: null,
      transcriptions: [
        {
          status: "In Progress",
        },
        {
          status: "Completed",
        },
      ],
    };
  },
  watch: {
    caseId: function(newVal) {
      this.loadCase(newVal);
    },
  },
  computed: {
    ...mapGetters("data", ["getCases"]),
    caseItem() {
      return this.caseModel || this.getCases[this.caseId];
    },
  },
  mounted() {
    this.loadCase(this.caseId);
  },
  methods: {
    loadCase(caseId) {
      getCase(caseId).then((caseModel) => {
        this.caseModel = caseModel;
      });
    },
    openCase() {
      this.$router.push(`/v2/case/${this.caseId}`);
    },
  },
};
</script>

<style></style>
