<template>
  <div>
    <div class="d-flex">
      <base-button class="m-0" link @click="back()">
        <ArrowLeftIcon size="18" class="mr-2"></ArrowLeftIcon>
        {{ title }}
      </base-button>
      <div class="flex-1"></div>
      <slot></slot>
    </div>
    <div class="h-divider"></div>
  </div>
</template>

<script>
import { ArrowLeftIcon } from "vue-feather-icons";
import BaseButton from "../../BaseButton.vue";
export default {
  props: {
    to: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Back",
    },
  },
  components: {
    ArrowLeftIcon,
    BaseButton,
  },
  methods: {
    back() {
      if (this.to) {
        this.$router.replace({
          name: this.to,
        });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style></style>
