<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      Add New Note
    </h4>

    <template v-if="isDisplayed">
      <!-- Add New -->
      <div class="mb-3">
        <label
          for="title"
          class="form-label"
        > Title </label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="form-control"
          placeholder="Title"
        >
      </div>

      <div class="mb-3">
        <rtf-editor
          id="content"
          :readonly="readonly"
          label="Content"
          @contentsChanged="contentsChanged"
        />
        <!-- <input
          id="content"
          v-model="content"
          type="text"
          class="form-control"
          placeholder="Content"
        > -->
      </div>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>

<script>
import {addNote} from "../../../../api";

export default {
  name: "add-note",
  props: {
    evidenceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isDisplayed: false,
      title: "",
      content: "",
      readonly: false,
      loading: false,
      copyChanges: {},
    };
  },
  methods: {
    rtfChanged(group, field, value) {
      if (!this.copyChanges[group]) {
        this.copyChanges[group] = {};
      }
      this.copyChanges[group][field] = value;
    },
    display() {
      this.isDisplayed = true;
    },
    reset() {
      this.title = "";
      this.content = "";
      this.$emit("close");
      this.loading = false;
      this.isDisplayed = false;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      addNote(
        this.evidenceId,
        undefined,
        this.title,
        this.content
      )
      // note is the result from the promise, ex is exception
      // .then returns an action result
        .then((note) => {
          this.$emit("created", note);
          this.reset();
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError("Adding New Note Failed", ex);
        });
      this.loading = false; // Make sure that it doesn't stay in the loading state
      this.isDisplayed = false;
    },
    contentsChanged(val) {
      this.content = val;
    },
    titleChanged(val) {
      this.title = val;
    },
  },
};
</script>
