<template>
  <div>
    <modal-v-2
      :show="showModal"
      modal-content-classes="bg-dark-200"
      :centered="true"
      :show-close="true"
      @close="showModal = false"
    >
      <h6
        slot="header"
        class="text-white mb-0"
      >
        Who is this person?
      </h6>
      <template v-if="casePeople.length > 1">
        <p class="my-3 text-white text-center">
          Person associated with this case
        </p>
        <!-- TODO: - Add switch for mapAll -->

        <el-select
          v-model="form.casePerson"
          class="select-primary dark w-100"
          effect="dark"
          popper-class="select-primary"
          placeholder="Select person"
          filterable
        >
          <el-option
            v-for="item in casePeople"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <div class="d-flex mt-2">
          <base-button
            class="flex-1 m-0"
            :disabled="!selectedFromCase"
            simple
            wide
            @click="assignSingle"
          >
            Assign Single
          </base-button>
          <base-button
            class="flex-1 m-0 ml-4"
            :disabled="!selectedFromCase"
            wide
            @click="assignAll"
          >
            Assign To All
          </base-button>
        </div>

        <p class="my-4 text-white text-center">
          - or -
        </p>
      </template>

      <card
        class="p-10 text-center bg-dark-100 cursor-pointer"
        @click="openAddCaseOfficer()"
      >
        <UserIcon
          size="32"
          class="text-white"
        />
        <h4 class="mt-3 my-0">
          LEO
        </h4>
      </card>
      <card
        class="p-10 text-center bg-dark-100 mb-3 cursor-pointer"
        @click="openAddActor()"
      >
        <UsersIcon
          size="32"
          class="text-white"
        />
        <h4 class="mt-3 my-0">
          Subject
        </h4>
      </card>
    </modal-v-2>
    <add-case-officer-modal
      ref="addCaseOfficer"
      :case-id="caseId"
      :evidence-id="evidenceId"
      :incident-id="incidentId"
      :associate="associate"
      @close="handleClose"
      @created="handleCreated"
    />
    <add-actor-modal
      ref="addActor"
      :case-id="caseId"
      :evidence-id="evidenceId"
      :incident-id="incidentId"
      :associate="associate"
      @close="handleClose"
      @created="handleCreated"
    />
  </div>
</template>

<script>
import {UserIcon, UsersIcon} from "vue-feather-icons";
import {mapActions, mapGetters} from "vuex";
import {isDefined} from "../../../api/helpers";
import Card from "../../Cards/Card.vue";
import BaseButton from "../../BaseButton.vue";
import ModalV2 from "../ModalV2.vue";
import AddActorModal from "./AddActorModal.vue";
import AddCaseOfficerModal from "./AddCaseOfficerModal.vue";
export default {
  components: {
    ModalV2,
    Card,
    UserIcon,
    UsersIcon,
    AddCaseOfficerModal,
    AddActorModal,
    BaseButton,
  },
  props: {
    caseId: {
      type: [String, Number],
      default: null,
    },
    evidenceId: {
      type: [String, Number],
      default: null,
    },
    incidentId: {
      type: [String, Number],
      default: null,
    },
    associate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        casePerson: "",
        mapAll: true,
      },
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseActorAttributions", "getCaseUserAttributions"]),
    casePeopleCount() {
      return this.caseActors.length + this.caseUsers.length;
    },
    casePeople() {
      if (!this.associate) return [];
      return [
        ...this.caseUsers.map((u) => {
          return {value: `${u.userId}_user`, name: u.fullName};
        }),
        ...this.caseActors.map((a) => {
          return [
            {value: `${a.actorId}_actor`, name: a.fullName},
            ...(a.other ?? "").split(",").filter((aa) => aa.length > 0).map((aa) => {
              return {name: aa, value: `${a.actorId}_${aa}_actor`};
            }),
          ];
        }).reduce((t, a) => {
          t.push(...a);
          return t;
        }, []),
      ];
    },
    caseActors() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseActorAttributions)) return [];
      return this.getCaseActorAttributions[this.caseId] ?? [];
    },
    caseUsers() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseUserAttributions)) return [];
      return this.getCaseUserAttributions[this.caseId] ?? [];
    },
    selectedFromCase() {
      return isDefined(this.form.casePerson) && this.form.casePerson.length > 0;
    },
  },
  mounted() {
    if (!this.associate || !isDefined(this.caseId)) return;
    if (this.casePeople.length > 1) return;
    this.loadCasePeopleAttributions({caseId: this.caseId}).catch((ex) => {
      // Silent
    });
  },
  methods: {
    ...mapActions("data", ["loadCasePeopleAttributions"]),
    openAddActor() {
      this.$refs.addActor.showModal = true;
      this.showModal = false;
    },
    openAddCaseOfficer() {
      this.$refs.addCaseOfficer.showModal = true;
      this.showModal = false;
    },
    associated(mapAll) {
      if (isDefined(this.form.casePerson)) {
        if (this.form.casePerson.endsWith("_actor")) {
          this.$emit(
            "associated",
            {
              actorId: this.form.casePerson.split("_")[0],
              mapAll,
            }
          );
        } else if (this.form.casePerson.endsWith("_user")) {
          this.$emit(
            "associated",
            {
              userId: this.form.casePerson.split("_")[0],
              mapAll,
            }
          );
        }
      }
      this.showModal = false;
    },
    assignSingle() {
      this.associated(false);
    },
    assignAll() {
      this.associated(true);
    },
    handleCreated(person) {
      this.$emit(this.associate ? "associated" : "created", person);
    },
    handleClose(value) {
      if (value === true) {
        showModal = true;
      }
    },
    isDisplayed() {
      return this.showModal;
    },
  },
};
</script>

<style></style>
