<template>
  <div
    v-if="loaded"
    class="d-flex flex-column pt-0 flex-1 position-relative"
    :class="{alignItemsCenter: !hasPeople}"
  >
    <case-back-div />
    <div
      v-if="hasPeople"
      class="p-5"
    >
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h6 class="text-white case-people--tile mb-0">
            People
          </h6>
          <h5 class="text-lightblue">
            Assigned to this case: {{ peopleCount }}
          </h5>
        </div>
        <base-button
          v-if="hasPeople"
          type="simple"
          @click="openAddPeopleModal"
        >
          <plus-icon
            size="1.5x"
            class="mr-1"
          />
          People
          <add-people-modal
            ref="addPeople"
            :case-id="caseId"
            @created="loadPeople"
          />
        </base-button>
      </div>
      <case-people-table
        :users="users"
        :actors="actors"
        @remove="removeAttribution"
      />
    </div>
    <wizard-container
      v-else
      show-only="people"
      @created="loadPeople"
    />
  </div>
</template>

<script>
import {PlusIcon} from "vue-feather-icons";
import {mapActions, mapGetters} from "vuex";
import {unattributeActor, unattributeUser} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseButton from "../../../components/BaseButton.vue";
import AddPeopleModal from "../../../components/DashboardV2/Case/AddPeopleModal.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import CasePeopleTable from "../../../components/DashboardV2/Case/CasePeopleTable.vue";
import WizardContainer from "../../../components/DashboardV2/Wizard/CaseWizard.vue";
export default {
  components: {WizardContainer, BaseButton, AddPeopleModal, CaseBackDiv, PlusIcon, CasePeopleTable},
  data() {
    return {
      loading: true,
      loaded: false,
      removing: false,
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseActorAttributions", "getCaseUserAttributions"]),
    peopleCount() {
      return this.actors.length + this.users.length;
    },
    hasPeople() {
      return this.peopleCount > 0;
    },
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    actors() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseActorAttributions)) return [];
      return this.getCaseActorAttributions[this.caseId] ?? [];
    },
    users() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseUserAttributions)) return [];
      return this.getCaseUserAttributions[this.caseId] ?? [];
    },
  },
  mounted() {
    this.loadPeople();
  },
  methods: {
    ...mapActions("data", ["loadCasePeopleAttributions"]),
    loadPeople() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      this.loadCasePeopleAttributions({caseId: this.caseId}).then(() => {
        this.loaded = true;
      }).catch((ex) => {
        this.$notifyError("Failed to load People", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    openAddPeopleModal() {
      this.$refs.addPeople.showModal = true;
    },
    removeAttribution(data) {
      if (isDefined(data) && isDefined(data.type) && isDefined(data.id)) {
        if (data.type === "users") {
          this.removeUserAttribute(data.id);
        } else {
          this.removeActorAttribute(data.id);
        }
      }
    },
    removeActorAttribute(actorAttributeId) {
      if (this.removing) return;
      this.removing = true;
      unattributeActor(actorAttributeId).then((r) => {
        this.$notifySuccess("Successfully removed Subject from Case");
        this.loadPeople();
      }).catch((ex) => {
        this.$notifyError("Unable to remove Subject from Case, please try again", ex);
      }).finally(() => {
        this.removing = false;
      });
    },
    removeUserAttribute(userAttributeId) {
      if (this.removing) return;
      this.removing = true;
      unattributeUser(userAttributeId).then((r) => {
        this.$notifySuccess("Successfully removed LEO from Case");
        this.loadPeople();
      }).catch((ex) => {
        this.$notifyError("Unable to remove LEO from Case, please try again", ex);
      }).finally(() => {
        this.removing = false;
      });
    },
  },
};
</script>

<style lang="scss">
h6 {
  &--title {
    font-size: 20px;
  }
}
</style>
