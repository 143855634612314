<template>
  <modal-v-2
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    @close="onClose()"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <base-button
        tag="a"
        size="xs"
        class="m-0"
        link
        @click="onClose(true)"
      >
        <ArrowLeftIcon class="text-white" />
      </base-button>
      <h6 class="text-white mb-0">
        Subject
      </h6>
    </div>
    <div class=" d-flex flex-column">
      <label
        for="people-groups"
        class="form-label text-lightblue"
      >
        Use Existing
      </label>
      <el-select
        v-if="actors.length > 1"
        v-model="form.actorUid"
        class="select-primary dark"
        effect="dark"
        popper-class="select-primary"
        filterable
      >
        <el-option
          v-for="item in actors"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
    </div>
    <p
      v-if="actors.length > 1"
      class="text-center text-white mt-3"
    >
      - or -
    </p>
    <base-input
      v-model="form.fullName"
      label="Full Name"
      :disabled="form.actorUid !== ''"
    />
    <base-input
      v-model="form.aliasCsv"
      label="Aliases"
      placeholder="Comma separated names"
      :disabled="form.actorUid !== ''"
    />
    <div
      v-if="!associate"
      class="d-flex flex-column"
    >
      <label
        for="people-groups"
        class="form-label text-lightblue"
      >
        Groups
      </label>
      <el-select
        v-model="form.group"
        class="select-primary dark"
        effect="dark"
        popper-class="select-primary"
        filterable
      >
        <el-option
          v-for="item in groups"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
    </div>
    <!-- <base-select
      v-model="form.group"
      label="Group"
      :options="groups"
    /> -->
    <div
      v-if="associate"
      class="d-flex mt-2"
    >
      <base-button
        class="flex-1 m-0"
        :disabled="!canAssociate"
        simple
        wide
        @click="assignSingle"
      >
        Assign Single
      </base-button>
      <base-button
        class="flex-1 m-0 ml-4"
        :disabled="!canAssociate"
        wide
        @click="assignAll"
      >
        Assign To All
      </base-button>
    </div>
    <div
      v-else
      class="text-right mt-8 mb-3"
    >
      <base-button
        class="m-0"
        wide
        @click="createActorCaseAttribute"
      >
        Add
      </base-button>
    </div>
  </modal-v-2>
</template>

<script>
import {ArrowLeftIcon} from "vue-feather-icons";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {attributeActor, getActors, addActor} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import BaseButton from "../../BaseButton.vue";
import BaseInput from "../../Inputs/BaseInput.vue";
import ModalV2 from "../ModalV2.vue";
export default {
  components: {
    ModalV2,
    BaseButton,
    ArrowLeftIcon,
    BaseInput,
  },
  props: {
    caseId: {
      type: [String, Number],
      default: null,
    },
    evidenceId: {
      type: [String, Number],
      default: null,
    },
    incidentId: {
      type: [String, Number],
      default: null,
    },
    associate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      form: {
        actorUid: "",
        fullName: "",
        aliasCsv: "",
        group: "",
        mapAll: false,
      },
    };
  },
  computed: {
    ...mapGetters("data", ["availablePeopleGroups", "getActorMap"]),
    groups() {
      // TODO: - remove this filter in future.
      return this.availablePeopleGroups.filter((g) => g.toLowerCase().indexOf("officer") === -1).map((g) => {
        return {name: g, value: g};
      });
    },
    caseIdInt() {
      const id = this.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    evidenceIdInt() {
      const id = this.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    incidentIdInt() {
      const id = this.incidentId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    isCaseOnly() {
      return !isDefined(this.evidenceIdInt) && !isDefined(this.incidentIdInt);
    },
    actors() {
      if (!isDefined(this.getActorMap)) return [];
      return Object.entries(this.getActorMap).map(([k, v]) => {
        return [
          {name: v.fullName, value: k},
          ...(v.aliasCsv ?? "").split(",").filter((aa) => aa.length > 0).map((aa) => {
            return {name: aa, value: `${k}_${aa}`};
          }),
        ];
      }).reduce((t, a) => {
        t.push(...a);
        return t;
      }, [{name: "[Create New]", value: ""}]);
    },
    canAssociate() {
      return (
        isDefined(this.form.actorUid) &&
        this.form.actorUid.length > 0
      ) ||
      (
        isDefined(this.form.fullName) &&
        this.form.fullName.length > 0
      );
    },
  },
  mounted() {
    if (
      Object.keys(this.getActorMap).length === 0 ) {
      this.loadActors();
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("data", ["loadCasePeopleAttributions"]),
    ...mapMutations("data", ["putActorMap"]),
    loadActors() {
      this.loading = true;
      getActors().then((response) => {
        const actorsById = {};
        response.forEach((e) => actorsById[e.id] = e);
        this.putActorMap(actorsById);
      }).catch((ex) => {
        this.$notifyError("Loading Subject Data Failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    createActorCaseAttribute() {
      if (this.associate) this.form.group = "-";
      if (
        !isDefined(this.form.group) ||
        this.form.group === ""
      ) {
        this.$notifyWarn("Group required");
        return;
      }
      this.loading = true;
      const actorUid = this.form.actorUid.split("_").reverse().pop();
      if (!isDefined(this.caseIdInt)) {
        this.$notifyError("Failed to assigning Subject", ex);
      } else if (actorUid === "") {
        this.doCreateActor(this.caseIdInt);
      } else {
        this.doCreateActorCaseAttribute(this.caseIdInt, actorUid);
      }
    },
    doCreateActor(caseId) {
      if (
        !isDefined(this.form.fullName) ||
        this.form.fullName === ""
      ) {
        this.$notifyWarn("Subject Name required");
        return;
      }
      addActor(
        this.form.fullName,
        isDefined(this.form.aliasCsv) && this.form.aliasCsv !== "" ? this.form.aliasCsv : null
      ).then((response) => {
        this.doCreateActorCaseAttribute(caseId, response.id);
        this.loadActors(); // Update the information.
      }).catch((ex) => {
        this.loading = false;
        this.$notifyError("Failed to create Subject", ex);
      });
    },
    doCreateActorCaseAttribute(caseId, actorId) {
      attributeActor(
        actorId,
        this.form.group,
        caseId,
        this.evidenceIdInt,
        this.incidentIdInt
      ).then(() => {
        this.$emit("created", {actorId, mapAll: this.form.mapAll});
        this.loadCasePeopleAttributions({caseId});
        if (this.$route.name === ethosRouteNames.CasePeople ) {
          this.reset();
          return;
        }
        this.onClose(false);
        if (this.isCaseOnly) {
          this.$router.push({name: ethosRouteNames.CasePeople, params: {caseId}});
        }
      }).catch((ex) => {
        this.$notifyError("Failed to assign Subject", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    assignSingle() {
      this.form.mapAll = false;
      this.createActorCaseAttribute();
    },
    assignAll() {
      this.form.mapAll = true;
      this.createActorCaseAttribute();
    },
    reset() {
      this.loading = false;
      this.form.actorUid = "";
      this.form.fullName = "";
      this.form.aliasCsv = "";
      this.form.group = "";
      this.form.mapAll = false;
      this.showModal = false;
    },
    onClose(showParent) {
      this.reset();
      this.$emit("close", showParent);
    },
  },
};
</script>

<style></style>
