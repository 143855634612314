<template>
  <div
    v-if="caseSummary"
    class="case-container d-flex flex-column"
  >
    <router-view v-if="isNonEmptyCase" />
    <case-empty-state
      v-else
      class="flex-1"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ethosRouteNames} from "../../../routes/routeNames";
import CaseEmptyState from "./CaseEmptyState.vue";
export default {
  props: {
    caseSummary: {
      type: Object,
      default: null,
    },
  },
  components: {
    CaseEmptyState,
  },
  computed: {
    ...mapGetters("data", ["getEvidence"]),
    isNonEmptyCase() {
      const cs = this.caseSummary;
      let counts = 0;
      counts += (cs?.userAttributionCount || 0) - 1; // SUbtracting 1, because we created one userAttribution with the case.
      counts += cs?.actorAttributionCount || 0;
      counts += cs?.evidenceCount || 0;
      counts += cs?.caseKeywordCount || 0;
      return counts > 0;
    },
  },
  mounted() {
    if (this.isNonEmptyCase && this.$route.path === `/v2/case/${this.caseSummary.id}`) {
      this.$router.replace({name: ethosRouteNames.CaseEvidence});
    }
  },
};
</script>

<style>
.case-container {
  height: calc(100vh - 60px);
}
</style>
