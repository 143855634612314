<template>
  <div class="card card-plain rounded-0 bg-transparent">
    <div
      id="headingOne"
      role="tab"
      class="cursor-pointer"
    >
      <a
        data-toggle="collapse"
        data-parent="#accordion"
        :class="active ? 'text-white bg-grey-900' : 'text-lightblue'"
        :aria-expanded="active"
        class="d-flex font-16 p-3 font-weight-bold text-lightblue align-items-center cursor-pointer"
        :aria-controls="`content-${itemId}`"
        @click="activate"
      >
        <div class="py-2">
          <slot name="title"> {{ title }} </slot>
        </div>
        <div class="flex-1" />
        <slot
          v-if="active"
          name="actions"
        />
        <slot
          v-else
          name="count"
        />
        <MinusIcon
          v-if="active"
          @click.stop="activate"
        />
        <ChevronDownIcon
          v-else
          @click.stop="activate"
        />
      </a>
      <div class="h-divider" />
    </div>
    <collapse-transition :duration="animationDuration">
      <div
        v-show="active"
        :id="`content-${itemId}`"
        role="tabpanel"
        :aria-labelledby="title"
        class="collapsed"
      >
        <div class="card-body bg-black px-5 py-3">
          <slot />
        </div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import {CollapseTransition} from "vue2-transitions";
import {ChevronDownIcon, MinusIcon} from "vue-feather-icons";
export default {
  name: "collapse-item",
  components: {
    CollapseTransition,
    ChevronDownIcon,
    MinusIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    animationDuration: {
      default: 250,
    },
    multipleActive: {
      default: false,
    },
    addItem: {
      default: () => {},
    },
    removeItem: {
      default: () => {},
    },
    deactivateAll: {
      default: () => {},
    },
  },
  computed: {
    itemId() {
      return this.id || this.title;
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    activate() {
      const wasActive = this.active;
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !wasActive;
      this.$emit("toggled", this.active);
    },
    open() {
      this.active = true;
    },
  },
  mounted() {
    this.addItem(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  },
};
</script>
<style></style>
