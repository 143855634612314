<template>
  <div>
    <component
      :is="tag"
      :class="[
        { 'col-md-4': vertical && !tabNavWrapperClasses },
        { 'col-12': centered && !tabNavWrapperClasses },
        tabNavWrapperClasses,
      ]"
    >
      <ul
        class="nav nav-pills"
        role="tablist"
        :class="[
          `nav-pills-${type}`,
          { 'nav-pills-icons': square },
          { 'flex-column': vertical },
          { 'justify-content-center': centered },
          tabNavClasses,
        ]"
      >
        <li
          v-for="tab in tabs"
          :key="tab.id"
          :class="`nav-item active ${tab.hasSubmenu ? 'submenu-wrapper' : ''}`"
          data-toggle="tab"
          role="tablist"
          aria-expanded="true"
        >
          <a
            data-toggle="tab"
            role="tablist"
            :href="`#${tab.id}`"
            :aria-expanded="tab.active"
            class="nav-link"
            :class="{ active: tab.active }"
            @click.prevent="tab.customHandler ? tab.customHandler(tab) : activateTab(tab)"
          >
            <tab-item-content :tab="tab" />
          </a>
        </li>
      </ul>
    </component>
    <div
      class="tab-content"
      :class="[
        { 'tab-space': !vertical },
        { 'col-md-8': vertical && !tabContentClasses },
        tabContentClasses,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import {isDefined} from "../../api/helpers";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "tabs",
  components: {
    TabItemContent: {
      props: ["tab"],
      render(h) {
        return h("div", [this.tab.$slots.label || this.tab.label]);
      },
    },
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab,
    };
  },
  props: {
    tag: {
      type: String,
      default: "div",
      description: "Dropdown html tag (e.g div, ul etc)",
    },
    type: {
      type: String,
      default: "primary",
      validator: (value) => {
        const acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeTab: {
      type: String,
      default: "",
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      default: "",
    },
    tabNavClasses: {
      type: [String, Object],
      default: "",
    },
    tabContentClasses: {
      type: [String, Object],
      default: "",
    },
    vertical: Boolean,
    square: Boolean,
    centered: Boolean,
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  methods: {
    findAndActivateTab(label) {
      const tabToActivate = this.tabs.find((t) => t.label === label);
      if (tabToActivate) {
        this.activateTab(tabToActivate);
      }
    },
    activateTab(tab) {
      if (this.handleClick) {
        this.handleClick(tab);
      } else {
        this.$emit("tab-clicked", tab.id);
      }
      this.deactivateTabs();
      tab.active = true;
    },
    deactivateTabs() {
      this.tabs.forEach((tab) => {
        tab.active = false;
      });
    },
    addTab(tab) {
      const index = isDefined(tab.index) ? tab.index : this.$slots.default.indexOf(tab.$vnode);
      if (!this.activeTab && index === 0) {
        tab.active = true;
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
      }
      this.tabs.splice(index, 0, tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.findAndActivateTab(this.value);
      }
    });
  },
  watch: {
    value(newVal) {
      this.findAndActivateTab(newVal);
    },
  },
};
</script>

<style scoped></style>
