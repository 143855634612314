<template>
  <dashboard-v-2-layout v-if="!loading">
    <div
      slot="full"
    >
      <template v-if="isRoot && 1 == 2">
        <div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayNotes"
              type="secondary"
              @input="toggleDisplayNotes"
            />
            <span class="label-switch label-right pl-2">Display Transcript Notes</span>
          </div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayIncidents"
              type="secondary"
              @input="toggleDisplayIncidents"
            />
            <span class="label-switch label-right pl-2">Display Transcript Incidents</span>
          </div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayIncidentColors"
              type="secondary"
              @input="toggleDisplayIncidentColors"
            />
            <span class="label-switch label-right pl-2">Display Transcript Incident Colors</span>
          </div>
        </div>
      </template>
      <workflows-selection
        ref="workflowsSelection"
        heading="Default File Analysis"
        class="mb-5"
        allow-actions
        @save="saveWorkflows"
      />
      <preferences
        ref="preferences"
        class="mb-5"
        heading="Preferences"
        @save="savePreferences"
      />

      <code-words :heading="codeWordHeading" />
    </div>
  </dashboard-v-2-layout>
</template>

<script>
import CodeWords from "../../Ethos/CodeWord/CodeWords.vue";
import {mapGetters, mapMutations} from "vuex";
import WorkflowsSelection from "../../Components/WorkflowsSelection.vue";
import {setUserConfig} from "../../../api";
import Preferences from "./Preferences.vue";
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import BaseSwitch from "../../../components/BaseSwitch.vue";

export default {
  components: {CodeWords, WorkflowsSelection, Preferences, DashboardV2Layout, BaseSwitch},
  name: "ethos-settings",
  computed: {
    ...mapGetters("auth", [
      "userEmail",
      "isRoot",
    ]),
  },
  data() {
    return {
      loading: false,
      displayNotes: false,
      displayIncidents: false,
      displayIncidentColors: false,
      codeWordHeading: "Code Word Settings",
    };
  },
  methods: {
    ...mapMutations("data", [
      "setConfig",
    ]),
    saveWorkflows() {
      this.save(
        null,
        this.$refs.workflowsSelection && this.$refs.workflowsSelection.checked().join(",")
      );
    },
    savePreferences() {
      this.save(this.$refs.preferences && this.$refs.preferences.tz);
    },
    save(tz, selectedWorkflows) {
      setUserConfig(
        this.userEmail,
        null,
        tz,
        selectedWorkflows
      ).then((config) => {
        this.$notifySuccess("Saved Preferences Successfully");
        if (tz && this.$refs.preferences) this.$refs.preferences.showActions = false;
        if (selectedWorkflows && this.$refs.workflowsSelection) this.$refs.workflowsSelection.showActions = false;
        this.setConfig(config);
      }).catch((ex) => {
        this.$notifyError("Saving Preferences Failed", ex);
        this.cloneConfigLabel();
      });
    },
  },
};
</script>
