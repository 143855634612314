import {jsonRequest, jsonRequestPaginated, isDefined, GET, POST, blobRequest, PUT, DELETE} from "./helpers";

export const postEvidence = async (
  name, language, caseName, minSpeakerCount, maxSpeakerCount, storagePath, file, lastModifiedDate, uploadOpts
) => {
  const fd = new FormData();
  if (name) fd.append("name", name);
  if (language) fd.append("language", language);
  if (caseName) fd.append("caseName", caseName);
  if (isDefined(minSpeakerCount)) fd.append("minSpeakerCount", minSpeakerCount);
  if (isDefined(maxSpeakerCount)) fd.append("maxSpeakerCount", maxSpeakerCount);
  if (storagePath) fd.append("storagePath", storagePath);
  if (file) fd.append("evidenceFile", file, file.name);
  if (isDefined(lastModifiedDate)) fd.append("startTimeStampISO", lastModifiedDate);
  return await jsonRequest(POST, "Evidence", { // TODO: - Should probably be PUT
    body: fd,
    postContentHeader: {},
    uploadOpts,
  });
};

export const putEvidence = async (
  evidenceId, name, description, tags, startTimeStamp, coordinates, status
) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  if (!isDefined(name) && !isDefined(description) && !isDefined(tags) && !isDefined(startTimeStamp) && !isDefined(descricoordinatesption)) throw new Error("Nothing to update");
  const data = {};
  if (isDefined(name)) data.name = name;
  if (isDefined(description)) data.description = description;
  if (isDefined(tags) && Array.isArray(tags)) data.tags = tags;
  if (isDefined(startTimeStamp)) data.startTimeStamp = startTimeStamp;
  if (isDefined(coordinates)) data.coordinates = coordinates;
  if (isDefined(status)) data.elementStatus = status;
  return await jsonRequest(PUT, `Evidence/${evidenceId}`, {body: JSON.stringify(data)});
};

export const getEvidenceById = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}`);
};

export const getEvidenceByIdAndUrl = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  const json = await jsonRequest(GET, `Evidence/${evidenceId}`);
  if (isDefined(json) && isDefined(json.signedUrl)) {
    return {
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Evidence Url");
};

export const getEvidenceForLookup = async () => await jsonRequest(GET, "Evidence/List");

export const getEvidenceCases = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/Cases`);
};

export const getEvidenceCasesData = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/Cases/Data`);
};

export const getEvidenceTranscriptions = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/Transcriptions`);
};

export const getEvidence = async (pageNumber, pageSize, filter, caseId) => {
  return await jsonRequestPaginated(GET, "Evidence", {pageNumber, pageSize, filter}, isDefined(caseId) ? `CaseId=${caseId}` : undefined);
};

export const getEvidenceIncidents = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/Incidents`);
};

export const downloadEvidence = async (evidenceId, original) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await blobRequest(GET, `Transcription/${evidenceId}/Download${original === true ? "?original=true" : ""}`);
};

export const getEvidencePeopleByGroup = async (evidenceId, groupName) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  if (!isDefined(groupName)) throw new Error("Group Name Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/People?groupName=${groupName}`);
};

export const getClips = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(GET, `Evidence/${evidenceId}/Clips`);
};

export const getClip = async (evidenceId, clipId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  if (!isDefined(clipId)) throw new Error("Clip Id Required");
  const json = await jsonRequest(GET, `Evidence/${evidenceId}/Clip/${clipId}`);
  if (isDefined(json) && isDefined(json.clip) && isDefined(json.signedUrl)) {
    return {
      ...json.clip,
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Clip");
};

export const putClip = async (evidenceId, startOffset, endOffset, title, description, status) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  if (!isDefined(startOffset) || !isDefined(endOffset)) throw new Error("Offsets Required");
  if (!isDefined(title) && !isDefined(description)) throw new Error("Nothing to update");
  const data = {};
  if (isDefined(title)) data.title = title;
  if (isDefined(description)) data.description = description;
  if (isDefined(status)) data.status = status;

  // We use JSON.stringify to convert the data of title and content to something we can send
  return await jsonRequest(PUT, `Evidence/${evidenceId}/Clip/${startOffset}/To/${endOffset}`, {body: JSON.stringify(data)});
};

export const deleteClip = async (evidenceId, clipId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  if (!isDefined(clipId)) throw new Error("Clip Id Required");
  return await jsonRequest(DELETE, `Evidence/${evidenceId}/Clip/${clipId}`);
};
