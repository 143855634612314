import {textRequest, jsonRequest, isDefined, GET, POST} from "./helpers";

export const getWorkflow = async (id) => {
  if (!isDefined(id)) throw new Error("Cannot get workflow - Missing required property");
  return await jsonRequest(GET, `Workflows/${id}`);
};

export const runWorkflow = async (workflowName, evidenceId, parentProcessId, language, additionalNotes) => {
  if (!isDefined(evidenceId) || !workflowName) {
    throw new Error("Cannot run workflow - Missing required property");
  }
  const data = {
    evidenceId,
  };
  if (isDefined(parentProcessId)) body.parentProcessId = parentProcessId;
  if (isDefined(language)) body.language = language;
  if (isDefined(additionalNotes)) body.additionalNotes = additionalNotes;
  return await textRequest(POST, `Workflows/${workflowName}`, {
    body: JSON.stringify(data),
  });
};
