<template>
  <el-tag
    v-if="detail"
    class="cursor-pointer user-select-none m-0 mb-1"
    effect="dark"
    type="secondary"
    contenteditable="false"
    :closable="detail.speakerPos > 0"
    @close="$emit('remove', detail)"
    @click.stop="$emit('select', detail)"
  >
    <img src="img/person.svg">
    {{ speakerName }}
  </el-tag>
</template>

<script>
import {mapGetters} from "vuex";
import {isDefined} from "../../../../api/helpers";
export default {
  name: "transcription-speaker",
  props: {
    detail: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters("data", [
      "getUserMap",
      "getActorMap",
    ]),
    speakerName() {
      const detail = this.detail;
      if (!isDefined(detail)) return "";
      let sName = `Speaker: ${detail.speakerTag}`;
      if (isDefined(detail.speakerUserId) && this.getUserMap[detail.speakerUserId]) {
        sName = this.getUserMap[detail.speakerUserId].fullName;
      } else if (isDefined(detail.speakerActorId) && this.getActorMap[detail.speakerActorId]) {
        sName = this.getActorMap[detail.speakerActorId].fullName;
      }
      return sName;
    },
  },
  methods: {
    select() {
      this.$emit("select", this.detail);
    },
  },
};
</script>
