<template>
  <card class="p-5 mb-0 border" @click="$emit('click')">
    <p class="text-white font-16 font-weight-bold">New Signals</p>
    <p class="text-lightblue font-16 font-weight-normal">2380</p>
    <base-button class="m-0 mt-2 bg-dark-100 text-lightblue" size="xs" block>
      View</base-button
    >
  </card>
</template>

<script>
import BaseButton from "../../BaseButton.vue";
import Card from "../../Cards/Card.vue";

export default {
  props: {},
  components: { Card, BaseButton },
};
</script>
