<template>
  <div class="d-flex h-100">
    <collapse-container
      classes="d-flex flex-row-reverse"
      collapse-button-classes="position-relative"
      collapse-button-icon-classes="position-absolute top-0 w-100"
    >
      <el-table
        ref="tableData"
        :data="tableData"
        row-key="groupName"
        row-class-name="cursor-pointer"
        class="h-100"
        style="width:auto"
        :show-header="false"
        :expand-row-keys="expandedRowKeys"
        @row-click="rowClicked"
        @expand-change="expandChanged"
      >
        <el-table-column
          type="expand"
          label-class-name="invisible"
        >
          <template slot-scope="props">
            <p
              v-if="props.row.people.length === 0"
              class="no-record p-3 text-center"
            >
              No Record
            </p>
            <button
              v-for="p in props.row.people"
              :key="p.id"
              class="btn btn-simple w-100 border-0 btn-nested"
              :class="{
                'is-active': p.id === (selectedPeople ? selectedPeople.id : -99)
              }"
              @click="setSelectedPeople(p)"
            >
              <user-icon
                size="1.5x"
                class="mr-2"
                style="margin-left: -8px"
              />
              {{ p.realName }}
            </button>
          </template>
        </el-table-column>
        <el-table-column
          label="Name"
          min-width="250px"
        >
          <template slot-scope="props">
            <folder-icon
              size="1.5x"
              class="mr-4"
              style="margin-left: -8px"
            />
            <span>{{ props.row.groupName }}</span>
          </template>
        </el-table-column>
      </el-table>
    </collapse-container>
    <people-individual
      :people="selectedPeople"
      @updated="setSelectedPeople"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {FolderIcon, UserIcon} from "vue-feather-icons";
import PeopleIndividual from "../Individual/Individual.vue";
import {
  getAllFromPeopleGroup,
  // getEvidence,
  getEvidencePeopleByGroup,
  getPeopleById,
  getProjectPeopleByGroup,
} from "../../../../api";
import {isDefined} from "../../../../api/helpers";
export default {
  name: "people-folders",
  components: {
    PeopleIndividual,
    FolderIcon,
    UserIcon,
  },
  data() {
    return {
      expandedRowKeys: [],
      selectedPeople: null,
      projectLoadedPeopleGroup: [],
      evidenceLoadedPeopleGroup: [],
    };
  },
  watch: {
    individualId: {
      handler(to, from) {
        if (isDefined(to) && to != from) {
          const toInt = parseInt(to, 10);
          if (!isNaN(toInt) && (!this.selectedPeople || this.selectedPeople.id != toInt)) {
            this.setSelectedPeopleById(toInt);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("data", [
      "loadedPeopleGroups",
      "peopleByGroup",
      "peopleList",
      "availablePeopleGroups",
    ]),
    tableData() {
      return this.availablePeopleGroups.map((key) => {
        let people = this.peopleByGroup[key];
        if (people) {
          if (isDefined(this.projectId) || isDefined(this.caseId) || isDefined(this.evidenceId)) {
            people = people.filter((p) => {
              let ok = false;
              if (isDefined(this.projectId) && p.projects.find((pr) => pr.id === this.projectId)) ok = true;
              if (!ok && isDefined(this.caseId) && p.cases.find((c) => c.id === this.caseId)) ok = true;
              if (!ok && isDefined(this.evidenceId) && p.evidence.find((e) => e.id === this.evidenceId)) ok = true;
              return ok;
            });
          }
        }
        return {
          groupName: key,
          people,
        };
      });
    },
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    evidenceId() {
      const id = this.$route.params.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    projectId() {
      const id = this.$route.params.projectId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    individualId() {
      const id = this.$route.params.peopleId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations("data", [
      "putPeople",
      "setLoadedPeople",
      "setLoadedPeopleGroup",
    ]),
    expandChanged(row, expanedRows) {
      this.expandedRowKeys = expanedRows.map((x) => x.groupName);
      this.loadRow(row);
    },
    rowClicked(row) {
      this.$refs.tableData.toggleRowExpansion(row);
      this.loadRow(row);
    },
    loadRow(row) {
      if (isDefined(this.evidenceId)) {
        if (this.evidenceLoadedPeopleGroup.indexOf(row.groupName) >= 0) return; // We're good, no need to load extra
        this.getEvidencePeople(row.groupName);
      } else
      if (isDefined(this.projectId)) {
        if (this.projectLoadedPeopleGroup.indexOf(row.groupName) >= 0) return; // We're good, no need to load extra
        this.getProjectPeople(row.groupName);
      } else {
        if (this.loadedPeopleGroups.indexOf(row.groupName) >= 0) return; // We're good, no need to load extra
        this.getPeople(row.groupName);
      }
    },
    setSelectedPeopleById(id) {
      if (!isDefined(id)) return;
      const exists = this.peopleList[id];
      if (exists) {
        this.setSelectedPeople(exists);
        return;
      }
      const me = this;
      getPeopleById(id).then((p) => {
        me.putPeople([p]);
        me.setSelectedPeople(p);
      }).catch((p) => {
        // Ignore?
      });
    },
    setSelectedPeople(people) {
      const id = people && people.id;
      if (!this.selectedPeople || this.selectedPeople.id !== id) {
        if (this.$route && this.$route.path.startsWith("/people")) {
          if (isDefined(id)) this.$router.push(`/people/${id}`);
        }
      }
      this.selectedPeople = people;
    },
    getPeople(groupName) {
      getAllFromPeopleGroup(groupName)
        .then((response) => {
          this.putPeople(response);
          this.setLoadedPeopleGroup(groupName);
        })
        .catch((ex) => {
          this.$notifyError(`Loading Grouped ${this.peopleNameLabel} Failed`, ex);
        });
    },
    getProjectPeople(groupName) {
      getProjectPeopleByGroup(this.projectId, groupName)
        .then((response) => {
          this.putPeople(response.people);
          this.projectLoadedPeopleGroup.push(groupName);
        })
        .catch((ex) => {
          this.$notifyError(`Loading Grouped ${this.peopleNameLabel} Failed`, ex);
        });
    },
    getEvidencePeople(groupName) {
      getEvidencePeopleByGroup(this.evidenceId, groupName)
        .then((response) => {
          this.putPeople(response.people);
          this.evidenceLoadedPeopleGroup.push(groupName);
        })
        .catch((ex) => {
          this.$notifyError(`Loading Grouped ${this.peopleNameLabel} Failed`, ex);
        });
    },
  },
};
</script>
