/* !

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import Vuex from "vuex";
import DashboardPlugin from "./plugins/dashboard-plugin";
import {Auth0Plugin} from "./plugins/auth0-plugin";
import {DatePlugin} from "./plugins/date-plugin";
import App from "./App.vue";

// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";

// plugin setup
Vue.use(Vuex);
Vue.use(DashboardPlugin);
Vue.use(DatePlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

// MUST be registered after
// VueRouter
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
  scope: "email",
});

// Import the Vuex store
import getStore from "./store";

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  store: getStore(),
  router,
  i18n,
});
