<template>
  <modal-v-2
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    @close="onClose()"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <h6 class="text-white mb-0">
        Move to folder
      </h6>
    </div>
    <base-select
      v-if="dirs.length > 1"
      v-model="form.dir"
      label="Use Existing"
      :options="dirOptions"
    />
    <p
      v-if="dirs.length > 1"
    >
      - or -
    </p>
    <base-input
      v-model="form.dirName"
      label="Folder Name"
      :disabled="form.dir !== ''"
    />
    <div class="text-right mt-8 mb-3">
      <base-button
        class="m-0"
        wide
        @click="setFolder"
      >
        Move
      </base-button>
    </div>
  </modal-v-2>
</template>

<script>
import {postCaseEvidenceSummary} from "../../../api";
import BaseButton from "../../BaseButton.vue";
import BaseInput from "../../Inputs/BaseInput.vue";
import BaseSelect from "../../Inputs/BaseSelect.vue";
import ModalV2 from "../ModalV2.vue";
export default {
  components: {
    ModalV2,
    BaseButton,
    BaseInput,
    BaseSelect,
  },
  props: {
    caseId: {
      type: Number,
    },
    evidenceIds: {
      type: Array,
    },
    dirs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      form: {
        dir: "",
        dirName: "",
      },
    };
  },
  computed: {
    dirOptions() {
      return this.dirs.map((d) => {
        return {name: d, value: d};
      });
    },
  },
  methods: {
    setFolder() {
      this.loading = true;
      postCaseEvidenceSummary(
        this.caseId, this.evidenceIds, undefined, this.form.dir === "" ? this.form.dirName : this.form.dir
      ).then((response) => {
        this.onClose();
      }).catch((ex) => {
        this.$notifyError("Moving Evidence Failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    onClose() {
      this.showModal = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
