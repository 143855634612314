const getters = {
  established: (state, getters) => {
    // Make sure that we've called in the user has a valid active role
    return state.hasCalled && (
      getters.isRoot ||
      getters.isAdmin ||
      getters.isEditor ||
      getters.isViewOnly
    );
  },
  userId: (state) => {
    return state.id;
  },
  userEmail: (state) => {
    return state.email;
  },
  userFullName: (state) => {
    return state.fullName;
  },
  userFirstName: (state) => {
    return state.firstName;
  },
  userLastName: (state) => {
    return state.lastName;
  },
  userProfilePhotoUrl: (state) => {
    return state.photoUrl;
  },
  isRoot: (state, getters) => {
    return state.roles.indexOf("ROOT") > -1;
  },
  isAdmin: (state, getters) => {
    return state.roles.indexOf("ADMIN") > -1;
  },
  isEditor: (state, getters) => {
    return state.roles.indexOf("EDITOR") > -1;
  },
  isViewOnly: (state, getters) => {
    return state.roles.indexOf("VIEWER") > -1;
  },
};

export default getters;
