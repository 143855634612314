<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default modal-wide"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <template v-if="isDisplayed">
      <div class="mb-3">
        <label
          for="title"
          class="form-label"
        > Title </label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="form-control"
          placeholder="Title"
        >
      </div>

      <div class="mb-3">
        <label
          for="content"
          class="form-label"
        > Content </label>
        <input
          id="content"
          :value="eventData.text"
          type="text"
          class="form-control"
          disabled
          placeholder="Content"
        >
      </div>

      <div class="mb-3">
        <label
          for="description"
          class="form-label"
        > Description </label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control"
          placeholder="Description"
        >
      </div>

      <div
        v-if="displayIncidentColors"
        class="mb-3"
      >
        <label
          for="color"
          class="form-label mb-0"
        > Color </label>
        <div class="d-flex flex-wrap align-items-center">
          <color-dot
            v-for="hex in colors"
            :key="hex"
            :hex="hex"
            :classes="'mr-1' + (hex === color ? ' selected' : '')"
            @click="setColor(hex)"
          />

          <color-dot
            v-if="colors.indexOf(color) === -1"
            :hex="color"
            classes="mr-1 selected"
            @active-change="setColor"
          />

          <base-button
            class="my-0"
            size="sm"
            simple
            @click="help"
          >
            Custom
            <plus-icon size="1.5x" />
            <color-picker
              :id="`cp-incident-${eventData.start}`"
              ref="colorPicker"
              v-model="color"
              :predefine="colors"
              class="custom-color-picker"
            />
          </base-button>
        </div>
      </div>

      <template v-if="eventData.type == 'timeline'">
        TODO: - DateTimePickers
      </template>

      <div
        v-if="displayIncidents"
        class="mb-3"
      >
        <label
          for="address"
          class="form-label"
        > Address </label>
        <input
          id="address"
          v-model="address"
          type="text"
          class="form-control"
          placeholder="Address"
        >
      </div>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <h1 v-if="originalIncident">
        <base-button
          size="sm"
          class="ml-auto"
          type="secondary"
          simple
          :loading="loading"
          @click="switchArchiveStatus"
        >
          {{ (originalIncident.archive) ? 'Unarchive' : 'Archive' }}
        </base-button>
      </h1>
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>


<script>
import {Modal} from "src/components";
import {ColorPicker, Select, Option} from "element-ui";
import {mapGetters, mapMutations} from "vuex";
import {addPeople, archiveIncident, getUsersOverview, getActors, linkIncidentTo, postIncident, putIncident, updateIndividual} from "../../../api";
import {isDefined} from "../../../api/helpers";
import ColorDot from "../../Components/ColorDot.vue";
import {defaultColorOptions} from "../../../util/util";
import {PlusIcon} from "vue-feather-icons";
import {IncidentType} from "../../../util/enums";

export default {
  name: "incident-form",
  components: {
    Modal,
    ColorDot,
    [Option.name]: Option,
    [Select.name]: Select,
    ColorPicker,
    PlusIcon,
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
    actorId: {
      type: Number,
      default: null,
    },
    caseId: {
      type: Number,
      default: null,
    },
    case: {
      type: Object,
      default: null,
    },
    evidenceId: {
      type: Number,
      default: null,
    },
    evidence: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDisplayed: false,
      loading: false,
      loadingList: true,
      eventData: null,
      originalIncident: null,
      title: "",
      address: "",
      archive: false,
      color: defaultColorOptions()[0],
      description: "",
      startTime: new Date(),
      endTime: new Date(),
      // TODO: - Flesh out these
      selectedEvidenceIds: [],
      selectedNoteIds: [],
      selectedPeopleIds: [], // TODO: - swap for incidents
      colors: defaultColorOptions(),
    };
  },
  watch: {
    showSelect: function(n, o) {
      if (n && !o) {

      }
    },
  },

  computed: {
    ...mapGetters("data", [
      "getUserMap",
      "getActorMap",
      "displayIncidentColors",
      "displayIncidents",
    ]),
    showSelect() {
      return !this.peopleId && (
        this.caseId !== null ||
        this.evidenceId !== null
      );
    },
    headerCopy() {
      let header = "Add New ";
      if (isDefined(this.originalIncident)) header = "Edit ";
      return header + (this.displayIncidents ? "Incident" : "Area Of Interest");
    },
  },
  mounted() {
    if (
      Object.keys(this.getUserMap).length +
      Object.keys(this.getActorMap).length === 0 ) {
      this.loadPeople();
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations("data", [
      "putActorMap",
      "putUserMap",
    ]),
    display(eventData, incident) {
      if (isDefined(incident)) {
        this.originalIncident = incident;
        this.title = incident.title || "";
        this.address = incident.address || "";
        this.color = incident.color || defaultColorOptions()[0];
        this.description = incident.description || "";
        this.startTime = new Date(incident.startTime || undefined);
        this.endTime = new Date(incident.endTime || undefined);
        this.archive = incident.archive === true;
        this.selectedEvidenceIds = incident.evidence && incident.evidence.map((e) => e.id) || [];
        this.selectedNoteIds = incident.notes && incident.notes.map((e) => e.id) || [];
        this.selectedPeopleIds = incident.people && incident.people.map((e) => e.id) || [];
      } else {
        this.reset();
      }

      this.eventData = eventData;
      this.isDisplayed = true;

      if (isDefined(this.userId)) {
        const user = this.getUserMap[this.userId];
        if (isDefined(user)) {
          // display the user.
        }
      }
      if (isDefined(this.actorId)) {
        const actor = this.getActorMap[this.actorId];
        if (isDefined(actor)) {
          // display the actor.
        }
      }
    },
    loadUsers() {
      getUsersOverview().then((response) => {
        const usersById = {};
        response.forEach((e) => usersById[e.id] = e);
        this.putUserMap(usersById);
      }).catch((ex) => {
        this.$notifyError("Loading LEO Data Failed", ex);
      });
    },
    loadActors() {
      getActors().then((response) => {
        const actorsById = {};
        response.forEach((e) => actorsById[e.id] = e);
        this.putActorMap(actorsById);
      }).catch((ex) => {
        this.$notifyError("Loading Subject Data Failed", ex);
      });
    },
    loadPeople() {
      this.loading = true;
      Promise.allSettled([this.loadActors(), this.loadUsers()]).finally(() => {
        this.loading = false;
      });
    },
    reset() {
      this.isDisplayed = false;
      this.title = "";
      this.address = "";
      this.archive = false;
      this.color = defaultColorOptions()[0];
      this.description = "";
      this.startTime = new Date();
      this.endTime = new Date();
      this.selectedEvidenceIds = [];
      this.selectedNoteIds = [];
      this.selectedPeopleIds = [];
      this.originalIncident = null;
      this.eventData = null;
      this.loading = false;
      this.$emit("close");
    },

    // Method for what happens after clicking archive/unarchive
    switchArchiveStatus() {
      this.archive = !this.archive;
      archiveIncident(
        this.originalIncident.id,
        this.archive
      ).then((incident) => {
        this.$emit("updated", incident);
        this.$notifySuccess("Incident Updated");
        this.reset();
      }).catch((ex) => {
        this.$notifyError("Failed to update Incident", ex);
        this.loading = false;
      });
    },

    submit() {
      if (this.loading) return;
      if (!isDefined(this.title) || this.title.length < 1) {
        this.$notifyWarn("Title required");
        return;
      }
      if (
        !isDefined(this.eventData) ||
        [IncidentType.transcript, IncidentType.timeline].indexOf(this.eventData.type) === -1
      ) return;

      const newTitle = this.title;
      const newDescription = (isDefined(this.description) && this.description.length > 0 && this.description) || null;
      const newColor = (isDefined(this.color) && this.color.length === 7 && this.color.startsWith("#") && this.color) || null;
      const newAddress = (isDefined(this.address) && this.address.length > 0 && this.address) || null;
      const newStartTime = (
        this.eventData.type === IncidentType.timeline &&
        isDefined(this.startTime)
      ) ? this.startTime.toISOString() : null;
      const newEndTime = (
        this.eventData.type ===IncidentType.timeline &&
        isDefined(this.endTime)
      ) ? this.endTime.toISOString(): null;
      const newStartOffset = (
        this.eventData.type === IncidentType.transcript &&
        isDefined(this.eventData.start)
      ) ? this.eventData.start: null;
      const newEndOffset = (
        this.eventData.type === IncidentType.transcript &&
        isDefined(this.eventData.end)
      ) ? this.eventData.end: null;
      const newTranscriptionId = (
        this.eventData.type === IncidentType.transcript &&
        isDefined(this.eventData.transcriptId)
      ) ? this.eventData.transcriptId: null;

      this.loading = true;
      if (!isDefined(this.originalIncident)) {
        postIncident(
          newTitle,
          this.eventData.type,
          newDescription,
          newColor,
          newStartTime,
          newEndTime,
          newStartOffset,
          newEndOffset,
          newTranscriptionId,
          newAddress,
          true,
          (this.eventData.nodes?.start?.dataset.so || 0) * 1000,
          (this.eventData.nodes?.end?.dataset.eo || 0) * 1000
        ).then((incident) => {
          const evidenceId = this.eventData.evidenceId;
          return {incident, evidenceId};
        }).then(({incident, evidenceId}) => {
          return linkIncidentTo(incident.id, [evidenceId]);
        }).then((incident) => {
          this.$emit("created", incident);
          this.$notifySuccess("Incident Created");
          this.reset();
        }).catch((ex) => {
          this.$notifyError("Failed to create Incident", ex);
          this.loading = false;
        });
      } else {
        const newTitle = this.title !== this.originalIncident.title ? this.title : null;
        putIncident(
          this.originalIncident.id,
          newTitle !== this.originalIncident.title ? newTitle : null,
          newDescription !== this.originalIncident.description ? newDescription : null,
          newColor !== this.originalIncident.color ? newColor : null,
          newStartTime !== this.originalIncident.startTime ? newStartTime : null,
          newEndTime !== this.originalIncident.endTime ? newEndTime : null,
          newStartOffset !== this.originalIncident.startOffset ? newStartOffset : null,
          newEndOffset !== this.originalIncident.endOffset ? newEndOffset : null,
          newAddress !== this.originalIncident.address ? newAddress : null
        ).then((incident) => {
          this.$emit("updated", incident);
          this.$notifySuccess("Incident Updated");
          this.reset();
        }).catch((ex) => {
          this.$notifyError("Failed to update Incident", ex);
          this.loading = false;
        });
      }
      return; // TODO: - Need to implement something like the below to add the evidence/notes/people
      // If we've selected people then attribute the person to it
      const selectedIds = [];
      if (Array.isArray(this.selectedPeopleIds)) {
        selectedIds.push(...this.selectedPeopleIds);
      } else if (this.single && isDefined(this.selectedPeopleIds)) {
        selectedIds.push(this.selectedPeopleIds);
      }
      if (selectedIds.length > 0) {
        const promises = [];
        const updatedPeople = [];
        for (let i = 0; i < selectedIds.length; i++) {
          const id = selectedIds[i];
          const promise = updateIndividual(
            id, null, null, null, null,
            this.caseId, this.evidenceId, null, this.case, this.evidence, null
          ).then((people) => {
            this.$notifySuccess(`${this.peopleNameLabel} Attributed Successfully`);
            updatedPeople.push(people);
            return Promise.resolve();
          }).catch((ex) => {
            this.$notifyError(`Attributing ${this.peopleNameLabel} Failed`, ex);
          });
          promises.push(promise);
        }
        Promise.allSettled(promises).then(() => {
          this.$emit("updated", updatedPeople);
          this.reset();
        }).catch((ex) => {
          // Shouldn't happen
          this.loading = false;
        });
      } else {
        if (this.peopleId) {
          updateIndividual(
            this.realName && this.realName.length > 0 ? this.realName : null,
            this.displayName && this.displayName.length > 0 ? this.displayName : null,
            this.description && this.description.length > 0 ? this.description : null,
            this.folders.length > 1 ? this.folders.join(",") : null,
            this.caseId, this.evidenceId, this.case,
            null, this.evidence, null
          )
            .then((people) => {
              this.$emit("updated", [people]);
              this.reset();
              this.$notifySuccess(`${this.personNameLabel} Updated Successfully`);
            })
            .catch((ex) => {
              this.loading = false;
              this.$notifyError(`Updating ${this.personNameLabel} Failed`, ex);
            });
        } else {
          // Otherwise add the new user
          if (this.folders.length < 1) {
            // They must have at least one folder
            this.$notifyWarn("Must select at least one Group");
            this.loading = false;
            return;
          }

          if (!this.realName || this.realName.length < 1) {
            // They must have at least one folder
            this.$notifyWarn("Must Enter Real Name");
            this.loading = false;
            return;
          }

          addPeople(
            this.realName,
            this.displayName && this.displayName.length > 0 ? this.displayName : this.realName,
            this.description,
            this.folders.join(","),
            this.caseId,
            this.evidenceId,
            null,
            this.caseId, this.evidenceId, this.case,
            null, this.evidence, null
          )
            .then((people) => {
              this.$emit("created", people);
              this.reset();
              this.$notifySuccess(`${this.personNameLabel} Added Successfully`);
            })
            .catch((ex) => {
              this.loading = false;
              this.$notifyError(`Adding New ${this.personNameLabel} Failed`, ex);
            });
        }
      }
    },
    setColor(hex) {
      this.color = hex;
    },
    help(e) {
      const picker = document.getElementById(`cp-incident-${this.eventData.start}`);
      if (isDefined(picker)) {
        const trigger = picker.getElementsByClassName("el-color-picker__trigger");
        if (trigger.length > 0) trigger[0].click();
      }
    },
  },
};
</script>
