<template>
  <div class="d-flex h-100 flex-grow-1">
    <collapse-container
      classes="d-flex flex-row-reverse"
      collapse-button-classes="position-relative"
      collapse-button-icon-classes="position-absolute top-0 w-100"
      @collapse="toggleCollapse"
    >
      <note-list
        ref="notesList"
        :evidence-id="evidenceId"
        @selectionchanged="noteListSelectionChanged"
      />
    </collapse-container>
    <!-- This is where we make our notes visible -->
    <div class="content flex-grow-1">
      <template v-if="activeComponent === 'view-notes'">
        <!-- We pass the content into note view so that we can display it -->
        <!-- parse changes string to objects, opposite of stringfy -->
        <note-viewer
          v-if="activeNote"
          :id="activeNote.id"
          :title="activeNote.title"
          :json="activeNote.content.indexOf('{') === -1 ? {} : JSON.parse(activeNote.content)"
          @updated="noteUpdated"
        />
      </template>
    </div>
    <add-note
      ref="addNoteForm"
      header="Add New Note"
      :evidence-id="evidenceId"
      @close="showAddNewNote = false"
    />
  </div>
</template>

<script>
import AddNote from "./AddNote/AddNote.vue";
import NoteViewer from "./NoteViewer/NoteViewer.vue";
import NoteList from "./NoteList/NoteList.vue";
import {isDefined} from "../../../api/helpers";

export default {
  components: {NoteViewer, AddNote, NoteList},
  data() {
    return {
      activeComponent: "view-notes",
      activeNote: null,
    };
  },
  computed: {
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    evidenceId() {
      const id = this.$route.params.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  methods: {
    displayAddNoteForm() {
      this.$refs.addNoteForm.display();
    },
    toggleCollapse(collapse) {
      console.log(collapse);
    },
    displayViewNote() {
      this.$refs.viewNote.display();
    },
    noteListSelectionChanged(n) {
      if (!isDefined(n)) return;
      let prefix = `/v2/case/${this.caseId}`;
      if (isDefined(this.evidenceId)) {
        prefix += `/view-evidence/${this.evidenceId}`;
      }

      // Then combine and test to avoid double routing:
      const fullPath = `${prefix}/note/${n.id}`;
      if (this.$route && this.$route.path.startsWith(prefix) && !this.$route.path.startsWith(fullPath)) {
        this.$router.push(fullPath);
      }
      this.activeNote = n;
    },
    noteUpdated(note) {
      if (this.$refs.notesList) {
        this.$refs.notesList.noteUpdated(note);
      }
    },
  },
};
</script>
