<template>
  <div class="d-flex h-100 ethos-container">
    <collapse-container
      classes="d-flex flex-row-reverse"
      collapse-button-classes="position-relative"
      collapse-button-icon-classes="position-absolute top-0"
    >
      <tabs
        ref="tabs"
        v-model="activeTab"
        type="info"
        tab-nav-wrapper-classes="p-2"
        tab-content-classes="flex-grow-1 h-100 p-0"
        vertical
        class="d-flex h-100"
        font="Roboto"
        @tab-clicked="handleTabClick"
      >
        <tab-pane
          id="addFiles"
          :index="0"
          label="Add Files"
        />

        <tab-pane
          id="files"
          :index="1"
          label="Files"
        />

        <tab-pane
          id="clips"
          :index="2"
          label="Clips"
        />

        <tab-pane
          id="shots"
          :index="3"
          label="Still Shots"
        />
      </tabs>
    </collapse-container>
    <template v-if="activeComponent !== null">
      <div class="position-relative pl-3 flex-grow-1 mw-100">
        <evidence
          v-if="activeComponent === 'files'"
          :no-border="true"
          reuse
        />
        <div
          v-else
          class="d-flex h-100 justify-content-center align-items-center"
        >
          No Content
        </div>
      </div>
    </template>
    <add-new-evidence
      ref="evidenceForm"
      :case-id="caseId"
      :project-id="projectId"
      header="Add !!!"
    />
  </div>
</template>

<script>
import {TabPane, Tabs} from "src/components";
import AddNewEvidence from "../../../Evidence/AddNewEvidence.vue";
import Evidence from "../../../Evidence/Evidence.vue";
import {isDefined} from "../../../../../api/helpers";

export default {
  components: {Tabs, TabPane, Evidence, AddNewEvidence},
  name: "project-evidence",
  data() {
    return {
      activeComponent: "Files",
      activeTab: "Files",
    };
  },
  computed: {
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    projectId() {
      const id = this.$route.params.projectId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  methods: {
    handleTabClick(id) {
      switch (id) {
        case "addFiles":
          if (this.$refs.evidenceForm) this.$refs.evidenceForm.display();
          const me = this;
          this.$nextTick(function() {
            const tab = me.$refs.tabs.tabs.find((t) => t.id == me.activeComponent);
            me.$refs.tabs.activateTab(tab);
          });
          return;
        default:
          break;
      }
      this.activeComponent = id;
    },
  },
};
</script>
