<template>
  <div class="h-100">
    <!-- <timeline /> -->
    <tabs
      ref="tabs"
      type="info"
      tab-nav-wrapper-classes="p-2 border-tabs-right"
      tab-content-classes="flex-grow-1 h-100 p-0"
      vertical
      class="d-flex h-100"
      font="Roboto"
      @tab-clicked="tabClicked"
    >
      <tab-pane id="0">
        <span slot="label">
          <archive-icon size="1.5x" />
          {{ evidenceNameLabel }}
        </span>
        <project-evidence />
      </tab-pane>
      <tab-pane id="1">
        <span slot="label">
          <user-plus-icon size="1.5x" />
          {{ peopleNameLabel }}
        </span>
        <people />
      </tab-pane>
      <!-- Make Notes down here into a drop down button -->
      <tab-pane
        id="2"
        has-submenu
      >
        <span slot="label">
          <el-menu
            ref="noteMenu"
            @select="menuSelected"
          >
            <el-submenu
              index="2-0"
              vertical-align="left"
            >
              <template slot="title">
                <span
                  slot="title"
                >
                  <file-icon
                    size="1.5x"
                    class="mr-2"
                  />
                  Notes
                </span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  index="2-1"
                  @click="added"
                >
                  <plus-icon
                    size="1.5x"
                    class="mr-2"
                    style="margin-left: -8px"
                  />
                  Add Note
                </el-menu-item>
                <el-menu-item
                  index="2-2"
                  @click="viewed"
                >
                  <eye-icon
                    size="1.5x"
                    class="mr-2"
                    style="margin-left: -8px"
                  />
                  View Notes
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>

        </span>
        <div v-if="clickedView">
          <notes />
        </div>
      </tab-pane>
    </tabs>
    <add-note
      ref="addNoteForm"
      header="Add New Note"
      :case-id="caseId"
      :project-id="projectId"
    />
  </div>
</template>

<script>
import {TabPane, Tabs} from "src/components";
import ProjectEvidence from "./components/Evidence/Evidence.vue";
import People from "../People/People.vue";
import {mapGetters} from "vuex";
import Notes from "../Notes/Notes.vue";
import AddNote from "../Notes/AddNote/AddNote.vue";
// import Timeline from "./Timelines/Timeline.vue";
import {
  ArchiveIcon,
  UserPlusIcon,
  FileIcon,
  PlusIcon,
  EyeIcon,
} from "vue-feather-icons";
import {isDefined} from "../../../api/helpers";

export default {
  name: "ethos-projects",
  components: {
    Tabs,
    TabPane,
    ProjectEvidence,
    People,
    Notes,
    AddNote,
    ArchiveIcon,
    UserPlusIcon,
    UserPlusIcon,
    FileIcon,
    PlusIcon,
    EyeIcon,
    // Timeline,
  },
  computed: {
    ...mapGetters("data", ["peopleNameLabel", "evidenceNameLabel"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    projectId() {
      const id = this.$route.params.projectId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    noteId() {
      const id = this.$route.params.noteId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  data() {
    return {
      selectedTab: "0",
      lastMenuItemClicked: null,
      clickedView: false,
    };
  },
  mounted() {
    if (this.noteId !== null) {
      const me = this;
      this.$nextTick(() => {
        // Set Notes as the active tab
        // IMPORTANT: - needs updating if any indices change.
        me.$refs.tabs.activateTab(me.$refs.tabs.tabs[2]);
        me.$refs.noteMenu.openMenu("2-0", 0);
        me.$refs.noteMenu.activeIndex = "2-2";
        me.clickedView = true;
      });
    }
  },
  methods: {
    added() {
      this.$refs.addNoteForm.display();
    },
    viewed() {
      this.clickedView = true;
    },
    tabClicked(id) {
      switch (id) {
        case "2":
          break;
        default:
          if (this.$refs.noteMenu) {
            this.$refs.noteMenu.activeIndex = null;
            this.lastMenuItemClicked = null;
            this.clickedView = false;
          }
          this.routeTo(id);
          break;
      }
      this.selectedTab = id;
    },
    routeTo(id) {
      if (id !== "0" && id !== "1") return;
      const prefix = `/cases/${this.caseId}/project/${this.projectId}`;
      const fullPath = `${prefix}/${id === "0" ? "evidence" : "people"}`;
      if (this.$route && this.$route.path.startsWith(prefix) && !this.$route.path.startsWith(fullPath)) {
        this.$router.push(fullPath);
      }
    },
    menuSelected(x) {
      if (x === "2-1") {
        this.$refs.noteMenu.activeIndex = this.lastMenuItemClicked;
        return;
      }
      this.lastMenuItemClicked = x;
    },
  },
};
</script>
