<template>
  <div
    class="evidence-content flex-grow-1 pt-4"
  >
    <div
      ref="parentDiv"
      class="tokenized-transcription-content"
      @click.left="closeMenu"
      @click.right="handleClick"
    >
      <template v-for="(c, index) in speakers">
        <div
          :key="`${c.speakerPos}-${index}`"
          class="d-flex align-items-baseline"
        >
          <el-tooltip
            v-if="speakerChangeRequests[c.speakerPos]"
            :key="`${c.speakerPos}-${metadata.requestedChanges.length}`"
            transition=""
          >
            <transcription-speaker
              :ref="`spk-${c.speakerPos}`"
              :data-spk="c.speakerPos"
              :detail="c"
              @select="selectSpeaker"
              @remove="removeSpeaker"
            />
            <template slot="content">
              <change-requests
                :scrs="speakerChangeRequests[c.speakerPos]"
                :actor-map="actorMap"
                :user-map="userMap"
                @approve="approve"
                @reject="reject"
              />
            </template>
          </el-tooltip>
          <transcription-speaker
            v-else
            :ref="`spk-${c.speakerPos}`"
            :data-spk="c.speakerPos"
            :detail="c"
            @select="selectSpeaker"
            @remove="removeSpeaker"
          />
          <div
            v-if="c.content.length > 0"
            class="ml-auto speaker-time-series"
            contenteditable="false"
          >
            {{ formatTime(c.content[0].startOffset) }}
          </div>
        </div>
        <div
          :key="'c-' + c.speakerPos + '-' + index"
          class="mt-2 mb-4 span-container"
          :class="{
            'highlight-edit-section': isEditMode,
            'has-span': (
              c.content && c.content.length > 0 &&
              c.content[0].startOffset <= currentPlaytimeWithOffset &&
              c.content[c.content.length -1].endOffset >= currentPlaytimeWithOffset
            ),
          }"
          :data-i="c.speakerPos"
          :contenteditable="isEditMode"
        >
          <template v-for="(span, spanIx) in c.content">
            <template
              v-if="
                editingSpeakerAt != c.speakerPos &&
                  (speakerChangeRequests[span.start] || contentChangeRequests[span.start])
              "
            >
              <el-tooltip
                :key="`${span.start}-${span.end}-${span.content}-${metadata.requestedChanges.length}-${spanIx}`"
                transition=""
              >
                <change-requests
                  slot="content"
                  :scrs="speakerChangeRequests[span.start]"
                  :crs="contentChangeRequests[span.start]"
                  :actor-map="actorMap"
                  :user-map="userMap"
                  @approve="approve"
                  @reject="reject"
                />
                <transcription-span
                  :ref="`trSpan-${span.start}-${span.end}`"
                  :span="span"
                  :speaker-pos="c.speakerPos"
                  :editing-speaker-at="editingSpeakerAt"
                  :incidents="incidents"
                  :unclear-threshold="unclearThreshold"
                  :current-playtime="currentPlaytimeWithOffset"
                  :keyword-indices="keywordIndices"
                  :keyword-current-index="keywordCurrentIndex"
                  :code-word-indices="codeWordIndices"
                  :audio-ready="activeSpanClicks"
                  :clip-range="clipRange"
                  :is-editing="isEditMode"
                  :signal-value="signalValue"
                  :unclear-value="unclearValue"
                  has-change-request
                  @seek="onSeek"
                  @index="(ind) =>$emit('index', ind)"
                  @incident="editIncident"
                  @nextSpeaker="goToNextSpeaker"
                  @prevSpeaker="goToPrevSpeaker"
                  @insertSpeaker="insertSpeakerWithStart"
                  @endEditing="cancelEdits"
                />
              </el-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-for -->
            <transcription-span
              v-else
              :ref="`trSpan-${span.start}-${span.end}`"
              :span="span"
              :speaker-pos="c.speakerPos"
              :editing-speaker-at="editingSpeakerAt"
              :incidents="incidents"
              :unclear-threshold="unclearThreshold"
              :current-playtime="currentPlaytimeWithOffset"
              :keyword-indices="keywordIndices"
              :keyword-current-index="keywordCurrentIndex"
              :code-word-indices="codeWordIndices"
              :audio-ready="activeSpanClicks"
              :clip-range="clipRange"
              :is-editing="isEditMode"
              :signal-value="signalValue"
              :unclear-value="unclearValue"
              @seek="onSeek"
              @index="(ind) =>$emit('index', ind)"
              @incident="editIncident"
              @nextSpeaker="goToNextSpeaker"
              @prevSpeaker="goToPrevSpeaker"
              @insertSpeaker="insertSpeakerWithStart"
              @endEditing="cancelEdits"
              @isPlaying="(el) => $emit('spanPlaying', el)"
              @editAt="goToNewEditPos"
            />
            <!--
              - Make custom Signals be attributable to a User/Actor
              -->
          </template>
        </div>
      </template>
      <ul
        id="dmenu"
        ref="dmenu"
        class="el-dropdown-menu el-popper"
        style="display:none;transform:translate3d(0,0,0);"
      >
        <li
          tabindex="1"
          class="el-dropdown-menu__item"
          @click="menuItemEdit"
        >
          Edit
        </li>

        <li
          v-if="focusedNodes.length === 1 && focusedNodes[0].className.indexOf('unclear') > -1"
          tabindex="2"
          class="el-dropdown-menu__item"
          @click="markClear"
        >
          Mark Word Correct
        </li>

        <li
          v-if="focusedNodes.length === 1 && focusedNodes[0].className.indexOf('redacted') > -1"
          tabindex="2"
          class="el-dropdown-menu__item"
          @click="unRedactContent"
        >
          Remove Redaction
        </li>

        <li
          v-else-if="highlightedRange !== null"
          tabindex="3"
          class="el-dropdown-menu__item"
          @click="redactContent"
        >
          Redact Content
        </li>
        <li
          v-if="focusedNodes.length > 0 &&
            focusedNodes.filter((f) => f.className.indexOf('incident') === -1).length === 0"
          tabindex="3"
          class="el-dropdown-menu__item"
          @click="viewIncident"
        >
          View Area of Interest
        </li>

        <li
          v-if="highlightedRange !== null"
          tabindex="3"
          class="el-dropdown-menu__item"
          @click="createIncident"
        >
          {{ displayIncidents ? 'Save Selection' : 'Mark Of Interest' }}
        </li>

        <li
          v-if="isSpanRightClicked == true"
          tabindex="4"
          class="el-dropdown-menu__item"
          @click="insertSpeaker"
        >
          Insert Speaker Before
        </li>
        <li
          tabindex="5"
          class="el-dropdown-menu__item"
          @click="assignToSpeaker"
        >
          Change Speaker
        </li>
        <li
          v-if="highlightedRange !== null && displayIncidents"
          tabindex="6"
          class="el-dropdown-menu__item"
          @click="onContextMenuSaveClip"
        >
          Save Clip
        </li>
      </ul>
      <incident-form-modal
        ref="incidentForm"
        @created="createdIncident"
        @updated="updatedIncident"
      />
      <add-people-modal
        ref="assignToSpeakerForm"
        :case-id="caseId"
        :evidence-id="evidenceId"
        associate
        @associated="assignContentToSpeaker"
        @created="assignContentToSpeaker"
        @close="closeAssignSpeakerForm"
      />
      <clip-form-modal
        ref="clipForm"
        :evidence-id="evidenceId"
        @clip="(c) => $emit('updatedClip', c)"
        @close="() => highlightedRange = null"
      />
      <metadata-form-modal
        ref="metadataForm"
        @close="() => highlightedRange = null"
      />
    </div>
  </div>
</template>

<script>
import {postChangeRequests, postChangeRequestApproval, postMarkClear, postSpeakerChangeRequest, postRemoveRedaction, postRedaction, getEvidenceCases, getUsersOverview, getActors} from "../../../../api";
import {mapGetters, mapMutations} from "vuex";
import TranscriptionSpeaker from "./TranscriptionSpeaker.vue";
import ChangeRequests from "./ChangeRequests.vue";
import IncidentFormModal from "../IncidentFormModal.vue";
import MetadataFormModal from "../MetadataFormModal.vue";
import AddPeopleModal from "../../../../components/DashboardV2/Case/AddPeopleModal.vue";
import ClipFormModal from "../ClipFormModal.vue";
import {isDefined} from "../../../../api/helpers";
import TranscriptionSpan from "./TranscriptionSpan.vue";
import {IncidentType} from "../../../../util/enums";
import moment from "moment";
import {ethosRouteNames} from "../../../../routes/routeNames";

const stringTimes = (s, m) => {
  let seconds = typeof s === "string" ? parseInt(s, 10) : s;
  if (isNaN(seconds)) seconds = 0;
  let millis = typeof m === "string" ? parseInt(m, 10) : m;
  if (isNaN(millis)) millis = 0;
  return seconds + (millis / 1000);
};

export default {
  components: {
    ChangeRequests,
    TranscriptionSpeaker,
    ClipFormModal,
    IncidentFormModal,
    AddPeopleModal,
    MetadataFormModal,
    TranscriptionSpan,
  },
  name: "TokenizedTranscriptionContent",
  props: {
    content: {
      type: String,
      default: null,
    },
    metadata: {
      type: Object,
      default: null,
    },
    evidenceId: {
      type: Number,
      default: null,
    },
    transcriptionId: {
      type: Number,
      default: 0,
    },
    transcriptionName: {
      type: String,
      default: "Transcript",
    },
    incidents: {
      type: Array,
      default() {
        return [];
      },
    },
    currentPlaytime: {
      type: Number,
      default: 0,
    },
    keywordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    keywordCurrentIndex: {
      type: Number,
      default: -1,
    },
    codeWordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    clip: {
      type: Object,
      default: null,
    },
    signalValue: {
      type: Boolean,
      default: true,
    },
    unclearValue: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    highlightedRange: function(n, o) {
      if (n == null && n != o) {
        const isFormOpen = this.$refs.assignToSpeakerForm.isDisplayed ||
          this.$refs.clipForm.isDisplayed ||
          this.$refs.incidentForm.isDisplayed;
        if (isFormOpen) {
          this.highlightedRange = o;
        } else {
          this.highlightOnContentRange(o.nodes.start, o.nodes.end, "selected", true);
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", [
      "userEmail",
    ]),
    ...mapGetters("data", [
      "getEvidence",
      "getActorMap",
      "getUserMap",
      "displayIncidents",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    hasClip() {
      return isDefined(this.clip);
    },
    currentPlaytimeWithOffset() {
      const clipOffset = this.hasClip ? this.clip.startOffset / 1000 : 0;
      return this.currentPlaytime + clipOffset;
    },
    contentChangeRequests() {
      if (!this.metadata || !this.metadata.requestedChanges) return [];
      const t = this.metadata.requestedChanges
        .filter((rc) => rc.type == "E")
        .reduce((all, rc) => {
          if (!all[rc.s]) all[rc.s] = [];
          all[rc.s].unshift(rc); // newest first
          return all;
        }, {});
      return t;
    },
    speakerChangeRequests() {
      if (!this.metadata || !this.metadata.requestedChanges) return [];
      const combined = {};
      this.metadata.requestedChanges
        .filter((rc) => rc.type == "S")
        .reduce((all, rc) => {
          if (!all[rc.s]) all[rc.s] = [];
          all[rc.s].unshift(rc); // newest first
          return all;
        }, combined);
      return combined;
    },
    speakers() {
      return this.getSpeakers(this.metadata, this.content, this.spans);
    },
    spans() {
      return this.getSpans(this.metadata, this.content);
    },
    userMap() {
      return this.getUserMap;
    },
    actorMap() {
      return this.getActorMap;
    },
  },
  data() {
    return {
      activeSpanClicks: false,
      currentHighlight: {
        line: -1,
        index: -1,
      },
      clipRange: null,
      tslookup: [],
      editingSpeakerAt: -1,
      unclearThreshold: 75,
      transcriptIndex: null,
      focusedSection: -1,
      focusedNodes: [],
      highlightedRange: null,
      cases: [],
      isEditMode: false,
      editModeTransitionEnded: {},
      editObserver: null,
      isSpanRightClicked: false,
      rightClickTarget: null,
      removedSpans: [],
      loading: true,
      assignSpeakerEventData: null,
    };
  },
  mounted() {
    if (
      Object.keys(this.getUserMap).length +
      Object.keys(this.getActorMap).length === 0 ) {
      this.loadPeople();
    } else {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.stopObserving();
  },
  methods: {
    ...mapMutations("data", [
      "putActorMap",
      "putUserMap",
    ]),
    startObserving() {
      if (this.observer) {
        return;
      }
      const config = {characterData: true, childList: true, subtree: true};

      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "characterData") {
            const key = mutation.target.parentElement.attributes["data-key"].value;
            // trigger onInput of span
            this.$refs[`trSpan-${key}`][0].onInput(mutation.target.data);
          }
          if (mutation.type === "childList") {
            // deleted words will be tracked here so that when save is called
            // we can apply those deletions (replace) to the content
            mutation.removedNodes.forEach((node) => {
              // check if node is a space to determine that it is a "replace"
              // and shouldn't be added back
              // TODO: look into a better way of detecting "replace"'d nodes
              if (node.dataset.o === " ") return;
              const clone = node.cloneNode(true);
              clone.style.visibility = "hidden";
              this.removedSpans.push(clone);
            });
          }
        });
      });

      this.$refs.parentDiv.querySelectorAll(".span-container").forEach((node) => {
        if (!this.observer.takeRecords().length) {
          this.observer.observe(node, config);
        }
      });
    },
    stopObserving() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
    getSpans(metadata, content) {
      if (!metadata || !metadata.entries || !content) return [];
      const entries = metadata.entries.map((e) => {
        return {
          content: content.slice(e.s, e.e),
          start: e.s,
          end: e.e,
          startOffset: stringTimes(e.st, e.sTm),
          endOffset: stringTimes(e.et, e.eTm),
          confidence: e.c,
          redacted: e.r === true,
        };
      });
      return entries;
    },
    getSpeakers(metadata, content, spans) {
      if (!metadata || !metadata.speakers || !content || this.loading) return [];
      let lastIndex = content.length;

      const speakers = metadata.speakers.slice().reverse().map((s) => {
        const slice = spans.filter((sp) => sp.start >= s.s && sp.end <= lastIndex);
        lastIndex = s.s;
        const toReturn = {
          content: slice,
          rawContent: slice.reduce((a, e) => a + e.content, ""),
          speakerPos: s.s,
          speakerActorId: null,
          speakerUserId: null,
          speakerTag: s.tag,
        };
        if (isDefined(s.uid)) {
          toReturn.speakerUserId = s.uid;
        } else if (isDefined(s.aid)) {
          toReturn.speakerActorId = s.aid;
        }
        return toReturn;
      }).reverse();
      return speakers;
    },
    loadUsers() {
      getUsersOverview().then((response) => {
        const usersById = {};
        response.forEach((e) => usersById[e.id] = e);
        this.putUserMap(usersById);
      }).catch((ex) => {
        this.$notifyError("Loading LEO Data Failed", ex);
      });
    },
    loadActors() {
      getActors().then((response) => {
        const actorsById = {};
        response.forEach((e) => actorsById[e.id] = e);
        this.putActorMap(actorsById);
      }).catch((ex) => {
        this.$notifyError("Loading Subject Data Failed", ex);
      });
    },
    loadPeople() {
      this.loading = true;
      Promise.allSettled([this.loadActors(), this.loadUsers()]).finally(() => {
        this.loading = false;
      });
    },
    highlightWordBefore(currentTime) {
      for (let i = this.tslookup.length - 1; i >= 0; i--) {
        if (this.tslookup[i].start <= currentTime) {
          this.currentHighlight.line = this.tslookup[i].line;
          this.currentHighlight.index = this.tslookup[i].index;
          return;
        }
      }
    },
    bindClicks() {
      this.activeSpanClicks = true;
    },
    handleClick(e) {
      if (e && e.target) {
        this.rightClickTarget = e.target;
        const isSpan = e.target.nodeName.toUpperCase() === "SPAN";
        if (isSpan) this.isSpanRightClicked = true;
        this.showRightClickMenu(e);
      }
    },
    showRightClickMenu(e) {
      if (this.editingSpeakerAt >= 0) return;
      e.stopPropagation();
      e.preventDefault();
      try {
        this.focusedSection = parseInt(e.currentTarget.dataset["i"], 10);
      } catch (ex) {
        console.log("Unable to find section index");
      }
      this.focusedNodes = [e.target];
      const x = e.clientX;
      const y = e.clientY;

      // Check to see if we rightclicked on highlighted text
      try {
        if (e.target.contentEditable !== "true") {
          let onHighlighted = false;
          const s = window.getSelection();
          if (s.toString().trim().length > 0) {
            e.target.childNodes.forEach((n) => onHighlighted = onHighlighted || s.containsNode(n));
            if (onHighlighted) {
            // Start of selection
              const anchorNode = s.anchorNode instanceof HTMLSpanElement ? s.anchorNode : s.anchorNode.parentNode;
              // End of selection
              const focusNode = s.focusNode instanceof HTMLSpanElement ? s.focusNode : s.focusNode.parentNode;
              if (anchorNode && focusNode) {
                this.highlightOnContentRange(anchorNode, focusNode, "selected");
                const as = parseInt(anchorNode.getAttribute("data-s"), 10);
                const ae = parseInt(anchorNode.getAttribute("data-e"), 10);
                const fs = parseInt(focusNode.getAttribute("data-s"), 10);
                const fe = parseInt(focusNode.getAttribute("data-e"), 10);
                const start = Math.min(as, fs);
                const end = Math.max(ae, fe);
                if (isDefined(start) && isDefined(end)) {
                  this.highlightedRange = {
                    start,
                    end,
                    text: this.content.slice(start, end),
                    type: IncidentType.transcript,
                    transcriptId: this.transcriptionId,
                    evidenceId: this.evidenceId,
                    nodes: {
                      start: as === start ? anchorNode : focusNode,
                      end: as === start ? focusNode : anchorNode,
                    },
                  };
                }
              }
            }
          }
        }
      } catch (ex) {
        console.error("Unable to find out if clicking highlighted");
      }

      this.$refs.dmenu.style = `transform:translate3d(${x}px,${y}px,0);display:block`;
    },
    onSeek(endTime) {
      this.clearHighlights();
      this.$emit("seek", endTime);
    },
    clearHighlights() {
      document.querySelectorAll("div.span-container span").forEach((el) => {
        try {
          const key = el.attributes["data-key"].value;
          const ref = this.$refs[`trSpan-${key}`][0];
          if (ref.isHighlighted) ref.isHighlighted = false;
        } catch (ex) {}
      });
    },
    highlightOnContentRange(startNode, endNode, className, doRemove) {
      this.clearHighlights();
      let sibling = startNode;
      let prevSibling = null;
      while (sibling != null) {
        const key = sibling.attributes["data-key"].value;
        if (doRemove) {
          this.$refs[`trSpan-${key}`][0].isHighlighted = false;
        } else {
          if (sibling.className.indexOf("span-container") === -1) {
            this.$refs[`trSpan-${key}`][0].isHighlighted = true;
          }
        }
        sibling = sibling.nextSibling;
        if (prevSibling && prevSibling == endNode) break;
        if (sibling == null) {
          // find next speaker section
          sibling = prevSibling.parentNode.nextSibling;
          while (sibling != null && sibling.className.indexOf("span-container") === -1) {
            sibling = sibling.nextSibling;
          }
          sibling = sibling.firstChild;
        }
        prevSibling = sibling;
      }
    },
    replaceSection(speakerPos) {
      let first = true;
      document.querySelectorAll(`div.span-container[data-i="${speakerPos}"] span`).forEach((el) => {
        if (first) {
          first = false;
          el.focus();
        } else {
          el.style.visibility = "hidden";
        }
      });
    },
    saveEdits() {
      this.doSaveEdits().then((t) => {
        this.$emit("updated", t);
        this.cancelEdits();
        this.$notifySuccess("Changes Requested");
      }).catch((ex) => {
        this.$notifyError("Unable to Request Changes", ex);
      }); // Its a replace
    },
    async doSaveEdits() {
      // Nodes detected as removed from the observer are then added back as hidden.
      // The hidden nodes should be treated as "replaced".
      // Changed nodes should be treated as "formatted".

      const container = document.querySelector(".tokenized-transcription-content");
      const hiddenGroups = this.findAdjacentHiddenSpans(this.removedSpans);
      this.removedSpans = [];
      const changes = [];
      if (hiddenGroups.length > 0) {
        changes.push(...this.prepareContentReplaceChanges(hiddenGroups));
      }
      for (const hg of hiddenGroups) {
        for (const sp of hg) {
          // remove the data-d attribute which indicates a change was made,
          // since this node was removed/hidden, it shouldn't be considered
          // as a "formatted" change.
          sp.removeAttribute("data-d");
        };
      }

      const changedElements = container.querySelectorAll("span[data-d]");
      const formattedChanges = [...changedElements].map((c) => this.formatChange(c));
      changes.push(...formattedChanges);

      if (changes.length === 0) return; // exit - nothing to do
      return await this.contentChange(changes);
    },
    findAdjacentHiddenSpans(spans) {
      const hiddenGroups = [];
      let currentGroup = [];
      let previousEndIndex = -1;

      spans.forEach((span, i) => {
        const startIdx = parseInt(span.getAttribute("data-s"), 10);
        const endIdx = parseInt(span.getAttribute("data-e"), 10);

        if (span.style.visibility === "hidden") {
          // If the start index is equal to the previous end index, it's part of the same group
          if (startIdx === previousEndIndex) {
            currentGroup.push(span);
          } else {
            // Otherwise, push the current group to hiddenGroups and start a new one
            if (currentGroup.length > 0) {
              hiddenGroups.push(currentGroup);
            }
            currentGroup = [span];
          }
          previousEndIndex = endIdx;
        } else if (currentGroup.length > 0) {
          // If the span is not hidden and there is a current group, push the group to the result and start a new group
          hiddenGroups.push(currentGroup);
          currentGroup = [];
          previousEndIndex = -1; // Reset the previous end index since the current span is not hidden
        }
        // If we're on the last span and there's a current group, push the group to the result
        if (i === spans.length - 1 && currentGroup.length > 0) {
          hiddenGroups.push(currentGroup);
        }
      });

      return hiddenGroups; // Returns an array of arrays, where each sub-array is a group of adjacent hidden spans
    },
    cancelEdits() {
      this.isEditMode = false;
      this.stopObserving();
      this.editingSpeakerAt = -1;
    },
    insertSpeaker() {
      if (this.focusedNodes.length > 0) {
        const start = parseInt(this.focusedNodes[0].dataset["s"], 10);
        this.insertSpeakerWithStart(start);
      }
    },
    insertSpeakerWithStart(start) {
      const me = this;
      postSpeakerChangeRequest(
        this.transcriptionId, start, null, null, false, true, null, null, null
      ).then((t) => {
        me.$emit("updated", t);
        me.$notifySuccess("Successfully requested to add Speaker");
        me.$nextTick(() => {
          if (me.editingSpeakerAt !== -1) me.goToNextSpeaker();
        });
      }).catch((ex) => {
        this.$notifyError("Unable to request new Speaker", ex);
      });
    },
    assignContentToSpeaker(person) {
      const eventData = this.assignSpeakerEventData;
      const userId = isDefined(person) ? person.userId : null;
      const actorId = isDefined(person) ? person.actorId : null;
      if ((isDefined(userId) || isDefined(actorId)) && isDefined(eventData)) {
        const me = this;
        postSpeakerChangeRequest(
          this.transcriptionId,
          eventData.start,
          eventData.end,
          null,
          false,
          true,
          null,
          userId,
          actorId
        ).then((t) => {
          this.assignSpeakerEventData = null;
          me.$emit("updated", t);
          me.$notifySuccess("Successfully requested to add Speaker");
          me.$nextTick(() => {
            if (me.editingSpeakerAt !== -1) me.goToNextSpeaker();
          });
        }).catch((ex) => {
          this.$notifyError("Unable to request new Speaker", ex);
        });
      }
    },
    onContextMenuSaveClip() {
      if (this.highlightedRange == null) {
        this.$notifyError("You must first select the text to clip.");
        return;
      }
      const clip = {
        in: this.highlightedRange.nodes.start.dataset.so,
        out: this.highlightedRange.nodes.end.dataset.eo,
      };
      this.saveClip(clip);
    },
    saveClip(details) {
      console.log("Saving in", details.in, "out", details.out);
      this.$refs.clipForm && this.$refs.clipForm.display(details.in * 1000, details.out * 1000);
      const range = this.highlightedRange;
      this.$nextTick(() => {
        this.highlightedRange = range;
      });
    },
    menuItemEdit(directionHint) {
      this.editingSpeakerAt = this.focusedSection;
      this.closeMenu();
      const containers = this.$refs.parentDiv.querySelectorAll(".span-container");
      containers.forEach((node) => {
        // transitionend event only firing of background-color with current styling
        const state = {
          "background-color": false,
        };
        this.editModeTransitionEnded[`i-${node.dataset.i}`] = state;
      });

      // listen for transitionend event on all span-containers and then start observing
      // this ensures we're not observing while the transition is happening
      containers.forEach((node) => {
        node.addEventListener("transitionend", (e) => {
          this.editModeTransitionEnded[`i-${node.dataset.i}`][e.propertyName] = true;
          if (this.checkAllPropertiesTrue(this.editModeTransitionEnded)) {
            this.startObserving();
          }
        }, {once: true});
      });
      this.isEditMode = true;
    },
    checkAllPropertiesTrue(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const nestedObj = obj[key];
          for (const prop in nestedObj) {
            if (nestedObj.hasOwnProperty(prop)) {
              if (nestedObj[prop] !== true) {
                return false; // Return false as soon as one property is not true
              }
            }
          }
        }
      }
      return true;
    },
    goToNewEditPos(params) {
      this.editingSpeakerAt = params.speakerPos;
      this.focusedNodes = [params.target];
      const me = this;
      this.$nextTick(() => {
        try {
          if (me.focusedNodes && me.focusedNodes.length > 0) {
            me.focusedNodes = [];
            const spanEl = document.querySelector(`span[data-s="${params.startPos}"]`);
            if (spanEl) spanEl.focus();
            return;
          }
        } catch (ex) {}
        try {
          const speakerEl = document.querySelector(`div[data-i="${params.speakerPos}"]`);
          speakerEl.firstChild.focus();
        } catch (ex) {}
      });
    },
    closeMenu() {
      this.menuTranscriptIndex = null;
      this.$refs.dmenu.style.display="none";
      this.highlightedRange = null;
      this.isSpanRightClicked = false;
    },
    prepareContentReplaceChanges(groups) {
      if (groups.length <= 0) throw new Error("No elements found");
      const changes = [];
      for (const els of groups) {
        const oldContent = els.reduce((all, el) => {
          all += el.dataset.o;
          return all;
        }, "");
        const startIndex = els[0].dataset.s;
        const endIndex = els[els.length - 1].dataset.e;
        const newContent = "";
        changes.push({startIndex, endIndex, newContent, oldContent});
      }
      return changes;
    },
    formatChange(el) {
      const oldContent = el.dataset.o;
      const startIndex = el.dataset.s;
      const endIndex = el.dataset.e;
      const oldContentTrimmed = oldContent.trim();
      const changedTo = el.innerText.trim();
      const originalIndex = oldContent.indexOf(oldContentTrimmed);
      const newContent = oldContent.slice(0, originalIndex) + changedTo + oldContent
        .slice(originalIndex + oldContent.length);
      return {startIndex, endIndex, newContent, oldContent};
    },
    async contentChange(formattedChanges) {
      return postChangeRequests(this.transcriptionId, formattedChanges);
    },
    removeSpeaker(crDetail) {
      postSpeakerChangeRequest(
        this.transcriptionId, crDetail.speakerPos, null, null, true, false, null, null, null
      ).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Successfully requested to update Speaker");
      }).catch((ex) => {
        this.$notifyError("Unable to request new Speaker", ex);
      });
    },
    selectSpeaker(crDetail) {
      const me = this;
      this.$emit("selectSpeaker", {
        uid: crDetail.speakerUserId,
        aid: crDetail.speakerActorId,
        startIndex: crDetail.speakerPos,
        callback: (opts, p, shouldMapMatching) => {
          if (!isDefined(p)) return;
          const userId = p.userId;
          const actorId = p.actorId;
          if (!isDefined(actorId) && !isDefined(userId)) return;
          postSpeakerChangeRequest(
            this.transcriptionId,
            opts.startIndex,
            null,
            null,
            false,
            false,
            shouldMapMatching,
            userId,
            actorId
          )
            .then((t) => {
              this.$emit("updated", t);
              me.$notifySuccess("Successfully requested to update Speaker");
            }).catch((ex) => {
              me.$notifyError("Unable to request new Speaker", ex);
            });
        },
      });
    },
    markClear() {
      const span = this.focusedNodes[0];
      if (!span) return;
      const start = span.dataset.s;
      const end = span.dataset.e;
      postMarkClear(this.transcriptionId, start, end, span.innerText).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    unRedactContent() {
      const span = this.focusedNodes[0];
      if (!span) return;
      const start = span.dataset.s;
      const end = span.dataset.e;
      postRemoveRedaction(this.transcriptionId, start, end, span.innerText).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    redactContent() {
      if (!this.highlightedRange) return;
      postRedaction(
        this.transcriptionId,
        this.highlightedRange.start,
        this.highlightedRange.end,
        this.highlightedRange.text
      ).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    createIncident() {
      this.$refs.incidentForm.display(this.highlightedRange);
      this.highlightedRange = null;
    },

    assignToSpeaker() {
      if (this.highlightedRange == null && this.rightClickTarget != null) {
        let startNode = null;
        let endNode = null;
        // highlights the speaker content based on where the right click was,
        // if a speaker tag was right clicked, it will emulate a click on it

        if (this.rightClickTarget.className.indexOf("span-container") > -1) {
          // span container was right clicked
          startNode = this.rightClickTarget.firstChild;
          endNode = this.rightClickTarget.lastChild;
        } else if (this.rightClickTarget.dataset.spk != null) {
          // speaker node was right clicked, emulate click on speaker
          const s = this.$refs[`spk-${this.rightClickTarget.dataset.spk}`];
          s[0].select();
          return;
        } else if (this.rightClickTarget instanceof HTMLSpanElement) {
          // span was right clicked
          startNode = this.rightClickTarget.parentNode.firstChild;
          endNode = this.rightClickTarget.parentNode.lastChild;
        } else if (this.rightClickTarget instanceof HTMLDivElement) {
          // div was right clicked
          const spanContainer = this.rightClickTarget.nextSibling;
          startNode = spanContainer.firstChild;
          endNode = spanContainer.lastChild;
        } else {
          // something else was right clicked
          return;
        }
        this.highlightedRange = {
          start: startNode.dataset.s,
          end: endNode.dataset.e,
          startTimeOffset: startNode.dataset.so,
          endTimeOffset: startNode.dataset.eo,
          text: this.content.slice(startNode.dataset.s, endNode.dataset.e),
          type: IncidentType.transcript,
          transcriptId: this.transcriptionId,
          evidenceId: this.evidenceId,
          nodes: {
            start: startNode,
            end: endNode,
          },
        };
        this.highlightOnContentRange(startNode, endNode, "selected");
      }

      if (this.highlightedRange != null) {
        this.assignSpeakerEventData = Object.assign({}, this.highlightedRange);
        this.$refs.assignToSpeakerForm.showModal = true;
      }
    },
    closeAssignSpeakerForm() {
      this.highlightedRange = null;
    },
    onClipRange(clipRange) {
      if (clipRange) {
        console.log("clipRange in", clipRange.in, "out", clipRange.out);
      }
      this.clipRange = clipRange;
    },
    viewIncident() {
      const spanS = this.focusedNodes[0].dataset.s;
      const spanE = this.focusedNodes[0].dataset.e;
      try {
        const incident = this.$refs[`trSpan-${spanS}-${spanE}`][0]?.incident;
        this.viewClip(incident);
      } catch (ex) {
        console.warn(ex);
      }
    },
    viewClip(incident) {
      if (isDefined(incident) && isDefined(incident.clipId)) {
        this.$router.push({name: ethosRouteNames.ClipV2, params: {clipId: incident.clipId}});
      }
    },
    editIncident(incident) {
      if (!this.displayIncidents) {
        this.viewClip(incident);
        return;
      }
      this.$refs.incidentForm.display(
        {
          start: incident.startOffset,
          end: incident.endOffset,
          text: this.content.slice(incident.startOffset, incident.endOffset),
          type: IncidentType.transcript,
          transcriptId: this.transcriptionId,
          evidenceId: this.evidenceId,
        },
        incident
      );
      this.highlightedRange = null;
    },
    approve(data) {
      postChangeRequestApproval(this.transcriptionId, data.cr.id, true, data.reason).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    reject(data) {
      postChangeRequestApproval(this.transcriptionId, data.cr.id, false, data.reason).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Rejected Successfully!");
      }).catch((ex) => {
        this.$notifyError("Unable to Reject the change", ex);
      });
    },
    goToNextSpeaker() {
      this.adjacentSpeaker(true);
    },
    goToPrevSpeaker() {
      this.adjacentSpeaker(false);
    },
    adjacentSpeaker(forwards) {
      const editing = this.editingSpeakerAt;
      this.doSaveEdits(editing).then(() => {
        const lastSpeaker = document.querySelector(`div[data-i="${editing}"]`);
        let sibling = forwards ? lastSpeaker.nextSibling : lastSpeaker.previousSibling;
        while (sibling !== null && sibling.className && sibling.className.indexOf("span-container") === -1) {
          sibling = forwards ? sibling.nextSibling : sibling.previousSibling;
        }
        if (sibling != null) {
          try {
            this.focusedSection = parseInt(sibling.dataset["i"], 10);
            this.menuItemEdit(forwards ? "forwards" : "reverse");
          } catch (ex) {
            console.log("Unable to find section index");
          }
        }
      }).catch((ex) => {
        this.cancelEdits();
        this.$notifyError("Cancelling edits, unable to save", ex);
      });
    },
    createdIncident(incident) {
      this.$emit("incident", incident);
    },
    updatedIncident(incident) {
      this.$emit("incident", incident);
    },
    formatTime(float) {
      let allSeconds = Math.round(float);
      const seconds = allSeconds % 60;
      allSeconds -= seconds;
      const minutes = Math.floor(allSeconds / 60) % 60;
      allSeconds -= minutes * 60;
      const hours = Math.floor(allSeconds / (60 * 60)) % 60;
      return `+ ${`${hours}`.padStart(2, "0")}:${`${minutes}`.padStart(2, "0")}:${`${seconds}`.padStart(2, "0")}`;
    },
    async export(e) {
      const evWithCases = await getEvidenceCases(this.evidenceId);
      this.cases = evWithCases?.cases;

      const maxCharCount = 47;
      const firstCharCount = 10;
      const maxLineCount = 25;

      // Start the page code
      let charCount = 0;
      const pages = [];
      let lines = [];
      let lineCopy = "";
      const nameStyle = " style=''";

      const charStart = this.speakers[0]?.content[0]?.start;
      const lineStart = charStart > 0 ? Math.ceil(charStart / maxCharCount + 1) : 1;

      this.speakers.forEach((sp) => {
        let sName = `Speaker: ${sp.speakerTag}`;
        if (isDefined(sp.speakerUserId ) && this.userMap[sp.speakerUserId]) {
          sName = this.userMap[sp.speakerUserId].fullName;
        } else if (isDefined(sp.speakerActorId ) && this.actorMap[sp.speakerActorId]) {
          sName = this.actorMap[sp.speakerActorId].fullName;
        }

        // Make it uppercased
        sName = sName.toUpperCase() + ":";
        sName = sName.split(" ").reverse();

        // IF redaction of the same length is desired, then instead of -- below use : s.c.replace(/./gmi, "*")
        // Assuming not, because it would leave clues as to what was removed
        const remainingContent = sp.content.map((s) => {
          return {c: s.content, r: s.redacted};
        }).map((s) => s.r ? " --" : s.c).join("").trim().split(" ").reverse();

        // Close this line - need a new one for the speaker
        if (charCount > 0) {
          lines.push(lineCopy);
          lineCopy = "";
          charCount = 0;
        }

        if (lines.length >= maxLineCount) {
          // Reset - close the page
          pages.push({lines});
          lines = [];
          charCount = 0;
        }

        let isFirst = true;
        while (sName.length > 0) {
          // Each speaker is a new line
          charCount = 0;

          let OOB = false; // OutOfBounds
          let nameForLine = "";

          // Loop and stitch the name together as we go
          while (!OOB && sName.length > 0) {
            // First needs an indent
            const remainingSpace = (isFirst ? maxCharCount - firstCharCount : maxCharCount) - nameForLine.length;
            const name = sName.pop();

            // We're at the start of a new line... if its too long just split it
            if (nameForLine.length === 0) {
              // Split it - if its too long
              let nameToAdd = name.slice(0, remainingSpace);
              if (nameToAdd.length < name.length) {
                // replace last char with hyphen and split
                nameToAdd = nameToAdd.slice(0, nameToAdd.length - 1) + "-";
                // Add any remainder back and call it out of bounds
                sName.push(name.slice(nameToAdd.length - 1));
                OOB = true;
              }
              nameForLine = nameToAdd;
            } else if (name.length + 1 <= remainingSpace) {
              // We fit - great - just append;
              nameForLine += ` ${name}`;
            } else {
              // next name part is too long; add in and move on.
              sName.push(name);
              OOB = true;
            }
          }

          // Now stitch
          lineCopy += `<b${isFirst ? nameStyle : ""}>${nameForLine}</b>`;
          charCount += (isFirst ? firstCharCount : 0) + nameForLine.length;

          // Close line IF sName has more
          if (sName.length > 0 || charCount === maxCharCount) {
            // End of the line; bump to next
            lines.push(lineCopy);
            lineCopy = "";

            if (lines.length >= maxLineCount) {
              // End of page; bump to next
              pages.push({lines});
              lines = [];
            }
            charCount = 0;
          }

          isFirst = false;
        }

        // Add a space if we're not at line end
        for (let x = 0; x < 2; x++) {
          if (charCount !== 0 && charCount + 1 < maxCharCount) {
            charCount++;
            lineCopy += "&nbsp;";
          }
        }

        // Apply the remaining content
        while (remainingContent.length > 0) {
          const next = remainingContent.pop();
          if (next.length < maxCharCount - charCount - 1) {
            // Just add it
            lineCopy += ` ${next}`;
            charCount += next.length + 1;
          } else {
            // End of the line; bump to next
            if (charCount > 0) {
              lines.push(lineCopy);
              lineCopy = "";
              charCount = 0;
            }

            if (lines.length >= maxLineCount) {
              // End of page; bump to next
              pages.push({lines});
              lines = [];
            }

            // Open the line
            if (next.length <= maxCharCount) {
              // We can add - because it fits
              lineCopy += next;
              charCount = next.length;
            } else {
              // Fit what we can
              lineCopy += next.slice(0, maxCharCount - 1) + "-";

              // Push remaining back
              remainingContent.push(next.slice(maxCharCount - 1));

              charCount = maxCharCount;
            }
          }

          if (charCount >= maxCharCount) {
            // End of the line; bump to next
            lines.push(lineCopy);
            lineCopy = "";
            charCount = 0;
            if (lines.length >= maxLineCount) {
              // End of page;
              pages.push({lines});
              lines = [];
            }
          }
        }
      });

      // append the closing tag for the line
      if (charCount > 0) lines.push(lineCopy);
      // append the closing tag for the page
      if (lines.length > 0) {
        // Fill page
        while (lines.length < maxLineCount) {
          lines.push(" ");
        }
        // Close page
        pages.push({lines});
      }

      // Build the HTML
      let transcriptHTML = "";
      for (let p = 0; p < pages.length; p++) {
        // transcriptHTML += `<div class="page" data-p="${p + 1}">`;
        for (let l = 0; l < pages[p].lines.length; l++) {
          transcriptHTML += `<div class="line" data-l="${l + 1}">${pages[p].lines[l]}</div>`;
        }
        // transcriptHTML += `<div class="page-count">${p + 1} of ${pages.length}</div></div>`;
      }

      const style = `
body {
  display: block;
  margin: 8px;
}
.transcript {
  font-family: 'Times New Roman';
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 2em;
  max-width: 46em;
}
.page {
  border: 2px solid black;
  counter-reset: line;
  margin: 1em 1em 6em 1em;
  padding: 1em;
  page-break-after: always;
  position: relative;
}
.page-count {
    position: absolute;
    bottom: -3em;
    left: 0;
    right: 0;
    text-align: center;
}
.line {
  counter-increment: line;
  margin-left: 5em;
  min-height: 2em;
  position: relative;
}

.line:before {
  content: counter(line);
  left: -5em;
  position: absolute;
  text-align: right;
  width: 2em;
}

p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}

table#hdrFtrTbl
{
    margin:0in 0in 0in 900in;
    width:1px;
    height:1px;
    overflow:hidden;
    display:none;
}

@page transcript
{size:8.5in 11.0in;
margin:1in 1in 1in 1in;
mso-header-margin:.5in;
mso-footer-margin:.5in;
mso-line-numbers-count-by:1;
mso-line-numbers-restart:continuous;
mso-line-numbers-start:${lineStart};
mso-header: h1;
mso-footer: f1;
mso-first-header: fh1;
mso-first-footer: ff1;
mso-paper-source:0;
}
div.transcript
  {page:transcript;}
      `;
      const exportedAt = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC";
      const caseNames = this.cases?.map((x) => x.Name)?.join(", ");
      const hdrFtrTable = `<table id='hdrFtrTbl' border='1' cellspacing='0' cellpadding='0'><tr style='height:1pt;mso-height-rule:exactly'><td><div style='mso-element:header' id="h1"><p class="MsoHeader"><table border="0" width="100%"><tr><td>Case(s): ${caseNames}</td></tr><tr><td>Transcript: ${this.transcriptionName}</td></tr></table></p></div></td><td><div style='mso-element:footer' id="f1"><p class="MsoFooter"><table width="100%" border="0" cellspacing="0" cellpadding="0"><tr><td align="center" class="footer"><g:message code="offer.letter.page.label"/><span style='mso-field-code: PAGE '></span> of <span style='mso-field-code: NUMPAGES '></span></td></tr><tr><td align='center' style='font-size:12px'>Exported at: ${exportedAt}</td></tr></table></p></div></td></tr></table>`;
      const html = `<!DOCTYPE html>\n<html lang="en">\n<head>\n<title>${this.transcriptionName}</title>\n<style>${style}</style></head><body><div class="transcript">\n${transcriptHTML}\n</div>${hdrFtrTable}</body></html>`;
      // 816px wide
      // 1064px length
      // const c = document.createElement("a");
      // c.style.display = "none";
      // c.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(html));
      // c.setAttribute("download", this.transcriptionName + ".html");
      // document.body.appendChild(c);
      // c.click();
      // document.body.removeChild(c);
      this.exportToDoc(html, this.transcriptionName);
    },
    exportToDoc(htmlContent, filename = "") {
      const html = htmlContent;
      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });
      // Specify link url
      const url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
      // Specify file name
      filename = filename?filename+".doc":"document.doc";
      // Create download link element
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      if (navigator.msSaveOrOpenBlob ) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;
        // Setting the file name
        downloadLink.download = filename;
        // triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    },
    editMetadata(e) {
      const evidence = this.getEvidence[this.evidenceId];
      const clip = this.clip;
      if (isDefined(clip)) {
        this.$refs.clipForm.display(0, 0, clip);
      } else {
        this.$refs.metadataForm && this.$refs.metadataForm.display(evidence);
      }
    },
  },
};
</script>

