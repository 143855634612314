<template>
  <div>
    <div
      class="dropdown case-evidence--filter"
      :class="[{ show: isOpen }]"
      @click="toggleDropDown"
    >
      <base-button
        link
        class="dropdown-toggle no-caret"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <FilterIcon></FilterIcon>
      </base-button>
      <div class="dropdown-menu dropdown-menu-lg-right">
        <a
          class="dropdown-item text-capitalize font-weight-bold text-white bg-black"
          >All File</a
        >
        <a class="dropdown-item text-capitalize font-weight-bold text-white"
          >Signals Only</a
        >
        <a class="dropdown-item text-capitalize font-weight-bold text-white"
          >Created By</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FilterIcon } from "vue-feather-icons";
import BaseButton from "../../BaseButton.vue";
export default {
  components: { BaseButton, FilterIcon },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closeDropDown(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropDown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropDown);
  },
};
</script>

<style lang="scss">
thead {
  overflow: visible;
}
.case-evidence--filter {
  .dropdown-menu:before {
    display: none;
  }
}
</style>
