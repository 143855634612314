<template>
  <div class="d-flex mb-4">
    <base-button
      size="sm"
      type="primary"
      class="ml-0"
      simple
      @click="(e) => $emit('editMetadata', e)"
    >
      <edit-2-icon
        class="mr-2"
        size="1.5x"
      />
      Profile
    </base-button>
    <base-button
      size="sm"
      type="primary"
      class="ml-0"
      simple
      @click="clip"
    >
      <x-icon
        v-if="clipping"
        class="mr-2"
        size="1.5x"
      />
      <scissors-icon
        v-else
        class="mr-2"
        size="1.5x"
      />
      {{ clipping ? "Cancel Clip" : "Clip" }}
    </base-button>
    <div class="flex-grow-1" />
    <base-button
      size="sm"
      type="primary"
      class="ml-0"
      :disabled="!canClip || !clipping"
      simple
      @click="(e) => $emit('saveClip', e)"
    >
      <save-icon
        class="mr-2"
        size="1.5x"
      />
      Save Clip
    </base-button>
    <base-button
      size="sm"
      type="primary"
      class="mx-0"
      simple
      @click="(e) => $emit('export', e)"
    >
      <download-icon
        class="mr-2"
        size="1.5x"
      />
      Export
    </base-button>
  </div>
</template>

<script>
import {DownloadIcon, Edit2Icon, SaveIcon, ScissorsIcon, XIcon} from "vue-feather-icons";
export default {
  name: "custom-media-control-buttons",
  components: {
    DownloadIcon,
    Edit2Icon,
    SaveIcon,
    ScissorsIcon,
    XIcon,
  },
  props: {
    canClip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      clipping: false,
    };
  },
  methods: {
    clip() {
      this.clipping = !this.clipping;
      this.$emit("clipping", this.clipping);
    },
  },
};
</script>
