<template>
  <bread-crumb :transparent="false">
    <BreadCrumbItem
      v-for="(route, index) in $route.matched.slice()"
      :key="route.name"
      style="display: inline-block"
    >
      <span
        :class="{
          'breadcrumb-current': index === $route.matched.length - 1,
        }"
      >
        <home-icon v-if="route.name === 'Home'" size="1.5x" class="pr-1" />

        <template v-if="index < $route.matched.length - 1">
          <router-link :to="{ name: route.name }" class="breadcrumb-link">
            {{ breadName(route) }}
          </router-link>
          <chevron-right-icon size="1.5x" class="mx-3" />
        </template>
        <template v-else>
          {{ breadName(route) }}
        </template>
      </span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb.vue";
import BreadCrumbItem from "./BreadcrumbItem";
import { HomeIcon, ChevronRightIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";
import { ethosRouteNames } from "../../routes/routeNames";
import { isDefined } from "../../api/helpers";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
    HomeIcon,
    ChevronRightIcon,
  },
  computed: {
    ...mapGetters("data", [
      "casesNameLabel",
      "caseNameLabel",
      "evidenceNameLabel",
      "projectNameLabel",
      "peopleNameLabel",
      "breadcrumbHints",
    ]),
  },
  methods: {
    breadName(route) {
      switch (route.name) {
        case ethosRouteNames.Cases:
          return this.casesNameLabel;
        case ethosRouteNames.Project:
          return this.projectNameLabel;
        case ethosRouteNames.Evidence:
        case ethosRouteNames.ProjectEvidence:
          return this.evidenceNameLabel;
        case ethosRouteNames.People:
        case ethosRouteNames.EvidencePeople:
        case ethosRouteNames.ProjectPeople:
          return this.peopleNameLabel;
        default:
          if (isDefined(this.breadcrumbHints[route.name])) {
            return this.breadcrumbHints[route.name];
          }
          break;
      }
      return route.name.split("_").pop();
    },
  },
};
</script>

<style scoped></style>
