<template>
  <div class="d-flex flex-column h-100">
  <div class="px-5 pt-5">      
    <base-input
        class="mb-4"
        :has-icon="true"
        addon-left-icon="fas fa-search"
        placeholder="Search within case"
        value=""
        @keyup.enter="search"
      />
      </div>
       <div class="flex-grow-1 overflow-auto">
    <div class="px-5 pb-5">
      <h6
        v-if="isViewCase && !isTitleEdit"
        class="case-input--title text-white"
        @click="enableTitleEdit()"
      >
        {{ caseModel.title || "Add Case Title" }}
      </h6>
      <input
        v-else
        ref="titleInput"
        v-model="caseModel.title"
        type="text"
        class="case-input case-input--title"
        placeholder="Add Case Title"
        @blur="setCaseTitle"
        @keyup.enter="setCaseTitle"
      >
      <h5 class="text-lightblue d-flex">
        <div class="nowrap">
          Case Number:
        </div>
        <input
          v-model="caseName"
          type="text"
          class="case-input font-16 text-lightblue"
          @blur="setCaseName"
          @keyup.enter="setCaseName"
        >
      </h5>
      <read-more
        v-if="isViewCase && !isDescriptionEdit"
        @onClick="enableDescriptionEdit()"
      >
        <div class="prewrap pre-wrap-adjust">
          {{ caseModel.description || "Add a brief description here..." }}
        </div>
      </read-more>
      <textarea
        v-else
        ref="descriptionInput"
        v-model="caseModel.description"
        type="text"
        rows="3"
        class="case-input font-16"
        placeholder="Add a brief description here..."
        @blur="setCaseDescription"
      />
    </div>
    <div class="h-divider" />
    <div class="p-5">
      <card
        v-for="(menu, i) in menus"
        :key="i"
        class="p-4 mb-5 cursor-pointer"
        :class="isMenuActive(menu) ? 'case-menu--active' : ''"
        @click="openMenu(menu)"
      >
        <component
          :is="menu.icon"
          size="32"
          class="text-lightblue mb-4"
        >
          {{
        }}
        </component>
        <div class="d-flex">
          <p class="case-menu-title font-16 font-weight-bold text-white mb-0">
            {{ menu.name }}
          </p>
          <div class="flex-1" />
          <p class="case-menu-title font-16 font-weight-bold text-white mb-0">
            {{ menu.count }}
          </p>
        </div>
      </card>
    </div>
       </div>
  </div>
</template>

<script>
import Card from "../../Cards/Card.vue";
import {
  FileIcon,
  ArchiveIcon,
  KeyIcon,
  CopyIcon,
  UserIcon,
} from "vue-feather-icons";
import ReadMore from "../ReadMore.vue";
import {putCase} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseInput from "../../Inputs/BaseInput.vue";
import {mapMutations} from "vuex";
import {ethosRouteNames} from "../../../routes/routeNames";

export default {
  components: {Card, ReadMore, BaseInput},
  props: {
    caseSummary: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isTitleEdit: false,
      isDescriptionEdit: false,
      caseModel: {
        title: null,
        name: null,
        description: null,
      },
      caseName: null,
    };
  },
  watch: {
    caseSummary: {
      immediate: true,
      handler(newVal) {
        this.caseModel = newVal;
        this.caseName = newVal?.name || "";
      },
    },
  },
  computed: {
    isViewCase() {
      return this.$route.matched.find((i) => i.name == "ViewCase") != null;
    },
    menus() {
      return [
        {
          icon: ArchiveIcon,
          name: "Evidence",
          count: this.caseSummary?.evidenceCount || 0,
          route: "CaseEvidence",
          children: ["CaseEvidenceList"],
        },
        {
          icon: KeyIcon,
          name: "Keywords",
          count: this.caseSummary?.caseKeywordCount || 0,
          route: "CaseKeywords",
          children: [],
        },
        {
          icon: CopyIcon,
          name: "Exhibits (Clips)",
          count: "",
          route: "CaseExhibits",
          children: [],
        },
        {
          icon: UserIcon,
          name: "People",
          count: (this.caseSummary?.userAttributionCount || 0) + (this.caseSummary?.actorAttributionCount || 0),
          route: "CasePeople",
          children: [],
        },
        {
          icon: FileIcon,
          name: "Notes",
          count: "",
          route: "CaseNotes",
          children: [],
        },
      ];
    },
  },
  mounted() {
    this.caseModel = this.caseSummary;
    this.caseName = this.caseSummary?.name || "";
  },
  methods: {
    ...mapMutations("data", ["setSearchKeyword"]),
    isMenuActive({route, children}) {
      const name = this.$route.name;
      return route == name || children.indexOf(name) != -1;
    },
    enableTitleEdit() {
      this.isTitleEdit = true;
      this.$nextTick(() => {
        this.$refs.titleInput.focus();
      });
    },
    enableDescriptionEdit() {
      this.isDescriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descriptionInput.focus();
      });
    },
    openMenu({route}) {
      this.$router.replace({
        name: route,
      });
    },
    setCaseName() {
      if (!isDefined(this.caseName)) return;
      this.updateCase();
    },
    setCaseTitle() {
      if (!isDefined(this.caseModel.title)) return;
      this.updateCase();
    },
    setCaseDescription() {
      if (!isDefined(this.caseName) || !isDefined(this.caseModel.title)) return;
      this.updateCase();
    },
    reload() {
      this.$emit("reload");
    },
    updateCase() {
      this.loading = true;
      putCase(
        this.caseModel.name,
        this.caseModel.title,
        this.caseName,
        this.caseModel.description
      ).then((response) => {
        this.caseModel = response;
        this.caseName = response.name || "";
        this.loading = false;
        this.$emit("updatedCase", response.id);
      }).catch((ex) => {
        this.loading = false;
        this.$notifyError("Saving Case Data Failed", ex);
      }).finally(() => {
        this.loading = false;
        this.isTitleEdit = false;
        this.isDescriptionEdit = false;
      });
    },
    search(e) {
      this.setSearchKeyword({
        keyword: e.target.value,
      });
      if (e.target.value != "" && this.$route.path.indexOf("search") === -1) {
        this.$router.replace({
          name: ethosRouteNames.CaseSearch,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.case-input {
  background: transparent;
  border: 0px;
  width: 100%;
  color: white;
  outline: none;
  font-weight: 400;
  resize: none;
  &::placeholder {
    color: white;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: white;
  }

  &::-ms-input-placeholder {
    color: white;
  }
  &--title {
    font-size: 20px;
  }
}
.case-menu--active {
  background: black !important;
  border: 2px solid var(--primary) !important;
}
.pre-wrap-adjust{
  margin-left: -0.2vw;
}
</style>
