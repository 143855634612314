<template>
  <table class="table tablesorter mb-0">
    <thead>
      <tr>
        <th
          width="55px"
          class="text-center"
        >
          <base-checkbox
            :checked="isSelectAll"
            @input="onSelectAll"
          />
        </th>
        <th
          v-for="(col, i) in columns"
          :key="i"
          class="text-capitalize font-weight-bold text-white"
          :class="col.class || ''"
          :width="col.width"
        >
          <slot :name="`header-${col.value}`">
            {{ col.label }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(item, index) in data"
      >
        <tr
          :key="index"
          class="cursor-pointer"
          :class="rowClass(index)"
          @click="() => $emit('clicked', {item, index})"
        >
          <td class="text-center">
            <base-checkbox
              :checked="isSelected(index)"
              @input="onCheckboxChange($event, index)"
            />
          </td>
          <td
            v-for="(col, i) in columns"
            :key="i"
            class="font-weight-bold"
            :class="col.tdClass"
          >
            <slot
              :name="`data-${col.value}`"
              :data="{
                item,
                value: item[col.value],
                index,
                selected: isSelected(index),
              }"
            >
              {{ item[col.value] }}
            </slot>
          </td>
        </tr>
        <slot
          name="nested-data"
          :data="{
            item,
            index,
          }"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import BaseCheckbox from "../Inputs/BaseCheckbox.vue";
export default {
  components: {BaseCheckbox},
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    disableRowClass: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          this.selected = to.slice();
        });
      },
    },
  },
  computed: {
    isSelectAll() {
      return this.data.length == this.selected.length;
    },
  },

  methods: {
    rowClass(index) {
      if (this.disableRowClass) return "";
      if (this.isSelected(index)) return "bg-black";
      return index % 2 == 0 ? "bg-dark-100" : "bg-dark-200";
    },
    isSelected(index) {
      return this.selected.indexOf(index) != -1;
    },
    onCheckboxChange(value, index) {
      const selectIndex = this.selected.indexOf(index);
      if (value && selectIndex == -1) {
        this.selected.push(index);
      }
      if (!value && selectIndex != -1) {
        this.selected.splice(selectIndex, 1);
      }
      this.$emit("input", this.selected);
    },
    onSelectAll(event) {
      if (event) {
        this.selected = this.data.map((item, i) => i);
      } else {
        this.selected = [];
      }
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style></style>
