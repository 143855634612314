<template>
  <div>
    <label
      for="fullDate"
      class="form-label"
    > {{ label }} </label>
    <div class="custom-date-input">
      <input
        id="millis"
        type="text"
        class="form-control"
        placeholder="Date"
        :value="$date(output.iso).tz(userTimezone).format('MMM DD, YYYY [at] HH:mm A')"
        @click.prevent="() => modalDisplayed = true"
      >
      <calendar-icon size="1.5x" />
    </div>
    <modal
      :show.sync="modalDisplayed"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      :show-close="false"
      :centered="true"
      class="modal-default modal-wide"
    >
      <h4
        slot="header"
        class="title title-up"
      >
        {{ header }}
      </h4>

      <div class="mb-3">
        <label
          for="date"
          class="form-label"
        > Date </label>
        <div class="custom-date-input">
          <el-date-picker
            v-model="date"
            type="date"
            effect="dark"
            class="dark"
            :clearable="false"
            popper-class="select-primary"
            placeholder="Pick a day"
            format="DD/MM/yyyy"
          />
          <calendar-icon size="1.5x" />
        </div>
      </div>
      <div class="d-flex flex-fill">
        <div class="mb-3 mr-3">
          <label
            for="hours"
            class="form-label"
          > Hours </label>
          <el-select
            id="hours"
            v-model="hours"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in hrOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 mr-3">
          <label
            for="mins"
            class="form-label"
          > Minutes </label>
          <el-select
            id="mins"
            v-model="mins"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in minOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 mr-3">
          <label
            for="secs"
            class="form-label"
          > Seconds </label>
          <el-select
            id="secs"
            v-model="secs"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in secOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 flex-grow-1">
          <label
            for="millis"
            class="form-label"
          > Milliseconds </label>
          <input
            id="millis"
            v-model="millis"
            type="number"
            :min="0"
            :max="999"
            class="form-control"
            placeholder="000"
          >
        </div>
      </div>
      <div class="mb-3">
        <timezone-field
          ref="tz"
          :name="tz"
          @changed="(t) => tz = t"
        />
      </div>

      <template slot="footer">
        <base-button
          size="sm"
          class="mr-auto"
          secondary
          simple
          @click="cancel"
        >
          Cancel
        </base-button>
        <base-button
          size="sm"
          class="ml-auto"
          @click="ok"
        >
          OK
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "src/components";
import {CalendarIcon} from "vue-feather-icons";
import {isDefined} from "../../../api/helpers";
import {stringsForRange} from "../../../util/util";
import TimezoneField from "./TimezoneField.vue";
import {mapGetters} from "vuex";

export default {
  name: "date-time-offset-form",
  components: {
    TimezoneField,
    Modal,
    CalendarIcon,
  },
  props: {
    originalDate: {
      type: Date,
      default() {
        return new Date();
      },
    },
    header: {
      type: String,
      default: "Select Date",
    },
    label: {
      type: String,
      default: "Date",
    },
  },
  data() {
    return {
      modalDisplayed: false,
      date: new Date(),
      hours: "00",
      mins: "00",
      secs: "00",
      millis: 0,
      tz: "America/Vancouver",
    };
  },
  watch: {
    output: function() {
      this.$emit("changed", this.output);
    },
  },
  computed: {
    ...mapGetters("data", [
      "userTimezone",
    ]),
    tzOffset() {
      return this.$tz.now.find((tz) => tz.name === this.tz).currentTimeOffsetInMinutes;
    },
    hrOptions() {
      return stringsForRange(0, 24, 2);
    },
    minOptions() {
      return stringsForRange(0, 60, 2);
    },
    secOptions() {
      return stringsForRange(0, 60, 2);
    },
    output() {
      let d = this.$date(this.date);
      d = d.hour(parseInt(this.hours));
      d = d.minute(parseInt(this.mins));
      d = d.second(parseInt(this.secs, 10));
      d = d.millisecond(this.millis);
      d = d.tz(this.tz, true); // `true` shifts the timezone only - without adjusting the hour
      return {
        date: d.toDate(),
        iso: d.toISOString(),
      };
    },
  },
  mounted() {
    this.set(this.originalDate);
  },
  methods: {
    set(date) {
      this.date = date;
      this.hours = this.hrOptions[date.getHours()];
      this.mins = this.minOptions[date.getMinutes()];
      this.secs = this.secOptions[date.getSeconds()];
      this.millis = date.getMilliseconds();
      const timeZone = this.$tz.now.find((tz) => tz.currentTimeOffsetInMinutes === -1 * date.getTimezoneOffset());
      const currentTimeZone = this.$tz.now.find((tz) => tz.name === this.tz);
      if (
        isDefined(timeZone) && (
          !isDefined(currentTimeZone) || // Don't have a timezone yet
          currentTimeZone.currentTimeOffsetInMinutes !== timeZone.currentTimeOffsetInMinutes // Different zone
        )
      ) {
        this.tz = timeZone.name;
        if (this.$refs.tz) this.$refs.tz.tz = timeZone.name;
      }
    },
    setPart(y, mnth, day, hr, min, sec, millis, tz) {
      if (isDefined(y)) this.date = this.$date(this.date).year(y).toDate();
      if (isDefined(mnth)) this.date = this.$date(this.date).month(mnth).toDate();
      if (isDefined(day)) this.date = this.$date(this.date).date(day).toDate();
      if (isDefined(hr)) this.date = this.$date(this.date).hour(hr).toDate();
      if (isDefined(min)) this.date = this.$date(this.date).minute(min).toDate();
      if (isDefined(sec)) this.date = this.$date(this.date).second(sec).toDate();
      if (isDefined(millis)) this.date = this.$date(this.date).millisecond(millis).toDate();
      if (isDefined(tz)) {
        this.date = this.$date(this.date).tz(tz).toDate();
        if (this.$refs.tz) this.$refs.tz.tz = tz;
      }
    },
    ok() {
      this.modalDisplayed = false;
    },
    cancel() {
      this.set(this.originalDate);
      this.modalDisplayed = false;
    },
  },
};
</script>
