<template>
  <div>
    <div class="p-5">
      <div class="d-flex">
        <base-button
          block
          type="simple"
          class="m-0 mr-2"
          @click="updateFilter"
        >
          <codepen-icon
            size="1.5x"
            class="mr-2"
          />
          Filter Cases ({{ filter }})
        </base-button>
        <base-button
          block
          class="m-0 ml-2"
          @click="$refs.addCase.showModal = true"
        >
          <user-plus-icon
            size="1.5x"
            class="mr-2"
          />
          New Case
        </base-button>
      </div>
    </div>
    <div class="h-divider" />
    <div class="d-flex px-5 py-2 flex-items-center">
      <h6 class="text-white mb-0">
        All Cases
      </h6>
      <div class="flex-1" />
      <b class="text-white">{{ pagination.pagination.TotalCount }} Cases</b>
    </div>
    <div class="px-5 dashboard-case--list">
      <case-card-item
        v-for="(item) in cases"
        :key="item.id"
        :class="isActiveCase(item.id) ? 'case-card--active' : 'border'"
        :case-item="item"
        @click="onCaseItemClick(item.id)"
      />
      <base-button
        v-if="canLoadMore"
        class="m-0 ml-2 mb-2 p-2 w-100"
        type="secondary"
        link
        @click="loadMore"
      >
        Load More
      </base-button>
    </div>
    <add-case-modal ref="addCase" />
  </div>
</template>

<script>
import BaseButton from "../../BaseButton.vue";
import {getCaseSummaries} from "../../../api";

import CaseCardItem from "./CaseCardItem.vue";
import {CodepenIcon, UserPlusIcon} from "vue-feather-icons";
import {mapGetters} from "vuex";
import {isDefined} from "../../../api/helpers";
import AddCaseModal from "../Case/AddCaseModal.vue";

const filterStates = ["my", "recent", "all"];

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  components: {
    BaseButton,
    CaseCardItem,
    CodepenIcon,
    BaseButton,
    UserPlusIcon,
    AddCaseModal,
  },
  data() {
    return {
      activeCaseId: null,
      pageSize: 10,
      allCases: [],
      allPagination: {
        TotalCount: 0,
      },
      allPageNumber: 1,

      myCases: [],
      myPagination: {
        TotalCount: 0,
      },
      myPageNumber: 1,

      recentCases: [],
      recentPagination: {
        TotalCount: 0,
      },
      recentPageNumber: 1,
      filter: "my",
    };
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    recentCutoff() {
      return this.$date().startOf("day").subtract(5, "days");
    },
    pagination() {
      switch (this.filter) {
        case "my": return {pagination: this.myPagination, pageSize: this.pageSize, pageNumber: this.myPageNumber};
        case "recent": return {pagination: this.recentPagination, pageSize: this.pageSize, pageNumber: this.recentPageNumber};
        default: return {pagination: this.allPagination, pageSize: this.pageSize, pageNumber: this.allPageNumber};
      }
    },
    canLoadMore() {
      return this.pagination.pagination.HasNext === true;
    },
    cases() {
      return this[`${this.filter}Cases`];
    },
  },
  mounted() {
    this.loadCases();
  },
  methods: {
    loadMore() {
      if (!this.canLoadMore) return;
      this[`${this.filter}PageNumber`] += 1;
      this.$nextTick(this.loadCases);
    },
    loadCases(keyword, attempt) {
      if (!this.pagination) {
        return;
      }
      if (this.filter == "my" && !isDefined(this.userId)) {
        attempt = isDefined(attempt) ? attempt : 1;
        if (attempt < 5) {
          setTimeout(() => this.loadCases(keyword, attempt + 1), 100);
        }
        return;
      }
      getCaseSummaries(this.pagination.pageNumber, this.pagination.pageSize, keyword, this.showArchived, this.filter === "recent", this.filter === "my" ? this.userId : undefined)
        .then((response) => {
          const casesArrayName = `${this.filter}Cases`;
          const newCases = this[casesArrayName].slice();
          response.data.forEach((c) => {
            const existingIndex = newCases.findIndex((nc) => nc.id === c.id);
            if (existingIndex > -1) {
              newCases[existingIndex] = c;
            } else {
              newCases.push(c);
            }
          });
          this[casesArrayName] = newCases;
          const p = JSON.parse(response.pagination);
          this[`${this.filter}Pagination`] = p;
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError(`Loading ${this.casesNameLabel} Data Failed`, ex);
        });
    },
    handlePaginationChange() {
      this.loadCases();
    },
    handleCaseView(id) {
      if (!isDefined(id)) return;
      this.$router.push(`/cases/${id}`);
    },
    isActiveCase(caseId) {
      return caseId == this.activeCaseId;
    },
    onCaseItemClick(i) {
      this.activeCaseId = i;
      this.$emit("input", i);
    },
    updateFilter() {
      this.filter = filterStates[(filterStates.indexOf(this.filter) + 1) % filterStates.length];
      const shouldRefresh = this[`${this.filter}Pagination`].TotalCount === 0;
      if (shouldRefresh) {
        this.loadCases();
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-case--list {
  height: calc(100vh - 244px);
  overflow-y: auto;
  .card {
    cursor: pointer;
  }
}
</style>
