<template>
  <modal-v-2
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    @close="onClose()"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <h6 class="text-white mb-0">
        New Case
      </h6>
    </div>
    <base-input
      v-model="form.title"
      label="Title"
    />
    <base-input
      v-model="form.name"
      label="Case Number"
    />
    <div class="text-right mt-8 mb-3">
      <base-button
        class="m-0"
        wide
        @click="createCase"
      >
        Create
      </base-button>
    </div>
  </modal-v-2>
</template>

<script>
import {mapGetters} from "vuex";
import {attributeUser, putCase} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import BaseButton from "../../BaseButton.vue";
import BaseInput from "../../Inputs/BaseInput.vue";
import ModalV2 from "../ModalV2.vue";
export default {
  components: {
    ModalV2,
    BaseButton,
    BaseInput,
  },
  props: {
    disableNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      form: {
        title: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    ...mapGetters("data", ["availableCaseRoles"]),
    roles() {
      return this.availableCaseRoles;
    },
    caseOfficerRole() {
      return this.roles.filter((r) => r.name?.toLowerCase() === "case officer").pop()?.value;
    },
  },
  methods: {
    createCase() {
      if (!isDefined(this.form.title) || this.form.title.length < 1) {
        this.$notifyWarn("Please enter title");
        return;
      }
      if (!isDefined(this.form.name) || this.form.name.length < 1) {
        this.$notifyWarn("Case Number is required");
        return;
      }
      this.loading = true;
      putCase(
        undefined,
        this.form.title,
        this.form.name
      ).then((response) => {
        this.$emit("updated", response);
        if (isDefined(this.userId) && isDefined(this.caseOfficerRole)) {
          attributeUser(this.userId, this.caseOfficerRole, undefined, response.id).catch((ex) => {}).finally(() => {
            this.finishPut(response.id);
          });
        } else {
          this.finishPut(response.id);
        }
      }).catch((ex) => {
        this.$notifyError("Creating Case Failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    finishPut(id) {
      this.onClose();
      if (this.disableNav) return;
      this.$router.push({name: ethosRouteNames.ViewCase, params: {caseId: id}});
    },
    onClose() {
      this.showModal = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
