import {jsonRequest, isDefined, POST, DELETE, GET, jsonRequestPaginated} from "./helpers";

export const attributesOf = async (
  type, typeId
) => {
  if (!isDefined(type)) throw new Error("Type Required");
  if (!isDefined(typeId)) throw new Error("TypeId Required");

  return await jsonRequest(GET, `Person/Attributes/${type}/${typeId}`);
};

export const pagedAttributesOf = async (
  type, typeId, pageNumber, pageSize, filter, caseId
) => {
  if (!isDefined(type)) throw new Error("Type Required");
  if (!isDefined(typeId)) throw new Error("TypeId Required");

  return await jsonRequestPaginated(
    GET,
    `Person/Attributes/${type}/${typeId}/Search`,
    {pageNumber, pageSize, filter},
    isDefined(caseId) ? `CaseId=${caseId}` : undefined
  );
};

export const attributeActor = async (
  actorId, group,
  caseId, evidenceId, incidentId,
  eCase, evidence
) => {
  if (!isDefined(actorId)) throw new Error("Id Required");
  if (!isDefined(group)) throw new Error("Group Required");
  const ids = buildIds(eCase, evidence);
  const data = {
    actorId,
    group,
    caseId: caseId || null,
    evidenceId: evidenceId || null,
    incidentId: incidentId || null,
    caseIds: ids.caseIds,
    evidenceIds: ids.evidenceIds,
    // TODO: - Incidents
  };

  return await jsonRequest(POST, "Person/Attribute", {body: JSON.stringify(data)});
};

export const unattributeActor = async (actorAttributeId) => {
  if (!isDefined(actorAttributeId)) throw new Error("Id Required");
  return await jsonRequest(DELETE, `Person/Attribute/Actor/${actorAttributeId}`);
};

export const attributeUser = async (
  userId, caseRole, group,
  caseId, evidenceId, incidentId,
  eCase, evidence
) => {
  if (!isDefined(userId)) throw new Error("Id Required");
  const hasCaseRole = isDefined(caseRole);
  if (isDefined(caseId) && !hasCaseRole) throw new Error("CaseRole Required");
  const ids = buildIds(eCase, evidence, null);
  const data = {
    userId,
    group: group || null,
    caseId: caseId || null,
    evidenceId: evidenceId || null,
    incidentId: incidentId || null,
    caseIds: hasCaseRole ? ids.caseIds : [],
    evidenceIds: ids.evidenceIds,
  };
  if (hasCaseRole) data.caseRole = caseRole;

  return await jsonRequest(POST, "Person/Attribute", {body: JSON.stringify(data)});
};

export const unattributeUser = async (userAttributeId) => {
  if (!isDefined(userAttributeId)) throw new Error("Id Required");
  return await jsonRequest(DELETE, `Person/Attribute/User/${userAttributeId}`);
};

const buildIds = (eCase, evidence) => {
  const caseIds = [];
  const evidenceIds = [];
  if (evidence) {
    evidenceIds.push(evidence.id);
    evidence.cases && evidence.cases.forEach((c) => caseIds.push(c.id));
  }
  if (eCase) {
    caseIds.push(eCase.id);
    eCase.evidence && eCase.evidence.forEach((e) => evidenceIds.push(e.id));
  }
  return {
    caseIds: caseIds.length > 0 ? caseIds : null,
    evidenceIds: evidenceIds.length > 0 ? evidenceIds : null,
  };
};
