import { render, staticRenderFns } from "./CollapseV2.vue?vue&type=template&id=d7085c14&scoped=true&"
import script from "./CollapseV2.vue?vue&type=script&lang=js&"
export * from "./CollapseV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7085c14",
  null
  
)

export default component.exports