<template>
  <base-select-table
    v-model="selected"
    class="people-table"
    :columns="columns"
    :data="tableData"
    disable-row-class
    @input="$emit('input', selected)"
  >
    <template v-slot:header-person>
      <base-select
        v-model="showing"
        style="width: 230px"
        :options="showingOptions"
        :table-select="true"
      />
    </template>

    <template v-slot:data-person="{ data }">
      <div class="d-flex align-items-center">
        <div
          v-if="isShowingUsers"
          class="photo ml-2"
        >
          <img
            v-if="data.item.userProfilePhotoUrl"
            src="img/mike.jpg"
          >
          <div
            v-else-if="data.item.initials && data.item.initials.length > 0"
            class="initials bg-secondary"
          >
            {{ data.item.initials }}
          </div>
        </div>
        <div class="d-flex flex-column flex-1 font-16 pl-2">
          <div>{{ data.item.fullName }}</div>
          <div
            v-if="data.item.group && data.item.group.length > 0"
            class="text-lightblue"
          >
            {{ data.item.group }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:data-caseRole="{ data }">
      {{ role(data.item.caseRole) }}
    </template>
    <template v-slot:data-options="{ data }">
      <div v-if="data.selected">
        <base-button
          link
          class="m-0"
          @click.stop="$emit('remove', {type: showing, id: data.item.id})"
        >
          <TrashIcon
            size="16"
            class="mr-1"
          />
          Remove
        </base-button>
        <base-button
          link
          class="m-0"
          @click.stop="$emit('share')"
        >
          <ShareIcon
            size="16"
            class="mr-1"
          />
          Share Case
        </base-button>
        <base-button
          link
          class="m-0 mr-10"
          @click.stop="$emit('edit')"
        >
          <EditIcon
            size="16"
            class="mr-1"
          />
          Edit Profile
        </base-button>
      </div>
    </template>
    <template v-slot:data-action="{}">
      <base-button link>
        View Profile
      </base-button>
    </template>
  </base-select-table>
</template>

<script>
import {TrashIcon, ShareIcon, EditIcon} from "vue-feather-icons";
import {mapGetters} from "vuex";
import BaseSelect from "../../Inputs/BaseSelect.vue";
import BaseSelectTable from "../BaseSelectTable.vue";

export default {
  name: "people-table",
  components: {
    BaseSelectTable,
    BaseSelect,
    TrashIcon,
    ShareIcon,
    EditIcon,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
      description: "User data",
    },
    actors: {
      type: Array,
      default: () => [],
      description: "Actor data",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showingOptions: [
        {name: "All Law Enforcement Officers", value: "users"},
        {name: "All Subjects", value: "actors"},
      ],
      showing: "users",
      selected: [],
    };
  },
  computed: {
    ...mapGetters("data", ["availableCaseRoles"]),
    roles() {
      return this.availableCaseRoles;
    },
    isShowingUsers() {
      return this.showing === "users";
    },
    tableData() {
      if (this.isShowingUsers) {
        return this.users.map((u) => {
          u.initials = this.initials(u);
          return u;
        });
      } else {
        return this.actors;
      }
    },
    columns() {
      return [
        {
          value: "person",
          label: "Person",
          class: "",
          tdClass: "bg-dark-100",
        },
        {
          value: "caseRole",
          label: this.isShowingUsers ? "Role" : "",
          class: "",
          tdClass: "bg-dark-100",
        },
        {
          value: "options",
          label: "",
          class: "",
          tdClass: "bg-dark-100",
          width: "420px",
        },
        {
          value: "action",
          width: "120px",
          label: "",
          class: "text-right",
          tdClass: "bg-dark-100 text-right",
        },
      ];
    },
  },
  methods: {
    initials(user) {
      let initials = "";
      const parts = user.fullName.split(" ");
      const last = parts.pop();
      const first = parts.pop();
      if (first && first.length > 0) initials += first[0];
      if (last && last.length > 0) initials += last[0];
      return initials.toUpperCase();
    },
    role(intVal) {
      if (!this.isShowingUsers) return "";
      return this.roles.filter((r) => r.value === intVal).pop()?.name ?? "";
    },
  },
};
</script>
<style>
  .table.people-table > tbody > tr > td {
    border-top-color: transparent;
  }
</style>
<style lang="scss" scope>

  .photo {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;
    img {
      width: 100%;
    }
    .initials {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 400;
    }
  }
</style>
<style lang="scss">
.people-table {
  tr {
    border-bottom: 8px solid transparent;
  }
  td:nth-child(2) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
