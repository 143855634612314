import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, POST, PUT, DELETE} from "./helpers";

import {v4 as uuidv4} from "uuid";

export const getCase = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/`);
};

export const getCaseSummary = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Summary`);
};

export const getCaseEvidence = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Evidence`);
};

export const getCaseEvidenceSummaries = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/EvidenceSummaries`);
};

export const postCaseEvidenceSummary = async (caseId, evidenceIds, name, dir) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(evidenceIds) || evidenceIds.length === 0) throw new Error("Evidence Id Required");
  const data = {
    evidenceIds,
  };
  if (isDefined(name) && name.length > 0) data.name = name;
  if (isDefined(dir)) data.dir = dir;
  return await jsonRequest(POST, `Cases/${caseId}/Evidence/Summary`, {
    body: JSON.stringify(data),
  });
};

export const deleteCaseEvidenceSummary = async (caseId, evidenceId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  return await jsonRequest(DELETE, `Cases/${caseId}/Evidence/Summary/${evidenceId}`);
};

export const getCaseClips = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Clips`);
};

export const getCases = async (pageNumber, pageSize, filter, archived, recent, userId) => {
  let query = archived === true ? "&Archived=true" : "";
  if (recent == true) query += "&Recent=true";
  if (isDefined(userId)) query += `&UserId=${userId}`;
  return await jsonRequestPaginated(
    GET,
    "Cases",
    {pageNumber, pageSize, filter},
    query
  );
};

export const getCaseSummaries = async (pageNumber, pageSize, filter, archived, recent, userId) => {
  let query = archived === true ? "&Archived=true" : "";
  if (recent == true) query += "&Recent=true";
  if (isDefined(userId)) query += `&UserId=${userId}`;
  return await jsonRequestPaginated(
    GET,
    "Cases/Summaries",
    {pageNumber, pageSize, filter},
    query
  );
};

export const getCaseProjects = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Projects`);
};

export const putCase = async (originalCaseNumber, title, caseNumber, description) => {
  if (!isDefined(title)) throw new Error("Title Required");
  const safeCaseNumber = caseNumber || uuidv4();
  const data = {
    title,
    name: safeCaseNumber,
    description,
  };

  return await jsonRequest(PUT, `Cases/${originalCaseNumber || caseNumber}`, {
    body: JSON.stringify(data),
  });
};

export const getAllCases = async () => {
  return await jsonRequest(GET, "Cases/GetAll");
};

export const archiveCase = async (caseId, archive) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(archive)) throw new Error("achive(true|false) required");
  const data = {
    archive,
  };
  return await doRequest(POST, `Cases/${caseId}/ArchiveCase`, {
    body: JSON.stringify(data),
  });
};

export const getArchiveCount = async () => {
  return await jsonRequest(GET, "Cases/Archived/Count");
};

export const addEvidenceIdsToCase = async (caseId, projectId, evidenceIds) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(evidenceIds) || evidenceIds.length < 1) throw new Error("evidenceIds required");
  const data = {
    evidenceIds,
  };
  if (isDefined(projectId)) data.projectId = projectId;
  return await doRequest(POST, `Cases/${caseId}/Link`, {
    body: JSON.stringify(data),
  });
};

