<template>
  <!-- What we want is to make the list of notes -->
  <tabs
    ref="tabslist"
    type="info"
    tab-nav-wrapper-classes="p-2"
    tab-content-classes="w-100 h-100 p-0"
    vertical
    class="d-flex h-100"
    font="Roboto"
    @tab-clicked="emitChange"
  >
    <tab-pane
      v-for="note in allNotes"
      :id="'' + note.id"
      :key="note.id"
      :label="note.title"
    />
    <tab-pane
      v-if="!allNotes || allNotes.length < 1"
      id="empty"
      label="No Notes"
    />
  </tabs>
</template>

<script>
import {TabPane, Tabs} from "src/components";
import {isDefined} from "../../../../api/helpers";
import {getAllNotes, getEvidenceNotes} from "../../../../api";
export default ({
  name: "note-list",
  components: {
    Tabs,
    TabPane,
  },
  props: {
    evidenceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      allNotes: null, // We want this to be the thing that returns all the notes (of project or evidence).
    };
  },
  watch: {
    noteId: {
      handler(n, o) {
        if (n !== o) {
          this.emitChange();
        }
      },
      immediate: true,
    },
  },
  computed: {
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    noteId() {
      const id = this.$route.params.noteId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  mounted() {
    if (this.evidenceId === null) {
      this.goGetAllNotes();
    } else {
      this.goGetEvidenceNotes();
    }
  },
  methods: {
    emitChange(ref) {
      let note = null;
      if (!this.allNotes || this.allNotes.length <= 0) return; // Makes sure that AllNotes is not null and that length is at least 1
      if (ref === undefined) { // ref is a special attribute, allows us to obtain a direct reference to a specific DOM element or child component instance after it's mounted
        if (isDefined(this.noteId)) {
          note = this.allNotes.find((note) => note.id === this.noteId);
          if (this.$refs.tabslist) this.$refs.tabslist.findAndActivateTab(note.title);
        } else {
          note = this.allNotes[0];
        }
      } else {
        // presuming Id from click
        note = this.allNotes.find((n) => "" + n.id === ref);
      }

      this.$emit("selectionchanged", note);
    },
    // TODO: - Change to by CaseId
    goGetAllNotes() {
      // Should there be an if-statement here to catch anything?
      const me = this;
      getAllNotes()
        .then((notes) => {
          me.allNotes = notes;
          me.$nextTick(() => {
            me.emitChange();
          });
        })
        .catch((ex) => {
          me.$notifyError("Loading Notes Failed", ex);
        });
    },
    goGetEvidenceNotes() {
      const me = this;
      getEvidenceNotes(
        this.evidenceId
      )
        .then((notes) => {
          me.allNotes = notes;
          me.$nextTick(() => {
            me.emitChange(me);
          });
        })
        .catch((ex) => {
          me.$notifyError("Loading Notes Failed (by Evidence)", ex);
        });
    },
    noteUpdated(note) {
      const index = this.allNotes.findIndex((n) => n.id === note.id);
      if (index > -1) Object.assign(this.allNotes[index], note);
    },
  },
});

</script>

