<template>
  <base-select-table
    v-model="selected"
    :columns="columns"
    :data="data"
    @input="$emit('input', selected)"
    @clicked="(e) => $emit('clicked', e)"
  >
    <template v-slot:header-fileName>
      Transcripts : {{ data.length }}
    </template>
    <template v-slot:header-evidence>
      Evidence
      <ArchiveIcon
        size="16"
        class="ml-1 text-lightblue"
      />
    </template>
    <template v-slot:header-exhibits>
      Exhibits
      <CopyIcon
        size="16"
        class="ml-1 text-lightblue"
      />
    </template>
    <template v-slot:header-signals>
      Signals
      <RepeatIcon
        size="16"
        class="ml-1 text-lightblue"
      />
    </template>
    <template v-slot:header-actions>
      <case-evidence-table-filter />
    </template>
    <template v-slot:data-actions="{ data }">
      <base-button
        icon
        link
        class="text-white"
        @click="openEvidenceList(data.item.name)"
      >
        <ChevronRightIcon aria-setsize="16" />
      </base-button>
    </template>
    <template v-slot:data-name="{ data }">
      <div class="font-weight-bold">
        {{ data.item.name }}
      </div>
      <div
        v-if="false"
        class="font-13 font-weight-bold"
      >
        Created By: {{ data.item.createdBy }}
      </div>
    </template>
    <template v-slot:data-createdOn="{ data }">
      <template v-if="data.selected">
        <base-button
          link
          class="m-0"
        >
          <ShareIcon
            size="16"
            class="mr-1"
          />
          Share Case
        </base-button>
      </template>
      <template v-else>
        {{ data.item.createdOn }}
      </template>
    </template>
  </base-select-table>
</template>
<script>
import {ArchiveIcon, ChevronRightIcon, RepeatIcon, ShareIcon, CopyIcon} from "vue-feather-icons";
import {ethosRouteNames} from "../../../routes/routeNames";
import BaseButton from "../../BaseButton.vue";
import BaseSelectTable from "../BaseSelectTable.vue";
import CaseEvidenceTableFilter from "./CaseEvidenceTableFilter.vue";

export default {
  name: "evidence-table",
  props: {
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      columns: [
        {
          value: "name",
          label: "Folder Name",
          class: "",
        },
        {
          value: "caseEvidenceCount",
          label: "Evidence",
          class: "text-center",
          tdClass: "text-center",
          width: "110px",
        },
        {
          value: "exhibits",
          label: "Exhibits",
          class: "text-center",
          tdClass: "text-center",
          width: "80px",
        },
        {
          value: "signals",
          label: "Signals",
          class: "text-center",
          width: "80px",
        },
        // {
        //   value: "createdOn",
        //   label: "Added on",
        //   class: "text-right",
        //   tdClass: "text-right",
        //   width: "250px",
        // },
        {
          value: "actions",
          label: "",
          class: "text-right",
          tdClass: "text-right",
          width: "55px",
        },
      ],
    };
  },
  components: {
    ArchiveIcon,
    RepeatIcon,
    ChevronRightIcon,
    BaseButton,
    ShareIcon,
    CaseEvidenceTableFilter,
    BaseSelectTable,
    CopyIcon,
  },
  methods: {
    openEvidenceList(name) {
      this.$router.replace({
        name: ethosRouteNames.CaseEvidenceList,
        params: {
          dirName: name,
        },
      });
    },
  },
};
</script>
<style></style>
