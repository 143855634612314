<template>
  <dashboard-v-2-layout>
    <dashboard-case-list
      slot="left"
      v-model="caseId"
    />
    <dashboard-case-details
      v-if="caseId != null"
      slot="right"
      :case-id="caseId"
    />
    <dashboard-empty
      v-else
      slot="right"
    />
  </dashboard-v-2-layout>
</template>

<script>
import DashboardCaseDetails from "../../components/DashboardV2/Dashboard/DashboardCaseDetails.vue";
import DashboardCaseList from "../../components/DashboardV2/Dashboard/DashboardCaseList.vue";
import DashboardEmpty from "../../components/DashboardV2/Dashboard/DashboardEmpty.vue";
import DashboardV2Layout from "../../components/DashboardV2/DashboardV2Layout.vue";

export default {
  components: {
    DashboardV2Layout,
    DashboardEmpty,
    DashboardCaseList,
    DashboardCaseDetails,
  },
  data() {
    return {
      caseId: null,
    };
  },
};
</script>

<style></style>
