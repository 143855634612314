<template>
  <dashboard-v-2-layout v-if="!loading">
    <case-menu
      slot="left"
      :case-summary="caseModel"
      @reload="reloadCaseSummary"
      @updatedCase="updatedCase"
    />
    <case-container
      slot="right"
      :case-summary="caseModel"
      @updatedCase="updatedCase"
    />
  </dashboard-v-2-layout>
</template>

<script>
import CaseContainer from "../../../components/DashboardV2/Case/CaseContainer.vue";
import CaseMenu from "../../../components/DashboardV2/Case/CaseMenu.vue";
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import {isDefined} from "../../../api/helpers";
import {mapGetters, mapMutations} from "vuex";
import {getCaseSummary} from "../../../api";

export default {
  components: {DashboardV2Layout, CaseMenu, CaseContainer},
  data() {
    return {
      loading: true,
      updatedCaseId: null,
    };
  },
  watch: {
    caseId: function(newVal) {
      this.loadCase();
    },
  },
  computed: {
    ...mapGetters("data", ["getCaseSummaries"]),
    caseId() {
      const id = isDefined(this.updatedCaseId) ? this.updatedCaseId : this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseModel() {
      if (!isDefined(this.caseId)) return null;
      return this.getCaseSummaries[this.caseId];
    },
  },
  mounted() {
    this.loadCase();
  },
  methods: {
    ...mapMutations("data", ["putCaseSummary"]),
    updatedCase(id) {
      this.updatedCaseId = id;
    },
    loadCase() {
      if (!isDefined(this.caseId)) {
        return;
      }
      if (isDefined(this.caseModel)) {
        this.loading = false;
        return;
      };
      this.reloadCaseSummary();
    },
    reloadCaseSummary() {
      this.loading = true;
      getCaseSummary(this.caseId).then((response) => {
        this.putCaseSummary(response);
      }).catch((ex) => {
        this.$notifyError("Loading Case Data Failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
