<template>
  <div
    class="ethos-container"
    :class="{
      bordered: (!hasChildRoute || reuse) && !noBorder,
      'm-3': !hasChildRoute,
    }"
  >
    <router-view v-if="!reuse" />
    <add-new-evidence
      ref="evidenceForm"
      :case-id="caseId"
      :project-id="projectId"
      header="Add !!!"
    />
    <div
      v-if="!hasChildRoute || reuse"
      class="paginated-table"
    >
      <div
        v-if="!reuse"
        class="my-3 d-flex align-items-center"
      >
        <h2 class="mr-3 d-inline-block mb-0">
          {{ evidenceNameLabel }}
        </h2>
        <base-button
          size="sm"
          type="primary"
          link
          class="m-0"
          @click="showEvidence"
        >
          Add New {{ evidenceNameLabel }}
          <plus-icon
            size="1.5x"
            class="ml-3"
          />
        </base-button>
      </div>
      <el-table
        :class="{
          'mt-4': reuse,
        }"
        :data="tableData"
        :style="!noBorder ? 'width: 100%' : ''"
        @row-click="(row) => handleEvidenceView(row)"
      >
        <el-table-column
          prop="name"
          label="Name"
          min-width="200"
          sortable
        >
          <div
            slot-scope="{ row }"
          >
            <div
              v-if="['In Progress'].includes(row.__status)"
            >
              Processing
              <base-progress
                :type="'primary'"
                class="w-100"
                :value="row.__progress"
                value-position="none"
              />
            </div>
            <div
              v-else
            >
              {{ row.name }}
            </div>
          </div>
        </el-table-column>
        <el-table-column
          prop="originalFileName"
          label="Original File Name"
          min-width="300"
          sortable
        />
        <el-table-column
          label="Created On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          label="Created By"
          min-width="200"
          sortable
        />
        <el-table-column
          prop="modifiedOn"
          label="Modified On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modifiedBy"
          label="Modified By"
          min-width="200"
          sortable
        />
        <el-table-column
          align="center"
          min-width="200"
        >
          <template slot-scope="scope">
            <base-button
              size="sm"
              type="primary"
              simple
              @click="handleEvidenceView(scope.row)"
            >
              View {{ evidenceNameLabel }}
            </base-button>
          </template>
        </el-table-column>
      </el-table>
      <table-pagination
        v-if="caseId === null"
        ref="pagination"
        :pagination="pagination"
        @changed="handlePaginationChange"
      />
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getCaseEvidence, getEvidence} from "../../../api";
import {hasChildRoute} from "src/util/util";
import {PlusIcon} from "vue-feather-icons";
import "src/assets/sass/custom/paginated-table.scss";
import {isDefined} from "../../../api/helpers";
import TablePagination from "../../../components/TablePagination.vue";
import AddNewEvidence from "./AddNewEvidence.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: "list-evidence",
  components: {
    PlusIcon,
    TablePagination,
    AddNewEvidence,
  },
  props: {
    reuse: {
      type: Boolean,
      value: false,
    },
    noBorder: {
      type: Boolean,
      value: false,
    },
    caseId: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {
        TotalCount: 0,
      },
      hasChildRoute: false,
    };
  },
  watch: {
    "$store.state.data.searchKeyword": function() {
      this.loadEvidence(this.$store.state.data.searchKeyword);
    },
    evidenceListRefreshToken(to, from) {
      this.loadEvidence(this.getSearchKeyword);
    },
    getWorkflowResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) {
        this.setWorkflowResponses();
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getSearchKeyword",
      "evidenceNameLabel",
      "userTimezone",
      "getWorkflowResponse",
      "evidenceListRefreshToken",
    ]),
  },
  beforeRouteUpdate(to, from, next) {
    this.hasChildRoute = hasChildRoute(to.path, "evidence");
    next();
  },
  mounted() {
    this.hasChildRoute = hasChildRoute(
      this.$router.currentRoute.path,
      "evidence"
    );
    this.loadEvidence(this.getSearchKeyword);
  },
  unmounted() {
    this.tableData.forEach((row) => {
      if (row.__interval) {
        clearInterval(row.__interval);
      }
    });
  },
  methods: {
    ...mapMutations("data", [
      "popWorkflowResponse",
      "setWorkflowResponse",
    ]),
    ...mapActions(["pollWorkflow"]),
    showEvidence() {
      this.$refs.evidenceForm.display();
    },
    loadEvidence(keyword) {
      if (this.caseId !== null) {
        getCaseEvidence(this.caseId)
          .then((response) => {
            this.tableData = response;
            this.loading = false;
            this.setupWorkflowPolls(this.tableData);
          })
          .catch((ex) => {
            this.$notifyError(`Loading ${this.evidenceNameLabel} Data Failed`, ex);
          });
      } else {
        if (!this.$refs.pagination) {
          return;
        }
        getEvidence(this.$refs.pagination.pageNumber, this.$refs.pagination.pageSize, keyword)
          .then((response) => {
            this.tableData = response.data;
            this.pagination = JSON.parse(response.pagination);
            this.loading = false;
            this.setupWorkflowPolls(this.tableData);
          })
          .catch((ex) => {
            this.loading = false;
            this.$notifyError(`Loading ${this.evidenceNameLabel} Data Failed`, ex);
          });
      }
    },
    setupWorkflowPolls(tableData) {
      this.setWorkflowResponses();
      tableData.forEach((row) => {
        let transcriptWorkflow = row.workflows.filter((w) => w.workflowId == "CreateTranscription");
        if (transcriptWorkflow.length > 0) {
          transcriptWorkflow = transcriptWorkflow[0];
          if (!isDefined(row.__workflowId)) {
            // create workflow payload to reattach to row for polling
            const workflowPayload = {
              id: uuidv4(),
              evidenceId: row.id,
              evidenceName: row.name,
              name: transcriptWorkflow.workflowId,
              status: "In Progress",
              progress: 0,
              workflowId: transcriptWorkflow.workflowPersistenceId,
            };
            this.setWorkflowResponse(workflowPayload);
          }
        }
      });
    },
    setWorkflowPoll(row, workflowPayload) {
      if (
        !isDefined(row.__workflowId) &&
        workflowPayload.workflowId !== null) {
        this.$set(row, "__workflowId", workflowPayload.workflowId);
        const poll = this.pollWorkflow;
        const pop = this.popWorkflowResponse;
        const interv = window.setInterval(() => {
          poll(workflowPayload)
            .then((finished) => {
              this.uploading = !finished;
              if (finished) {
                window.clearInterval(interv);
                window.setTimeout(() => {
                  pop(workflowPayload.id);
                }, 3000);
              }
            })
            .catch((ex) => {});
        }, 10 * 1000);
        this.$set(row, "__interval", interv);
      }
    },
    handlePaginationChange() {
      this.loadEvidence();
    },
    handleEvidenceView(row) {
      if (!isDefined(row?.id)) return;
      if (row?.__status && row.__status !== "Complete") {
        this.$notifyError("Evidence is not ready yet");
        return;
      }
      this.$router.push(`/evidence/${row.id}`);
    },
    setWorkflowResponses() {
      if (
        Array.isArray(this.getWorkflowResponse) &&
        this.getWorkflowResponse.length > 0
      ) {
        this.getWorkflowResponse.forEach((item) => {
          if (!["Create Transcription", "CreateTranscription"].includes(item.name)) return;
          const existsAtIndex = this.tableData.findIndex(
            (r) => r.id === item.evidenceId
          );
          if (existsAtIndex !== -1) {
            this.setWorkflowPoll(this.tableData[existsAtIndex], item);
            this.$set(this.tableData[existsAtIndex], "__status", item.status);
            this.$set(this.tableData[existsAtIndex], "__progress", item.progress);
          }
        });
      }
    },
  },
};
</script>
