<template>
  <div class="wrapper">
    <notifications />
    <upload-manager-overlay
      ref="uploadoverlay"
    />
    <div class="main-panel ethos-panel">
      <ethos-header />
      <div
        v-if="authEstablished"
        class="content"
      >
        <zoom-center-transition
          :duration="200"
          mode="out-in"
        >
          <!-- your content here -->
          <template v-if="$route.path === '/'">
            <dashboard />
          </template>
          <template v-else>
            <router-view />
          </template>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import UploadManagerOverlay from "./UploadManagerOverlay.vue";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import EthosHeader from "../Header/Header.vue";
import Dashboard from "./Dashboard.vue";
import {ZoomCenterTransition} from "vue2-transitions";
import {mapGetters} from "vuex";

export default {
  components: {
    EthosHeader,
    Dashboard,
    ZoomCenterTransition,
    UploadManagerOverlay,
  },
  data() {
    return {
      displayEvidenceUpload: false,
    };
  },
  watch: {
    getUploadResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) this.$refs.uploadoverlay.display();
    },
  },
  computed: {
    ...mapGetters("auth", [
      "established",
    ]),
    ...mapGetters("data", [
      "getUploadResponse",
    ]),
    authEstablished() {
      return this.$auth.isAuthenticated && this.established;
    },
  },
  async mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      const docClasses = document.body.classList;
      const isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
