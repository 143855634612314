import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, POST, DELETE, PUT} from "./helpers";

export const getProjects = async (pageNumber, pageSize, filter) => {
  return await jsonRequestPaginated(
    GET,
    "Projects",
    {pageNumber, pageSize, filter}
  );
};

export const addProject = async (caseId, evidenceId, title, description) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(title)) throw new Error("Title Id Required");
  const data = {
    caseId,
    evidenceId,
    title,
    description,
  };
  return await jsonRequest(POST, "Projects", {
    body: JSON.stringify(data),
  });
};

export const deleteProject = async (projectId) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  return await doRequest(DELETE, `/Projects/${projectId}`);
};

export const getProjectPeopleByGroup = async (projectId, groupName) => {
  if (!isDefined(projectId)) throw new Error("ProjectId Required");
  if (!isDefined(groupName)) throw new Error("Group Name Required");
  return await jsonRequest(GET, `Projects/${projectId}/People?groupName=${groupName}`);
};

export const postProjectPreferences = async (projectId, starred) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");

  // In future this can be optional; Presumably we'll add colors / tags etc. at some point
  if (!isDefined(starred)) throw new Error("starred(true|false) required");
  const data = {
    starred,
  };
  return await jsonRequest(POST, `Projects/${projectId}/Preferences`, {
    body: JSON.stringify(data),
  });
};

export const getAllStarredProjects = async () => {
  return await jsonRequest(GET, "Projects/Starred");
};

export const getRecentProjects = async () => {
  return await jsonRequest(GET, "Projects/Recent?count=3");
};

export const getProjectIncidents = async (projectId) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  return await jsonRequest(GET, `Projects/${projectId}/Incidents`);
};

export const getProjectTimelines = async (projectId) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  return await jsonRequest(GET, `Projects/${projectId}/Timeline`);
};

export const getProjectTimeline = async (projectId, timelineId) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  if (!isDefined(timelineId)) throw new Error("Timeline Id Required");
  return await jsonRequest(GET, `Projects/${projectId}/Timeline/${timelineId}`);
};

export const postProjectTimline = async (projectId, timelineData) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  if (!isDefined(timelineData)) throw new Error("Data Required");

  // TODO: - Validate the data here?

  return await jsonRequest(POST, `Projects/${projectId}/Timeline`, {
    body: JSON.stringify(timelineData),
  });
};

export const putProjectTimline = async (projectId, timelineId, timelineData) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  if (!isDefined(timelineId)) throw new Error("Timeline Id Required");
  if (!isDefined(timelineData)) throw new Error("Data Required");

  // TODO: - Validate the data here?

  return await jsonRequest(PUT, `Projects/${projectId}/Timeline/${timelineId}`, {
    body: JSON.stringify(timelineData),
  });
};

export const deleteProjectTimline = async (projectId, timelineId, timelineData) => {
  if (!isDefined(projectId)) throw new Error("Project Id Required");
  if (!isDefined(timelineId)) throw new Error("Timeline Id Required");

  return await jsonRequest(DELETE, `Projects/${projectId}/Timeline/${timelineId}`);
};
