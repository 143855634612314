var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table tablesorter mb-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"55px"}},[_c('base-checkbox',{attrs:{"checked":_vm.isSelectAll},on:{"input":_vm.onSelectAll}})],1),_vm._l((_vm.columns),function(col,i){return _c('th',{key:i,staticClass:"text-capitalize font-weight-bold text-white",class:col.class || '',attrs:{"width":col.width}},[_vm._t(("header-" + (col.value)),function(){return [_vm._v(" "+_vm._s(col.label)+" ")]})],2)})],2)]),_c('tbody',[_vm._l((_vm.data),function(item,index){return [_c('tr',{key:index,staticClass:"cursor-pointer",class:_vm.rowClass(index),on:{"click":function () { return _vm.$emit('clicked', {item: item, index: index}); }}},[_c('td',{staticClass:"text-center"},[_c('base-checkbox',{attrs:{"checked":_vm.isSelected(index)},on:{"input":function($event){return _vm.onCheckboxChange($event, index)}}})],1),_vm._l((_vm.columns),function(col,i){return _c('td',{key:i,staticClass:"font-weight-bold",class:col.tdClass},[_vm._t(("data-" + (col.value)),function(){return [_vm._v(" "+_vm._s(item[col.value])+" ")]},{"data":{
              item: item,
              value: item[col.value],
              index: index,
              selected: _vm.isSelected(index),
            }})],2)})],2),_vm._t("nested-data",null,{"data":{
          item: item,
          index: index,
        }})]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }