<template>
  <div class="d-flex h-100 ethos-container">
    <collapse-container
      classes="d-flex flex-row-reverse"
      collapse-button-classes="position-relative"
      collapse-button-icon-classes="position-absolute top-0 w-100"
    >
      <tabs
        v-model="activeTab"
        type="info"
        tab-nav-wrapper-classes="p-2"
        tab-content-classes="w-100 h-100 p-0"
        vertical
        class="d-flex h-100"
        font="Roboto"
        @tab-clicked="handleClick"
      >
        <!-- Use custom handler to avoid tab selection - just want to handle the form display -->
        <tab-pane
          id="addPeople"
          :index="0"
          :custom-handler="(t) => handleClick(t.id)"
        >
          <span slot="label">
            <plus-icon size="1.5x" />
            Add {{ peopleNameLabel }}
          </span>
        </tab-pane>
        <tab-pane
          id="peopleFolders"
          :index="1"
        >
          <span slot="label">
            <folder-icon size="1.5x" />
            Folders
          </span>
        </tab-pane>
      </tabs>
    </collapse-container>
    <div class="content w-100">
      <template v-if="activeComponent === 'folders'">
        <people-folders />
      </template>
    </div>

    <people-form
      ref="peopleForm"
      header="Select !!!"
      :case-id="caseId"
      :evidence-id="evidenceId"
      :project-id="projectId"
      @created="createdPeople"
      @updated="updatedPeople"
    />
  </div>
</template>

<script>
import {TabPane, Tabs} from "src/components";
import {mapGetters, mapMutations} from "vuex";
import {isDefined} from "../../../api/helpers";
import PeopleFolders from "./Folders/Folders.vue";
import PeopleForm from "./PeopleForm/PeopleForm.vue";
import {PlusIcon, FolderIcon} from "vue-feather-icons";

export default {
  name: "view-people",
  components: {
    Tabs,
    TabPane,
    PeopleFolders,
    PeopleForm,
    PlusIcon,
    FolderIcon,
  },
  data() {
    return {
      activeComponent: "folders",
      activeTab: "Folders",
    };
  },
  computed: {
    ...mapGetters("data", ["peopleNameLabel"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    evidenceId() {
      const id = this.$route.params.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    projectId() {
      const id = this.$route.params.projectId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations("data", ["putPeople"]),
    displayPeopleForm() {
      this.$refs.peopleForm.reset();
      this.$refs.peopleForm.display();
    },
    createdPeople(people) {
      this.putPeople([people]);
    },
    updatedPeople(peopleList) {
      // List of people updated
      this.putPeople(peopleList);
    },
    handleClick(id) {
      switch (id) {
        case "addPeople":
          this.displayPeopleForm();
          break;
        case "peopleFolders":
          this.activeComponent = "folders";
          break;
        default: break;
      }
    },
  },
};
</script>
