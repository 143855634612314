import {jsonRequest, jsonRequestPaginated, isDefined, GET, POST} from "./helpers";

export const getTranscriptions = async (pageNumber, pageSize, filter) => {
  return await jsonRequestPaginated(GET, "Transcription?withCases=true", {pageNumber, pageSize, filter});
};
export const getTranscriptionHeadlines = async (pageNumber, pageSize, filter, caseId) => {
  return await jsonRequestPaginated(GET, "Transcription/Headlines", {pageNumber, pageSize, filter}, isDefined(caseId) ? `CaseId=${caseId}` : undefined);
};

export const getTranscription = async (transcriptionId) => {
  if (!isDefined(transcriptionId)) throw new Error("Workflow Id Required");
  return await jsonRequest(GET, `Transcription/${transcriptionId}?withCases=true`);
};

export const getAudioTranscript = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  const json = await jsonRequest(GET, `Transcription/${evidenceId}/AudioTranscript`);
  if (isDefined(json) && isDefined(json.transcript) && isDefined(json.signedUrl)) {
    return {
      ...json.transcript,
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Transcription");
};

export const getUrl = async (evidenceId, original) => {
  if (!isDefined(evidenceId)) throw new Error("Evidence Id Required");
  let url = `Transcription/${evidenceId}/Url`;
  if (original === true) url += "?original=true";
  const json = await jsonRequest(GET, url);
  if (isDefined(json) && isDefined(json.signedUrl)) {
    return {
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Url");
};
// export const updateTranscriptionContent = async (transcriptionId, content) => {
//   if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
//   if (!isDefined(content)) throw new Error("Content Required");
//   const data = {
//     content,
//   };
//   // TODO: - Convert to PATCH && return the updated Transcription && change to jsonRequest
//   return await doRequest(POST, `Transcription/${transcriptionId}/UpdateContent`, {
//     body: JSON.stringify(data),
//   });
// };

export const postChangeRequests = async (transcriptionId, changes) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  changes.forEach((c) => {
    if (!isDefined(c.startIndex)) throw new Error("Start Index Required");
    if (!isDefined(c.endIndex)) throw new Error("End Index Required");
    if (!isDefined(c.newContent)) throw new Error("Content Required");
    if (!isDefined(c.oldContent)) throw new Error("Previous Content Required");
  });
  return await jsonRequest(POST, `Transcription/${transcriptionId}/RequestChanges`, {
    body: JSON.stringify({changeRequests: changes}),
  });
};

export const playBackPosition = async (transcriptionId, email, changes) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  changes =JSON.stringify(changes);
  return await jsonRequest(POST, `Transcription/${transcriptionId}/Playback`, {
    body: JSON.stringify({Email: email, Position: changes}),
  });
};

export const postMarkClear = async (transcriptionId, startIndex, endIndex, oldContent) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  if (!isDefined(startIndex)) throw new Error("Start Index Required");
  if (!isDefined(endIndex)) throw new Error("End Index Required");
  if (!isDefined(oldContent)) throw new Error("Previous Content Required");
  const data = {
    startIndex,
    endIndex,
    newContent: oldContent,
    oldContent,
    markClear: true,
  };
  return await jsonRequest(POST, `Transcription/${transcriptionId}/RequestChanges`, {
    body: JSON.stringify({changeRequests: [data]}),
  });
};

export const postRemoveRedaction = async (transcriptionId, startIndex, endIndex, oldContent) => {
  return await postRedact(transcriptionId, startIndex, endIndex, oldContent, false);
};

export const postRedaction = async (transcriptionId, startIndex, endIndex, oldContent) => {
  return await postRedact(transcriptionId, startIndex, endIndex, oldContent, true);
};

const postRedact = async (transcriptionId, startIndex, endIndex, oldContent, redact) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  if (!isDefined(startIndex)) throw new Error("Start Index Required");
  if (!isDefined(endIndex)) throw new Error("End Index Required");
  if (!isDefined(oldContent)) throw new Error("Previous Content Required");
  const data = {
    startIndex,
    endIndex,
    newContent: oldContent,
    oldContent,
    redact,
  };
  return await jsonRequest(POST, `Transcription/${transcriptionId}/RequestChanges`, {
    body: JSON.stringify({changeRequests: [data]}),
  });
};

export const postSpeakerChangeRequest = async (
  transcriptionId,
  startIndex,
  endIndex,
  newSpeakerId,
  isRemove,
  isAdd,
  shouldMapMatching,
  newSpeakerUserId,
  newSpeakerActorId
) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  if (!isDefined(startIndex)) throw new Error("Start Index Required");
  const data = {
    startIndex,
    endIndex,
    newSpeakerId,
  };
  if (isDefined(isRemove)) {
    data.isRemove = isRemove;
  }
  if (isDefined(isAdd)) {
    data.isAdd = isAdd;
  }
  if (isDefined(shouldMapMatching)) {
    data.shouldMapMatching = shouldMapMatching;
  }
  if (isDefined(newSpeakerUserId)) {
    data.newSpeakerUserId = newSpeakerUserId;
  }
  if (isDefined(newSpeakerActorId)) {
    data.newSpeakerActorId = newSpeakerActorId;
  }
  return await jsonRequest(POST, `Transcription/${transcriptionId}/RequestChanges`, {
    body: JSON.stringify({speakerChangeRequests: [data]}),
  });
};

export const postChangeRequestApproval = async (transcriptionId, changeId, isApproved, reason) => {
  if (!isDefined(transcriptionId)) throw new Error("Transcription Id Required");
  if (!isDefined(changeId)) throw new Error("ChangeId Required");
  if (!isDefined(isApproved)) throw new Error("Approval flag Required");
  const data = {
    isApproved,
    reason,
  };
  return await jsonRequest(POST, `Transcription/${transcriptionId}/Approval/${changeId}`, {
    body: JSON.stringify(data),
  });
};
