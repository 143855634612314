<template>
  <base-select-table
    v-model="selected"
    :columns="columns"
    :data="data"
    @input="$emit('input', selected)"
    @clicked="(e) => $emit('clicked', e)"
  >
    <template v-slot:header-name>
      Transcripts : {{ data.length }} <slot name="tableheader" />
    </template>
    <template v-slot:header-exhibits>
      Exhibits
      <CopyIcon
        size="16"
        class="ml-1 text-lightblue"
      />
    </template>
    <template v-slot:header-signals>
      Signals
      <RepeatIcon
        size="16"
        class="ml-1 text-lightblue"
      />
    </template>
    <template v-slot:data-actions="{ data }">
      <base-button
        icon
        link
        class="text-white"
        @click="openEvidence(data.item.data)"
      >
        <ChevronRightIcon aria-setsize="16" />
      </base-button>
    </template>
    <template v-slot:nested-data="{ data }">
      <template v-if="data.item.showNested">
        <template v-if="data.item.nestedDataLoading">
          <tr :key="'nested-' + data.index">
            <td />
            <td
              :colspan="columns.length"
              class="text-center"
            >
              Loading
            </td>
          </tr>
        </template>
        <template v-else-if="data.item.nestedData.length === 0">
          <tr :key="'nested-' + data.index">
            <td />
            <td
              :colspan="columns.length"
              class="text-center"
            >
              No Exhibits
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="(nestedItem, ni) in data.item.nestedData"
            :key="'nested-' + data.index + '-' + ni"
          >
            <td />
            <td :colspan="columns.length - 2">
              <span @click="openClip(nestedItem)" class="text-underline">{{ nestedItem.title }}</span>
            <span v-if="nestedItem.description"> ({{nestedItem.description}})</span>
            </td>
            <td class="text-right">
              {{ toSeconds(nestedItem.endOffset - nestedItem.startOffset) }}
            </td>
            <td>
              <base-button
                icon
                link
                class="text-white"
                @click="openClip(nestedItem)"
              >
                <ChevronRightIcon aria-setsize="16" />
              </base-button>
            </td>
          </tr>
        </template>
      </template>
    </template>
  </base-select-table>
</template>

<script>
import {RepeatIcon, CopyIcon, ChevronRightIcon} from "vue-feather-icons";
import {isDefined} from "../../../api/helpers";
import BaseSelectTable from "../../../components/DashboardV2/BaseSelectTable.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
export default {
  name: "evidence-list-table",
  components: {
    BaseSelectTable,
    RepeatIcon,
    CopyIcon,
    ChevronRightIcon,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      columns: [
        {
          value: "name",
          label: "File Name",
          class: "",
        },
        {
          value: "exhibits",
          label: "Exhibits",
          class: "text-center",
          width: "80px",
        },
        {
          value: "signals",
          label: "Signals",
          class: "text-center",
          width: "80px",
        },
        {
          value: "createdOn",
          label: "Added on",
          class: "text-right",
          width: "250px",
        },
        {
          value: "actions",
          label: "",
          class: "text-right",
          width: "55px",
        },
      ],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          this.selected = to.slice();
        });
      },
    },
  },
  methods: {
    openEvidence(cse) {
      this.$router.replace({
        name: ethosRouteNames.CaseViewEvidence,
        params: {
          evidenceId: cse.evidenceId,
        },
      });
    },
    openClip(clip) {
      if (!isDefined(clip)) return;
      this.$router.replace({
        name: ethosRouteNames.ClipsV2,
        params: {
          evidenceId: clip.evidenceId,
          clipId: clip.id,
        },
      });
    },
    toSeconds(millis) {
      return (millis / 1000).toFixed(2) + "s";
    },
  },
};
</script>
<style></style>
