<template>
  <div>
    <case-back-div
      to="CaseEvidence"
      title="Back to All Evidence"
    />
    <div class="d-flex p-5 items-center align-items-center">
      <div>
        <h6 class="text-white mb-0">
          {{ dirNameDisplay }}
        </h6>
        <p
          v-if="false"
          class="mb-0 text-lightblue"
        >
          Created by Jim Smith on Mar 00, 0000
        </p>
      </div>
      <div class="flex-grow-1" />
      <base-button
        type="simple"
        class="m-0"
      >
        <PlusIcon class="mr-3" />
        Transcript
      </base-button>
    </div>
    <div class="px-5">
      <case-evidence-list-table
        v-model="selected"
        :data="tableData"
        @clicked="handleRowClicked"
      >
        <case-evidence-actions
          v-if="selected.length > 0"
          slot="tableheader"
          class="d-inline pl-4"
          @move="() => $refs.dirModal.showModal = true"
          @delete="deleteCaseEvidence"
        />
      </case-evidence-list-table>
      <case-evidence-dir-modal
        ref="dirModal"
        :case-id="caseId"
        :evidence-ids="selectedEvidenceIds"
        :dirs="dirs"
        @close="reset"
      />
    </div>
  </div>
</template>

<script>
import {PlusIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";
import {deleteCaseEvidenceSummary, getCaseClips, getCaseEvidenceSummaries} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseButton from "../../../components/BaseButton.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import CaseEvidenceActions from "../../../components/DashboardV2/Case/CaseEvidenceActions.vue";
import CaseEvidenceDirModal from "../../../components/DashboardV2/Case/CaseEvidenceDirModal.vue";
import CaseEvidenceListTable from "../../../components/DashboardV2/Case/CaseEvidenceListTable.vue";
import {DEFAULT_EVIDENCE_DIRNAME} from "../../../util/consts";

export default {
  components: {
    CaseBackDiv,
    BaseButton,
    PlusIcon,
    CaseEvidenceListTable,
    CaseEvidenceActions,
    CaseEvidenceDirModal,
  },
  data() {
    return {
      loading: false,
      selected: [],
      expandedEvidenceIds: [],
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseEvidenceSummaries", "getCaseClipSummaries"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseEvidenceSpecificSummaries() {
      if (!isDefined(this.getCaseEvidenceSummaries) || !isDefined(this.caseId)) return [];
      return (this.getCaseEvidenceSummaries[this.caseId] || []);
    },
    dirName() {
      const dir = this.$route.params.dirName;
      return isDefined(dir) ? dir : "";
    },
    dirNameDisplay() {
      return this.dirName === "" ? DEFAULT_EVIDENCE_DIRNAME : this.dirName;
    },
    dirs() {
      return this.caseEvidenceSpecificSummaries.reduce((a, ces) => {
        if (a.indexOf(ces.dir) === -1) a.push(ces.dir);
        return a;
      }, []);
    },
    caseEvidenceSummaries() {
      return this.caseEvidenceSpecificSummaries.filter((ces) => ces.dir === this.dirName || (this.dirName === DEFAULT_EVIDENCE_DIRNAME && ces.dir === ""));
    },
    selectedEvidence() {
      const tdata = this.tableData;
      return this.selected.map((i) => tdata[i].data);
    },
    selectedEvidenceIds() {
      return this.selectedEvidence.map((ces) => ces.evidenceId);
    },
    tableData() {
      const nestedDataLoading = !this.caseClipSummariesLoaded;
      return this.caseEvidenceSummaries.map((cse) => {
        const showNested = this.expandedEvidenceIds.indexOf(cse.evidenceId) !== -1;
        const nestedData = this.caseClipSummaries.filter((c) => c.evidenceId === cse.evidenceId);
        return {
          name: cse.name,
          data: cse,
          signals: cse.incidentCount,
          exhibits: cse.clipCount,
          createdOn: cse.evidenceMetadata.createdOn,
          showNested,
          nestedData,
          nestedDataLoading,
        };
      });
    },
    caseClipSummariesLoaded() {
      const summaries = this.getCaseClipSummaries;
      return isDefined(summaries) &&
        isDefined(this.caseId) &&
        isDefined(summaries[this.caseId]);
    },
    caseClipSummaries() {
      return this.caseClipSummariesLoaded ? this.getCaseClipSummaries[this.caseId] : [];
    },
  },
  mounted() {
    if (this.caseEvidenceSummaries.length === 0) {
      this.loadCaseEvidence();
    }
  },
  methods: {
    ...mapMutations("data", ["putCaseEvidenceSummaries", "putCaseClipSummaries"]),
    loadCaseEvidence() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseEvidenceSummaries(this.caseId).then((response) => {
        this.putCaseEvidenceSummaries({
          caseId: this.caseId,
          summaries: response,
        });
      }).catch((ex) => {
        this.$notifyError("Failed to gather Evidence for Case", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    reset() {
      this.selected = [];
      this.loadCaseEvidence();
    },
    deleteCaseEvidence() {
      const evidenceIds = this.selectedEvidenceIds.slice();
      const requests = evidenceIds.map((eid) => deleteCaseEvidenceSummary(this.caseId, eid));
      Promise.allSettled(requests).then((r) => {
        this.$notifySuccess("Removed Evidence from Case");
      }).catch((ex) => {
        this.$notifyError("Unable to delete an Evidence from the Case", ex);
      }).finally(() => {
        this.reset();
      });
    },
    loadClips() {
      getCaseClips(this.caseId).then((response) => {
        this.putCaseClipSummaries({caseId: this.caseId, summaries: response});
      }).catch((ex) => {
        this.$notifyError("Unable to load Exhibits", ex);
      });
    },
    handleRowClicked(e) {
      console.log(e);
      const item = e.item;
      const index = e.index;
      if (!item || !isDefined(index)) return;
      if (!this.caseClipSummariesLoaded) {
        this.loadClips();
      }
      if (this.expandedEvidenceIds.indexOf(item.data.evidenceId) === -1) {
        this.expandedEvidenceIds = [...this.expandedEvidenceIds, item.data.evidenceId];
      } else {
        this.expandedEvidenceIds = this.expandedEvidenceIds.filter((e) => e != item.data.evidenceId);
      }
    },
  },
};
</script>

<style></style>
