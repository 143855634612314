<template>
  <div class="d-flex flex-column">
    <case-back-div />
    <wizard-container show-all />
  </div>
</template>

<script>
import WizardContainer from "../Wizard/CaseWizard.vue";
import CaseBackDiv from "./CaseBackDiv.vue";
export default {
  components: {
    CaseBackDiv,
    WizardContainer,
  },
};
</script>
