import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, PUT, DELETE} from "./helpers";

export const getUser = async (email) => {
  if (!isDefined(email)) throw new Error("Email Required");
  return await jsonRequest(GET, `Users/${email}`);
};

export const getUsers = async (pageNumber, pageSize, filter) => {
  return await jsonRequestPaginated(GET, "Users", {pageNumber, pageSize, filter});
};

export const getUsersOverview = async () => {
  return await jsonRequest(GET, "Users/Overview");
};

export const putUser = async (email, title, firstName, lastName, roles) => {
  if (!isDefined(email)) throw new Error("Email Required");
  if (!isDefined(firstName)) throw new Error("First Name Required");
  if (!isDefined(lastName)) throw new Error("Last Name Required");
  const data = {
    email,
    title: isDefined(title) ? title : null,
    firstName,
    lastName,
    roles: isDefined(roles) ? roles : [],
  };
  return await jsonRequest(PUT, `Users/${email}`, {
    body: JSON.stringify(data),
  });
};

export const patchUser = async (email, title, firstName, lastName) => {
  if (!isDefined(email)) throw new Error("Email Required");
  const operations = [];
  if (isDefined(title)) operations.push({op: "replace", path: "/title", value: title});
  if (isDefined(firstName)) operations.push({op: "replace", path: "/firstName", value: firstName});
  if (isDefined(lastName)) operations.push({op: "replace", path: "/lastName", value: lastName});
  if (operations.length === 0) throw new Error("Nothing to do");
  return await jsonRequest(PATCH, `Users/${email}`, {
    body: JSON.stringify(operations),
  });
};

export const deleteUser = async (email) => {
  if (!isDefined(email)) throw new Error("Email Required");
  return await doRequest(DELETE, `Users/${email}`);
};


export const setUserConfig = async (
  email, nomenclature, timeZone, selectedWorkflowsCsv, selectedResultWorkflowsCsv,
  displayNotes, displayIncidents, displayIncidentColors
) => {
  if (!isDefined(email)) throw new Error("Email Required");
  const data = {};
  if (isDefined(nomenclature)) Object.assign(data, nomenclature);
  if (isDefined(timeZone)) data.timeZone = timeZone;
  if (isDefined(selectedWorkflowsCsv)) data.selectedWorkflowsCsv = selectedWorkflowsCsv;
  if (isDefined(selectedResultWorkflowsCsv)) data.selectedResultWorkflowsCsv = selectedResultWorkflowsCsv;
  if (isDefined(displayNotes)) data.displayNotes = displayNotes;
  if (isDefined(displayIncidents)) data.displayIncidents = displayIncidents;
  if (isDefined(displayIncidentColors)) data.displayIncidentColors = displayIncidentColors;
  return await jsonRequest(PUT, `Users/${email}/SetConfig`, {
    body: JSON.stringify(data),
  });
};
