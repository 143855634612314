<template>
  <div class="position-relative project-wrapper">
    <card class="project mr-3 position-relative">
      <div
        class="h-100 w-100"
        @click="viewProject"
      >
        <h4 class="project-title mr-3">
          {{ project.title }}
        </h4>
        <p class="project-description text-light">
          {{ project.description }}
        </p>
      </div>
      <base-button
        size="sm"
        type="neutral"
        icon-only
        :no-fill="!project.starred"
        class="position-absolute top-2 right-0"
        @click="starProject"
      >
        <star-icon size="1.5x" />
      </base-button>

      <div
        v-if="project.timeline || project.evidenceCount"
        class="item-details d-flex justify-content-center position-absolute"
      >
        <div class="item-data d-flex justify-content-center py-2">
          <span
            v-if="project.timeline"
          > Timeline: {{ project.timeline }} </span>
          <span
            v-if="project.evidenceCount"
          > Evidence: {{ project.evidenceCount }} </span>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {postProjectPreferences} from "../../../api";
import {StarIcon} from "vue-feather-icons";
import {mapGetters} from "vuex";

export default {
  components: {StarIcon},
  name: "project-card",
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("data", ["projectNameLabel", "starredNameLabel"]),
  },
  mounted() {
  },
  methods: {
    viewProject() {
      if (!this.project || this.project.id === undefined) return;
      this.$router.push(`/cases/${this.project.caseId}/project/${this.project.id}`);
    },

    starProject() {
      if (!this.project || this.project.id === undefined) return;
      postProjectPreferences(this.project.id, !this.project.starred)
        .then(() => {
          this.$notifySuccess(`${this.projectNameLabel} ${this.projectNameLabel} Successfully`);
          this.$emit("updated");
        })
        .catch((ex) => {
          this.$notifyError(`${this.projectNameLabel} ${this.projectNameLabel} Failed`, ex);
        });
    },
  },
};
</script>
