<template>
  <div class="ethos-container">
    <div class="paginated-table">
      <el-table
        class="mt-4"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="displayName"
          label="Display Name"
          min-width="200"
          sortable
        />
        <el-table-column
          prop="realName"
          label="Name"
          min-width="300"
          sortable
        />
        <el-table-column
          prop="createdOn"
          label="Created On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          label="Created By"
          min-width="200"
          sortable
        />
        <el-table-column
          prop="modifiedOn"
          label="Modified On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modifiedBy"
          label="Modified By"
          min-width="200"
          sortable
        />
        <el-table-column
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <base-button
              size="sm"
              type="primary"
              simple
              @click="handlePeopleView(scope.row.id)"
            >
              View
            </base-button>
          </template>
        </el-table-column>
      </el-table>
      <table-pagination
        ref="pagination"
        :pagination="pagination"
        @changed="handlePaginationChange"
      />
    </div>
    <!-- <download-blob
      ref="dlblob"
      :blob="dlFileBlob"
      :file-name="dlFileName"
      :content-type="dlFileContentType"
      :visible="false"
    /> -->
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {getPeopleSearch} from "../../../api";
// import {downloadPeople} from "../../../api";
// import {hasChildRoute} from "src/util/util";
// import {PlusIcon} from "vue-feather-icons";
// import DownloadBlob from "../DownloadBlob.vue";
import "src/assets/sass/custom/paginated-table.scss";
import {isDefined} from "../../../api/helpers";
import TablePagination from "../../../components/TablePagination.vue";

export default {
  name: "list-people",
  components: {
    // PlusIcon,
    TablePagination,
    // DownloadBlob,
  },
  props: {
  },
  data() {
    return {
      tableData: [],
      pagination: {
        TotalCount: 0,
      },
      // hasChildRoute: false,
      // dlFileBlob: null,
      // dlFileName: null,
      // dlFileContentType: null,
    };
  },
  computed: {
    ...mapGetters("data", [
      "getSearchKeyword",
      "userTimezone",
    ]),
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.hasChildRoute = hasChildRoute(to.path, "evidence");
  //   next();
  // },
  mounted() {
    // this.hasChildRoute = hasChildRoute(
    //   this.$router.currentRoute.path,
    //   "evidence"
    // );
    this.loadPeople(this.getSearchKeyword);
  },
  methods: {
    loadPeople(keyword) {
      if (!this.$refs.pagination) return;
      getPeopleSearch(this.$refs.pagination.pageNumber, this.$refs.pagination.pageSize, keyword)
        .then((response) => {
          this.tableData = response.data;
          this.pagination = JSON.parse(response.pagination);
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError("Loading Data Failed", ex);
        });
    },
    handlePaginationChange() {
      this.loadPeople();
    },
    handlePeopleView(id) {
      if (!isDefined(id)) return;
      this.$router.push(`/people/${id}`);
    },
  },
  watch: {
    "$store.state.data.searchKeyword": function() {
      this.loadPeople(this.$store.state.data.searchKeyword);
    },
  },
};
</script>
