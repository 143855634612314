<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default modal-wide"
    @close="fullReset"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <template v-if="isDisplayed">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="addNewEvidence d-flex flex-column justify-content-center">
            <template v-if="showEvidenceFields && !justWorkflows">
              <div class="mb-3">
                <!-- sub-header above Upload button -->
                <label
                  for="display-name"
                  class="form-label text-200 d-block"
                >
                  {{ showSelect ? 'Select From ETHOS' : 'Files' }}
                </label>

                <!-- Allow Selection from Existing ETHOS users -->
                <template v-if="showSelect">
                  <el-select
                    id="select-evidence"
                    ref="evidenceSelect"
                    v-model="selectedEvidenceIds"
                    class="select-primary dark w-100"
                    popper-class="select-primary"
                    :loading="!loadedEvidence"
                    loading-text="Fetching Evidence"
                    effect="dark"
                    filterable
                    multiple
                    placeholder="Evidence"
                    @focus="doGetEvidence"
                  >
                    <el-option
                      v-for="item in evidence"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </template>
                <template v-if="selectedEvidenceIds.length === 0">
                  <label
                    v-if="showSelect"
                    for="display-name"
                    class="form-label text-200 mt-4 d-block"
                  >
                    OR - Upload New Files
                  </label>
                  <!-- Below is where we call the function responsible for adding files -->
                  <drop-file
                    types="audio/*"
                    :button-only="true"
                    @filesSelected="addNewEvidenceSingle"
                  />

                  <!-- Show each file selected by user -->
                  <collapse>
                    <collapse-item classes="align-items-center d-flex with-switch">
                      <template slot="title">
                        <div class="align-items-baseline d-flex flex-grow-1 justify-content-between mr-4">
                          <div class="d-flex align-items-baseline">
                            <h4 class="title">
                              Files to Upload
                            </h4>
                            <span class="text-200 ml-2">({{ files.length }})</span>
                          </div>
                          <!-- This is our TOGGLE button for alphabetical sort -->
                          <div class="togglebutton switch-change-color mt-3">
                            <base-switch
                              v-model="alphabetical"
                              type="secondary"
                              @input="toggleMode"
                            />
                            <span class="label-switch label-right pl-2">Sort A-Z</span>
                          </div>
                        </div>
                      </template>
                      <div>
                        <div
                          v-for="item in files"
                          :key="item.title"
                          class="file-indicator"
                        >
                          <div class="mr-2">
                            <file-text-icon size="1.5x" />
                          </div>
                          <span>{{ item.name }}</span>
                          <base-button
                            class="close-modal"
                            @click="deleteEvidence(item)"
                          >
                            <x-icon size="1.2x" />
                          </base-button>
                        </div>
                      </div>
                    </collapse-item>
                  </collapse>
                </template>
              </div>

              <template v-if="selectedEvidenceIds.length === 0">
                <div class="mb-3">
                  <div class="form-group d-flex align-items-end span-grow">
                    <ValidationProvider
                      v-slot="{ passed, failed, errors }"
                      name="name"
                      :rules="{required: !showSelect}"
                    >
                      <base-input
                        v-model="name"
                        required
                        type="text"
                        label="Evidence Name"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                      />
                    </ValidationProvider>
                    <el-tooltip
                      transition=""
                    >
                      <div
                        slot="content"
                        class="p-2"
                        style="min-width:240px"
                      >
                        <div class="row">
                          <div class="col-12">
                            <h5 style="max-width:400px">
                              You can use any of the following in combination with your own text to
                              rename files within ETHOS dynamically on upload.
                            </h5>
                            Leaving the default
                            <i class="text-200">{filename}.{ext}</i> will retain the files original names.
                            <br>
                            <br>
                            Example: <i class="text-200">CASE-231A-{filename}.{ext}</i> would rename:
                            <br>
                            <span class="ml-2"> - </span>
                            <i class="text-200 ml-2">eg1.mp3</i> to <i class="text-200">CASE-231A-eg1.mp3</i>
                            <br>
                            <span class="ml-2"> - </span>
                            <i class="text-200 ml-2">eg2.m4a</i> to <i class="text-200">CASE-231A-eg2.m4a</i>
                            <br>
                            <br>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4 text-200">
                            {filename}
                          </div>
                          <div class="col-8">
                            Original file name
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-4 text-200">
                            {ext}
                          </div>
                          <div class="col-8">
                            Original file extension
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-4 text-200">
                            {index}
                          </div>
                          <div class="col-8">
                            The order in the list
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-4 text-200">
                            {date}
                          </div>
                          <div class="col-8">
                            Date and Time now
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-4 text-200">
                            {uploader}
                          </div>
                          <div class="col-8">
                            Your name
                          </div>
                        </div>
                      </div>
                      <base-button
                        class="mr-0 ml-3"
                        icon
                        simple
                        type="secondary"
                      >
                        i
                      </base-button>
                    </el-tooltip>
                  </div>
                  <div class="form-group">
                    <label
                      for="display-name"
                      class="form-label"
                    >
                      Case Number
                    </label>
                    <div class="row">
                      <el-select
                        v-model="selectedCase"
                        :clearable="caseId === null || caseId === undefined"
                        placeholder="Select Case"
                        class="select-primary dark col-9"
                        effect="dark"
                        popper-class="select-primary"
                      >
                        <el-option
                          v-for="item in cases"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name"
                        />
                      </el-select>

                      <base-button
                        v-if="!hasCaseId"
                        size="sm"
                        type="secondary"
                        simple
                        class="m-0"
                        @click="$refs.caseForm && $refs.caseForm.display()"
                      >
                        New Case
                      </base-button>
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      for="language"
                      class="form-label"
                    > Language </label>
                    <el-select
                      id="language"
                      v-model="language"
                      class="select-primary dark"
                      effect="dark"
                      popper-class="select-primary"
                    >
                      <el-option
                        v-for="lang in availableLanguages"
                        :key="lang"
                        :label="lang"
                        :value="lang"
                      />
                    </el-select>
                  </div>

                  <!-- TODO: - Workflow settings may need to move to workflow calling instead of Evidence -->
                  <div class="form-group row">
                    <div class="minspeaker col-6">
                      <ValidationProvider
                        v-slot="{ passed, failed, errors }"
                        name="minSpeakerCount"
                        rules="required|min:1|max:8"
                      >
                        <label
                          for="minSpeakerCount"
                          class="form-label"
                        >
                          Estimated Speakers (low)
                        </label>
                        <base-input
                          v-model="minSpeakerCount"
                          type="number"
                          name="minSpeakerCount"
                          :error="errors[0]"
                          :class="[
                            { 'has-success': passed },
                            { 'has-danger': failed },
                          ]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="maxspeaker col-6">
                      <ValidationProvider
                        v-slot="{ passed, failed, errors }"
                        name="maxSpeakerCount"
                        rules="required|min:1|max:8"
                      >
                        <label
                          for="maxSpeakerCount"
                          class="form-label"
                        >
                          Estimated Speakers (high)
                        </label>
                        <base-input
                          v-model="maxSpeakerCount"
                          type="number"
                          name="maxSpeakerCount"
                          :error="errors[0]"
                          :class="[
                            { 'has-success': passed },
                            { 'has-danger': failed },
                          ]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <template v-if="displayWorkflows">
              <workflows-selection ref="workflowSelection" />
            </template>

            <!-- Most bottom button for uploading or saving -->
            <base-button
              v-if="displayWorkflows || showEvidenceFields"
              native-type="submit"
              size="sm"
              type="primary"
              class="ml-auto"
            >
              {{ selectedEvidenceIds.length > 0 ? 'Save' : 'Upload' }}
            </base-button>
          </div>
          <add-case-modal
            ref="caseForm"
            @updated="newCase"
          />
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DropFile from "../../Ethos/DropFile.vue";
import {getCase, getAllCases, addEvidenceIdsToCase, getEvidenceForLookup} from "../../../api";
import {FileTextIcon, XIcon} from "vue-feather-icons";
import {v4 as uuidv4} from "uuid";
import AddCaseModal from "../../../components/DashboardV2/Case/AddCaseModal.vue";
import WorkflowsSelection from "../../Components/WorkflowsSelection.vue";

import {extend} from "vee-validate";
import {required, min, max} from "vee-validate/dist/rules";
import {isDefined} from "../../../api/helpers";
import Collapse from "../../../components/Collapse/Collapse.vue";
import CollapseItem from "../../../components/Collapse/CollapseItem.vue";

import {BaseSwitch} from "src/components";

extend("required", required);
extend("min", min);
extend("max", max);

export default {
  name: "add-new-evidenece",
  components: {
    DropFile,
    WorkflowsSelection,
    FileTextIcon,
    XIcon,
    AddCaseModal,
    Collapse,
    CollapseItem,
    BaseSwitch,
  },
  props: {
    uploadedFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    evidenceId: {
      type: Number,
      default: null,
    },
    evidenceName: {
      type: String,
      default: null,
    },
    justWorkflows: {
      type: Boolean,
      default: false,
    },
    caseId: {
      type: Number,
      default: null,
    },
    showWorkflows: {
      type: Boolean,
      default: true,
    },
    showEvidenceFields: {
      type: Boolean,
      default: true,
    },
    header: {
      type: String,
      default: "Add New !!",
    },
  },
  data() {
    return {
      isDisplayed: false,
      saving: false,
      name: "",
      caseNumber: "",
      files: [],
      addFromCase: false,
      cases: [],
      selectedCase: "",
      language: "",
      minSpeakerCount: 1,
      maxSpeakerCount: 2,
      storagePath: "",
      guid: "",
      evidence: [],
      loadedEvidence: false,
      selectedEvidenceIds: [],
      alphabetical: false,
    };
  },
  watch: {
    availableLanguages(to, from) {
      if (to && (!from || to.lengh !== from.length)) this.setDefaultLanguage();
    },
  },
  computed: {
    ...mapGetters("data", [
      "availableLanguages",
      "userTimezone",
    ]),
    ...mapGetters("auth", [
      "userFullName",
    ]),
    headerCopy() {
      if (!this.header) return "";
      return this.header.replace("!!!", "Evidence");
    },
    displayWorkflows() {
      return this.selectedEvidenceIds.length === 0 && (this.justWorkflows || this.showWorkflows);
    },
    showSelect() {
      return this.hasCaseId;
    },
    hasCaseId() {
      return isDefined(this.caseId);
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      "runWorkflows",
      "uploadEvidence",
    ]),
    display() {
      this.reset();
      if (!isDefined(this.caseId)) {
        this.getCases();
      } else {
        this.doGetCase();
      }
      this.setDefaultLanguage();
      this.addNewEvidence(this.uploadedFiles);
      this.isDisplayed = true;
    },
    reset() {
      this.isDisplayed = false;
      this.caseNumber = "";
      this.name = "{filename}.{ext}";
      this.files = [];
      this.addFromCase = false;
      this.cases = [];
      this.selectedCase = "";
      this.language = "";
      this.minSpeakerCount = 1;
      this.maxSpeakerCount = 2;
      this.storagePath = "";
      this.guid = "";
      this.$refs.workflowSelection && this.$refs.workflowSelection.reset();
      this.selectedEvidenceIds = [];
    },
    fullReset() {
      this.reset();
      this.$emit("close");
    },

    stopClick(event) {
      event.stopPropagation();
    },

    // The method that is called when the alphabetical sort switch is toggled
    toggleMode(type) {
      if (this.alphabetical) {
        this.files.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        // Sort by creation date
        this.files.sort((a, b) => a.ethos < b.ethos ? -1 : 1); // ? -1:1 turns it into ternary, so helps browser
      }
    },

    getCases() {
      this.cases = [];
      getAllCases()
        .then((cases) => {
          this.cases = cases;
        })
        .catch((ex) => {
          this.$notifyError("Loading Case Data Failed", ex);
        });
    },
    // Method to remove the displayed files that are going to be uploaded
    deleteEvidence(fileItem) {
      if (fileItem && this.files !== []) {
        this.files = this.files.filter((item) => item !== fileItem);
      }
    },
    doGetCase() {
      getCase(this.caseId)
        .then((response) => {
          if (response) {
            this.selectedCase = response.name;
            this.cases = [response];
          }
        })
        .catch((ex) => {
          this.$notifyError("Loading Case Data Failed", ex);
        });
    },
    // Subsequent uploads run this method (doesn't run this method with first upload)
    addNewEvidenceSingle(file) {
      if (file !== null) {
        this.files.push(file);
      }
    },
    // The first upload calls this method
    addNewEvidence(files) {
      if (files.length > 0) {
        files.forEach((file) => {
          this.files.push(file);
        });
      }
    },
    setDefaultLanguage() {
      if (
        Array.isArray(this.availableLanguages) &&
        this.availableLanguages.length > 0
      ) {
        const defaultLanguage = this.availableLanguages.find(
          (l) => l.toLowerCase() === "english"
        );
        this.language = defaultLanguage ?
          defaultLanguage :
          this.availableLanguages[0];
      }
    },

    submitJustWorkflows() {
      const date = this.$date().tz(this.userTimezone).format("MMM DD, YYYY [at] HH:mm A");

      const payload = {
        evidenceId: this.evidenceId,
        evidenceName: this.evidenceName || "Evidence",
        dateAdded: date,
        workflows: [],
      };

      const checkedWorkflows = this.$refs.workflowSelection ? this.$refs.workflowSelection.checked() : [];
      payload.workflows = checkedWorkflows;
      this.runWorkflows(payload);

      this.isDisplayed = false;
      this.$emit("close");
    },

    linkCaseToEvidence() {
      this.isDisplayed = false;
      addEvidenceIdsToCase(this.caseId, null, this.selectedEvidenceIds).then((c) => {
        this.$emit("updatedCase", c);
        this.$emit("close");
        this.reset();
      }).catch((ex) => {
        this.$notifyError("Unable to link Evidence to Case", ex);
      });
    },

    submit() {
      if (!this.files) {
        this.$notifyWarn("Files Required! Drag to upload audio file");
        return;
      }
      if (this.justWorkflows) return this.submitJustWorkflows();
      if (this.selectedEvidenceIds.length > 0) {
        return this.linkCaseToEvidence();
      }

      // We go through each and every file in files to upload.
      // This is also where we want to change the generics {...} if any.
      const dateTime = this.$date().tz(this.userTimezone).format("MMM DD, YYYY [at] HH:mm A");
      for (let i = 0; i < this.files.length; i++) {
        const f = this.files[i];
        const nameArray = f.name.split(".");
        const extension = nameArray.pop();
        const justName = nameArray.join(".");
        let cpyCurrentFileName = "";
        cpyCurrentFileName = this.name.replace("{filename}", justName).replace("{index}", i).replace("{date}", (new Date()).toISOString()).replace("{uploader}", this.userFullName).replace("{ext}", extension);

        const payload = {
          id: uuidv4(),
          name: cpyCurrentFileName,
          storagePath: this.storagePath,
          language: this.language,
          selectedCase: this.selectedCase,
          minSpeakerCount: this.minSpeakerCount,
          maxSpeakerCount: this.maxSpeakerCount,
          lastModifiedDate: f.lastModifiedDate?.toISOString(),
          dateAdded: dateTime,
          workflows: [],
          file: f,
        };

        this.isDisplayed = false;
        this.$emit("close");
        this.$emit("loadEvidence", payload);

        const checkedWorkflows = this.$refs.workflowSelection ? this.$refs.workflowSelection.checked() : [];
        payload.workflows = checkedWorkflows;
        payload.notify = {$n: this.$notify, $s: this.$notifications};
        this.uploadEvidence(payload);
      };
    },

    doGetEvidence() {
      if (this.loadedEvidence) return;
      getEvidenceForLookup().then((evidence) => {
        this.evidence = evidence;
        this.loadedEvidence = true;
      }).catch((ex) => {
        this.$notifyError("Unable to load existing Evidence", ex);
      });
    },

    newCase(c) {
      if (!isDefined(c)) return;
      this.cases.push(c);
      this.selectedCase = c.name;
    },
  },
};
</script>
