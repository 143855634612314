<template>
  <div class="d-flex flex-column flex-1">
    <div class="flex-1 d-flex flex-column justify-content-center">
      <h2 class="text-white mb-0 text-center">
        What would you like to do?
      </h2>
      <div class="text-caption text-center text-lightblue mb-10">
        {{ selectCopy }}
      </div>
      <div class="row px-10 mx-0">
        <card
          v-if="showEvidence"
          class="col mr-3 p-10 text-center cursor-pointer"
          @click="$refs.addEvidence.display()"
        >
          <ArchiveIcon
            size="32"
            class="text-white"
          />
          <h4 class="mt-3 my-0">
            Add Evidence
          </h4>
        </card>
        <card
          v-if="showPeople"
          class="col mr-3 p-10 text-center cursor-pointer"
          @click="$refs.addPeople.showModal = true"
        >
          <UserIcon
            size="32"
            class="text-white"
          />
          <h4 class="mt-3 my-0">
            Add People
          </h4>
        </card>
        <card
          v-if="showKeyword"
          class="col ml-3 p-10 text-center cursor-pointer"
          @click="$refs.addKeywords.showModal = true"
        >
          <FolderIcon
            size="32"
            class="text-white"
          />
          <h4 class="mt-3 my-0">
            Add Keyword
          </h4>
        </card>
      </div>
    </div>
    <add-people-modal
      ref="addPeople"
      :case-id="caseId"
      @created="$emit('created')"
    />
    <add-keyword-modal
      ref="addKeywords"
      @created="$emit('created')"
    />
    <add-new-evidence-modal
      ref="addEvidence"
      :case-id="caseId"
      @created="$emit('created')"
    />
  </div>
</template>

<script>
import {ArchiveIcon, UserIcon, FolderIcon} from "vue-feather-icons";
import {isDefined} from "../../../api/helpers";
import Card from "../../Cards/Card.vue";
import AddKeywordModal from "../Case/AddKeywordModal.vue";
import AddPeopleModal from "../Case/AddPeopleModal.vue";
import AddNewEvidenceModal from "./../../../../src/pages/DashboardV2/Evidence/AddNewEvidenceModal.vue";
export default {
  components: {
    Card,
    ArchiveIcon,
    UserIcon,
    FolderIcon,
    AddPeopleModal,
    AddKeywordModal,
    AddNewEvidenceModal,
  },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
    showOnly: {
      type: String,
      default: "",
    },
  },
  computed: {
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    selectCopy() {
      return this.shownCount > 1 ? "Select an option to begin." : "";
    },
    showOnlyLower() {
      return this.showOnly.toLowerCase();
    },
    showEvidence() {
      return this.showAll || this.showOnlyLower.indexOf("evidence") > -1;
    },
    showPeople() {
      return this.showAll || this.showOnlyLower.indexOf("people") > -1;
    },
    showKeyword() {
      return this.showAll || this.showOnlyLower.indexOf("keyword") > -1;
    },
    shownCount() {
      return [this.showEvidence, this.showPeople, this.showKeyword].reduce((p, c) => p + (c ? 1 : 0), 0);
    },
  },
};
</script>
