var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('drop-file',{attrs:{"types":"audio/*,video/*","center-button":true,"center-button-text":("Upload " + _vm.evidenceNameLabel)},on:{"filesSelected":_vm.multipleNewEvidence}}),_c('div',{staticClass:"border rounded-lg w-100"},[_c('div',{staticClass:"d-flex flex-row filter-list-header align-items-center ml-4 my-3"},[_c('h4',{staticClass:"m-0"},[_vm._v(" Uploads ")]),_c('el-select',{staticClass:"select-primary dark ml-4",attrs:{"effect":"dark","popper-class":"select-primary"},model:{value:(_vm.uploadingFilterSelection),callback:function ($$v) {_vm.uploadingFilterSelection=$$v},expression:"uploadingFilterSelection"}},_vm._l((_vm.filterOptions),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"d-inline-block w-100"},[_c('span',[_vm._v(_vm._s(row.name))]),_c('base-progress',{staticClass:"w-50",attrs:{"type":row.status === 'Failed' ? 'danger' : 'primary',"value":row.progress,"value-position":"none"}})],1)}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"d-inline-block w-100"},[_c('span',[_vm._v(" "+_vm._s(row.status)+" ")])])}}])}),_c('el-table-column',{attrs:{"prop":"size","label":"Size","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(((row.size.toFixed(1)) + " MB"))+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"dateAdded","label":"Date Added","width":"200","sortable":""}})],1)],1),_c('div',{staticClass:"border mt-4 rounded-lg"},[_c('div',{staticClass:"\n        d-flex\n        flex-row\n        filter-list-header\n        align-items-center\n        ml-4\n        my-3\n      "},[_c('h4',{staticClass:"m-0"},[_vm._v(" AI Analysis ")]),_c('el-select',{staticClass:"select-primary dark ml-4",attrs:{"effect":"dark","popper-class":"select-primary"},model:{value:(_vm.uploadingFilterSelection),callback:function ($$v) {_vm.uploadingFilterSelection=$$v},expression:"uploadingFilterSelection"}},_vm._l((_vm.filterOptions),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.workflowTableData}},[_c('el-table-column',{attrs:{"prop":"evidenceName","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"d-inline-block w-100"},[_c('span',[_vm._v(_vm._s(row.evidenceName))]),_c('base-progress',{staticClass:"w-50",attrs:{"type":row.status === 'Failed'
                ? 'danger'
                : row.status === 'Requesting'
                  ? 'secondary'
                  : 'primary',"value":row.progress,"value-position":"none"}}),(row.progress >= 100 && row.name == 'Create Transcription')?_c('base-button',{attrs:{"size":"sm","type":"primary","simple":""},on:{"click":function($event){return _vm.$router.push(("/evidence/" + (row.evidenceId)))}}},[_vm._v(" View "+_vm._s(_vm.evidenceNameLabel)+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Type","width":"300","sortable":""}}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"dateAdded","label":"Date Added","width":"200","sortable":""}})],1)],1),_c('add-new-evidence',{ref:"evidenceForm",attrs:{"uploaded-files":_vm.files,"header":"New !!!"},on:{"close":_vm.reset}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }