<template>
  <card class="admin-users">
    <div class="my-3 d-flex align-items-center">
      <h2 class="mr-3 d-inline-block mb-0">
        Users
      </h2>
      <base-button
        size="sm"
        type="primary"
        simple
        class="m-0"
        @click="addNewUser"
      >
        New User
        <plus-square-icon
          size="1.5x"
          class="ml-3"
        />
      </base-button>
    </div>
    <div class="paginated-table">
      <div>
        <el-table
          :data="users"
          style="width: 100%"
          :row-class-name="userRowClassName"
          @row-click="showUser"
        >
          <el-table-column
            prop="fullName"
            label="Name"
            min-width="200"
            sortable
          />
          <el-table-column
            prop="email"
            label="Email"
            min-width="250"
            sortable
          />
          <el-table-column
            label="Roles"
            min-width="200"
            sortable
          >
            <template slot-scope="scope">
              <el-tag
                v-for="role in scope.row.roles"
                :key="role"
                effect="dark"
                type="primary"
              >
                {{ role }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdOn"
            label="Created On"
            width="250"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createdBy"
            label="Created By"
            width="200"
            sortable
          />
          <el-table-column
            prop="modifiedOn"
            label="Modified  On"
            width="250"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="modifiedBy"
            label="Modified  By"
            width="200"
            sortable
          />
        </el-table>
      </div>

      <!-- <div class="pager">
        <el-pagination
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="pagination.TotalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div> -->
      <table-pagination
        ref="pagination"
        :pagination="pagination"
        @changed="handlePaginationChange"
      />
    </div>
    <modal
      :show.sync="showUserModal"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      class="modal-default"
    >
      <template v-if="showUserModal">
        <p
          slot="header"
          class="title"
        >
          {{ userModalHeader }}
        </p>
        <user-form
          :key="selectedUser ? selectedUser.email : 'new'"
          :user="selectedUser"
          @complete="userModalComplete"
          @deleted="userModalDeleted"
        />
      </template>
    </modal>
  </card>
</template>

<script>
import {mapGetters} from "vuex";
import {getUsers} from "../../../../api";
import {PlusSquareIcon} from "vue-feather-icons";
import UserForm from "./UserForm.vue";
import TablePagination from "../../../../components/TablePagination.vue";

export default {
  name: "UserManagement",
  components: {
    UserForm,
    PlusSquareIcon,
    TablePagination,
  },
  data() {
    return {
      users: [],
      pagination: {
        TotalCount: 0,
      },
      showUserModal: false,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "userEmail",
    ]),
    ...mapGetters("data", [
      "userTimezone",
    ]),
    userModalHeader() {
      return this.selectedUser && this.selectedUser.email ? this.selectedUser.email : "New User";
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      if (!this.$refs.pagination) {
        return;
      }
      getUsers(this.$refs.pagination.pageNumber, this.$refs.pagination.pageSize)
        .then((response) => {
          this.users = response.data;
          this.pagination = JSON.parse(response.pagination);
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError("Loading Users Data Failed", ex);
        });
    },
    addNewUser() {
      this.selectedUser = null;
      this.showUserModal = true;
    },
    showUser(r, c, e) {
      if (r.email === this.userEmail) return;
      this.selectedUser = r;
      this.showUserModal = true;
    },
    handlePaginationChange() {
      this.loadUsers();
    },
    userModalComplete(user) {
      if (user) {
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].email === user.email) {
            Object.assign(this.users[i], user);
          }
        }
      }
      this.selectedUser = null;
      this.showUserModal = false;
    },
    userModalDeleted(email) {
      if (email) {
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].email === email) {
            this.users.splice(i, 1);
          }
        }
      }
      this.selectedUser = null;
      this.showUserModal = false;
    },
    userRowClassName({row, rowIndex}) {
      if (row.roles.length < 1) {
        return "cell-strike-through";
      }
      return "";
    },
  },
};
</script>

<style>
.cell-strike-through td {
  text-decoration-line: line-through;
}
</style>
