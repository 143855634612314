<template>
  <modal-v-2
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    @close="onClose()"
  >
    <div slot="header">
      <h6 class="text-white mb-0">
        Keywords
      </h6>
      <p class="mb-0 text-lightblue">
        Total &#x2022; {{ form.keywords.length }}
      </p>
    </div>
    <base-chip-text-area
      ref="basechip"
      v-model="form.keywords"
      label="Keywords"
    />
    <span class="text-white mt-1">Press enter to add keywords, or use commas</span>
    <div class="text-right mt-8 mb-3">
      <base-button
        class="m-0"
        wide
        @click="createKeywords"
      >
        Add
      </base-button>
    </div>
  </modal-v-2>
</template>

<script>
import {postCaseKeywords} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import BaseButton from "../../BaseButton.vue";
import BaseChipTextArea from "../../Inputs/BaseChipTextArea.vue";
import ModalV2 from "../ModalV2.vue";
export default {
  components: {
    ModalV2,
    BaseButton,
    BaseChipTextArea,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      form: {
        keywords: [],
        name: null,
      },
    };
  },
  computed: {
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  methods: {
    createKeywords() {
      let keywords = Array.isArray(this.form.keywords) ? this.form.keywords.slice() : [];
      if (this.$refs.basechip) {
        const residual = this.$refs.basechip.getCurrentInput();
        if (isDefined(residual)) keywords.push(residual);
      }
      keywords = keywords.map((k) => k.trim()).filter((k) => k.length > 0); // nonempty strings

      if (keywords.length < 1) {
        this.$notifyWarn("Keyword required");
        return;
      }
      if (!isDefined(this.caseId)) {
        this.$notifyError("Failed to create Case Keywords", ex);
        return;
      }
      this.loading = true;
      postCaseKeywords(this.caseId, keywords)
        .then((response) => {
          this.$emit("created");
         if (this.$route.name === ethosRouteNames.CaseKeywords) {
            this.form.keywords = [];
            this.$refs.basechip.clearAll();
            this.showModal = false;
            return;
          }
          const caseId =  this.caseId
          this.$router.push({
            name: ethosRouteNames.CaseKeywords,
            params: {caseId},
          }).then(() => { this.$router.go() });
        })
        .catch((ex) => {
          this.$notifyError("Creating Case Failed", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClose() {
      this.form.keywords = [];
      this.$refs.basechip.clearAll();
      this.showModal = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
