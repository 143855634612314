<template>
  <div class="dashboard-v2">
    <template v-if="!hideNav">
      <notifications />
      <upload-manager-overlay
        ref="uploadoverlay"
      />
      <nav-bar />
    </template>
    <div class="dashboard-v2--layout">
      <template v-if="isFull">
        <div class="dashboard-v2--full">
          <slot name="full" />
        </div>
      </template>
      <template v-else>
        <div class="dashboard-v2--left">
          <slot name="left" />
        </div>
        <div class="dashboard-v2--right">
          <slot name="right" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import {mapGetters} from "vuex";
import {isDefined} from "../../api/helpers";
import UploadManagerOverlay from "../../pages/Ethos/Dashboard/UploadManagerOverlay.vue";
export default {
  components: {NavBar, UploadManagerOverlay},
  props: {
    hideNav: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getUploadResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) this.$refs.uploadoverlay.display();
    },
  },
  computed: {
    ...mapGetters("auth", [
      "established",
    ]),
    ...mapGetters("data", [
      "getUploadResponse",
    ]),
    authEstablished() {
      return this.$auth.isAuthenticated && this.established;
    },
    isFull() {
      return isDefined(this.$slots.full && this.$scopedSlots.full);
    },
  },
};
</script>

<style lang="scss">
.dashboard-v2--right {
  overflow-y: auto;
}
</style>
