var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex items-center align-items-center"},[_c('div',[_c('h6',{staticClass:"text-white mb-0"},[_vm._v(" Exhibits ")]),_c('p',{staticClass:"mb-0 text-lightblue"},[_vm._v(" Total: "+_vm._s(_vm.data.length)+" ")])]),_c('div',{staticClass:"flex-1"}),_c('base-button',{attrs:{"link":""}},[_c('FilterIcon')],1),(_vm.selected.length > 0)?_c('base-button',{attrs:{"type":"simple"}},[_c('ShareIcon'),(_vm.data.length == _vm.selected.length)?_c('span',[_vm._v("Share all")]):_c('span',[_vm._v("Share selected")])],1):_vm._e(),_c('base-button',{attrs:{"type":"simple"}},[_c('PlusIcon'),_vm._v(" Exhibit ")],1)],1),_c('base-select-table',{attrs:{"columns":_vm.columns,"data":_vm.data},on:{"input":function($event){return _vm.$emit('input', _vm.selected)}},scopedSlots:_vm._u([{key:"header-name",fn:function(){return [_vm._v(" File Name: "+_vm._s(_vm.data.length)+" Files ")]},proxy:true},{key:"header-signals",fn:function(){return [_vm._v(" Signals "),_c('RepeatIcon',{staticClass:"ml-1 text-lightblue",attrs:{"size":"16"}})]},proxy:true},{key:"header-description",fn:function(){return [_vm._v(" Description ")]},proxy:true},{key:"header-evidenceName",fn:function(){return [_vm._v(" Seen In ")]},proxy:true},{key:"data-title",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"font-weight-bold",on:{"click":function($event){return _vm.display(data.item)}}},[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"data-description",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"exb-desc"},[_vm._v(_vm._s(data.item.description))])]}},{key:"data-evidenceName",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-underline",on:{"click":function () { return _vm.showEvidence(data.item.evidenceId); }}},[_vm._v(" "+_vm._s(data.item.evidenceName)+" ")])]}},{key:"data-signals",fn:function(ref){
var data = ref.data;
return [(data.selected == false)?_c('span',[_vm._v(_vm._s(data.item.signals))]):_c('span')]}},{key:"data-createdOn",fn:function(ref){
var data = ref.data;
return [(data.selected)?[_c('base-button',{staticClass:"m-0",attrs:{"link":""}},[_c('ShareIcon',{staticClass:"mr-1",attrs:{"size":"16"}}),_vm._v(" Share ")],1)]:[_vm._v(" "+_vm._s(_vm.$date(data.item.createdOn).tz(_vm.userTimezone).format("MM-DD-YY"))+" ")]]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }