<template>
  <card class="case-transcription p-5 mb-5">
    <div class="d-flex">
      <div class="photo bg-black d-flex">
        <div
          class="spinner-border bg-black text-warning"
          role="status"
          v-if="inProgress"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <material-icon v-else size="26px" class="text-white"
          >task_alt</material-icon
        >
      </div>
      <div class="pl-3 flex-grow-1">
        <p class="case-transcription-title font-16 font-weight-bold">
          Transcriptions {{ inProgress ? "in progress" : "Completed" }}
        </p>
        <template v-if="inProgress">
          <p class="case-transcription-description mb-0">Transcribing: 2</p>
          <p class="case-transcription-description mb-0">Completed: 5</p>
        </template>
        <p v-else class="case-transcription-description mb-0">8</p>
      </div>
      <div>
        <base-button
          type="primary"
          class="m-0"
          @click="isExpanded = false"
          link
          v-if="isExpanded"
          >Close</base-button
        >
        <base-button
          type="primary"
          class="m-0"
          link
          @click="isExpanded = true"
          v-else
          >Expand</base-button
        >
      </div>
    </div>

    <div v-show="isExpanded" class="case-transcription--list mt-2 pl-3">
      <card class="bg-black p-4 mb-3" v-for="i in 8" :key="i">
        <div class="d-flex">
          <p class="case-transcription-description flex-grow-1">
            Transcript Name
          </p>
          <p class="case-transcription-description">Sep 15, 2023 at 1:20 PM</p>
        </div>
        <p class="case-transcription-description flex-grow-1">
          Length: 8 minutes
        </p>
        <p class="case-transcription-description flex-grow-1">
          File Type: .mov
        </p>
      </card>
    </div>
  </card>
</template>

<script>
import BaseButton from "../../BaseButton.vue";
import Card from "../../Cards/Card.vue";
import MaterialIcon from "../MaterialIcon.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Card, BaseButton, MaterialIcon },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    inProgress() {
      return this.item.status == "In Progress";
    },
  },
  beforeMount() {
    this.isExpanded = !this.inProgress;
  },
};
</script>

<style lang="scss">
.case-transcription {
  .photo {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    .spinner-border {
      width: 36px;
      height: 36px;
    }
  }
  width: 100%;
  &-description {
    font-weight: 400;
  }
  &--list {
    margin-left: 36px;
  }
}
</style>
