<template>
  <div class="d-flex w-100">
    <audio-transcript v-if="clipId !== null && clipId !== undefined" />
    <div
      v-else
      class="flex-grow-1 m-2"
    >
      <el-table
        :data="clips"
        style="width: 100%"
      >
        <el-table-column
          prop="title"
          label="Name"
          min-width="200"
          sortable
        />
        <el-table-column
          label="Start/End"
          min-width="180"
          sortable
        >
          <template slot-scope="scope">
            {{ startEnd(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Duration"
          min-width="140"
          sortable
        >
          <template slot-scope="scope">
            {{ duration(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdOn"
          label="Created On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.createdOn).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          label="Created By"
          min-width="200"
          sortable
        />
        <el-table-column
          prop="modifiedOn"
          label="Modified On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.modifiedOn).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modifiedBy"
          label="Modified By"
          min-width="200"
          sortable
        />
        <el-table-column
          align="center"
          min-width="120"
        >
          <template slot-scope="scope">
            <base-button
              size="sm"
              type="primary"
              simple
              @click="display(scope.row)"
            >
              View
            </base-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getClips} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {secsToTimeString} from "../../../util/util";
import AudioTranscript from "./AudioTranscript/AudioTranscript.vue";

export default {
  name: "clips-list",
  components: {
    AudioTranscript,
  },
  data() {
    return {
      clips: [], // Our array to hold all achived incidents
    };
  },
  computed: {
    evidenceId() {
      const id = this.$route.params.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    clipId() {
      const id = this.$route.params.clipId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  mounted() {
    this.loadClips();
  },
  methods: {
    loadClips() {
      if (this.evidenceId !== null) {
        getClips(this.evidenceId).then((clips) => {
          this.clips = clips;
        }).catch((ex) => {
          this.$notifyError(`Loading ${this.evidenceId} Evidence's Clips Failed`, ex);
        });
      }
    },
    startEnd(clip) {
      const start = secsToTimeString(clip.startOffset / 1000, true);
      const end = secsToTimeString(clip.endOffset / 1000, true);
      return `${start} to ${end}`;
    },
    duration(clip) {
      const secs = clip.endOffset - clip.startOffset;
      return secsToTimeString(secs / 1000, true);
    },
    display(clip) {
      if (!isDefined(clip)) return;
      let prefix;
      if (isDefined(this.evidenceId)) {
        if (!isDefined(this.projectId)) {
          prefix = `/evidence/${this.evidenceId}`;
        } else {
          prefix = `/case/${this.caseId}/project/${this.projectId}/evidence/${this.evidenceId}`;
        }
      } else {
        prefix = `/case/${this.caseId}/project/${this.projectId}`;
      }

      // Then combine and test to avoid double routing:
      const fullPath = `${prefix}/clips/${clip.id}`;
      if (this.$route && this.$route.path.startsWith(prefix) && !this.$route.path.startsWith(fullPath)) {
        this.$router.push(fullPath);
      }
    },
  },
};
</script>
