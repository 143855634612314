import Vue from "vue";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";

const mutations = {
  setConfig(state, config) {
    if (!config) return;
    state.config.labels = [];
    ["case", "cases", "project", "evidence", "people", "person", "incident", "starred", "timeline"].forEach((s) => {
      const name = `${s}NameLabel`;
      state.config.labels.push({name, value: config[name]});

      state.searchRadioOpts.forEach((o) => {
        if (o.name.toLowerCase() === s.toLowerCase()) o.label = config[name];
      });
    });

    if (config.availableWorkflowsCsv) {
      const parsedGroups = [];
      config.availableWorkflowsCsv.split("##")
        .filter((g) => g.length > 3)
        .forEach((g) => {
          const parts = g.split("!!!");
          if (parts.length = 2) {
            parsedGroups.push({
              name: parts[0],
              workflows: parts[1].split(","),
            });
          }
        });
      state.config.availableWorkflows = [];
      state.config.availableWorkflows.push(...parsedGroups);
    }
    if (config.selectedWorkflowsCsv) {
      state.config.selectedWorkflows = [];
      state.config.selectedWorkflows.push(
        ...config.selectedWorkflowsCsv.split(",")
      );
    }
    if (config.availableResultWorkflowsCsv) {
      state.config.availableResultWorkflows = [];
      state.config.availableResultWorkflows.push(
        ...config.availableResultWorkflowsCsv.split(",")
      );
    }
    if (config.selectedResultWorkflowsCsv) {
      state.config.selectedResultWorkflows = [];
      state.config.selectedResultWorkflows.push(
        ...config.selectedResultWorkflowsCsv.split(",")
      );
    }
    if (config.availableLanguagesCsv) {
      state.config.availableLanguages = [];
      state.config.availableLanguages.push(
        ...config.availableLanguagesCsv.split(",")
      );
    }
    if (config.availablePeopleGroupsCsv) {
      state.config.availablePeopleGroups = [];
      state.config.availablePeopleGroups.push(
        ...config.availablePeopleGroupsCsv.split(",")
      );
    }
    if (config.autoApprovalRoles) {
      state.config.autoApprovalRoles = [];
      state.config.autoApprovalRoles.push(
        ...config.autoApprovalRoles.split(",")
      );
    }
    console.warn(config.availableClipStatesCsv);
    if (config.availableClipStatesCsv) {
      state.config.availableClipStates = [];
      state.config.availableClipStates.push(
        ...config.availableClipStatesCsv.split(",")
      );
    }
    if (config.availableCaseRolesCsv) {
      state.config.availableCaseRoles = [];
      state.config.availableCaseRoles.push(
        ...config.availableCaseRolesCsv.split(",").map((s) => {
          const parts = s.split(":");
          const name = parts.pop();
          let value = parts.pop();
          if (!isDefined(value)) {
            value = 0;
          } else {
            value = parseInt(value, 10);
          }
          if (isNaN(value)) value = 0;
          return {
            name,
            value,
          };
        })
      );
    }
    if (config.timeZone) {
      state.config.timeZone = config.timeZone;
    }
    if (isDefined(config.displayNotes)) {
      state.config.displayNotes = config.displayNotes;
    }
    if (isDefined(config.displayIncidents)) {
      state.config.displayIncidents = config.displayIncidents;
    }
    if (isDefined(config.displayIncidentColors)) {
      state.config.displayIncidentColors = config.displayIncidentColors;
    }
  },
  setAutoApproveRoles(state, data) {
    if (data && Array.isArray(data)) {
      state.config.autoApprovalRoles = data.slice();
    }
  },
  setBreadcrumbHint(state, data) {
    Vue.set(state.breadcrumbHints, data.name, data.value);
  },
  setHighlight(state, data) {
    if (!data) return;
    let newPaths = [];
    const toMap = Array.isArray(data) ? data : [data];
    toMap.forEach((pair) => {
      if (!pair.key || pair.id === null || pair.id === undefined) return;
      let path = `${pair.id}`;
      if (pair.key === "transcriptions") {
        const evidenceId = state[pair.key][pair.id].evidenceId;
        if (state.evidence[evidenceId]) {
          path = `${evidenceId}.${path}`;
          try {
            const caseId = state.evidence[evidenceId].caseIds[0];
            if (state.cases[caseId]) {
              path = `${caseId}.${path}`;
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      } else if (pair.key === "transcriptions") {
        try {
          const caseId = state[pair.key][pair.id].caseIds[0];
          if (state.cases[caseId]) {
            path = `${caseId}.${path}`;
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      if (pair.state === 2) {
        newPaths = [path];
      } else {
        newPaths.push(path);
      }
      state.highlightPaths = newPaths;
    });
  },
  putSearchResults(state, data) {
    if (!data) throw new Error("Failed to find data to set");
    const type = data.type;
    const query = data.query;
    const result = data.result;

    const opt = state.searchRadioOpts.filter((o) => o.name === type)[0];
    const key = opt ? opt.key : null;

    const search = {
      id: state.searchCount,
      type: opt ? opt.label : type,
      query,
      count: result ? result.length : 0,
      error: null, // TODO: - Map this? or only log success this way?
      active: true,
      transcriptions: [],
      cases: [],
      evidence: [],
    };
    state.searchCount++;
    if (result) {
      if (Array.isArray(result)) {
        result.forEach((val) => {
          putEach(state, key, val, search);
        });
      } else {
        putEach(state, key, result, search);
      }
    }
    state.searches.push(search);
  },
  setEvidenceAdded(state, data) {
    Vue.set(state, "evidenceListRefreshToken", Date.now());
  },
  setSearchKeyword(state, data) {
    if (!data) throw new Error("Failed to find data to set");
    state.searchKeyword = data.keyword;
    // Set the title
    Vue.set(state.breadcrumbHints, ethosRouteNames.Search, `Search results for "${data.keyword}"`);
  },
  setUploadResponse(state, response) {
    const existsAtIndex = state.uploadResponses.findIndex((u) => u.id === response.id);
    if (existsAtIndex !== -1) {
      state.uploadResponses[existsAtIndex] = response;
    } else {
      state.uploadResponses.push(response);
    }
    // Clone to prompt
    state.uploadResponses = [...state.uploadResponses];
  },
  popUploadResponse(state, uploadResponseId) {
    const existsAtIndex = state.uploadResponses.findIndex((w) => w.id === uploadResponseId);
    if (existsAtIndex !== -1) {
      state.uploadResponses.splice(existsAtIndex);
    }
  },
  setWorkflowResponse(state, response) {
    const existsAtIndex = state.workflowResponses.findIndex((w) => w.id === response.id);
    if (existsAtIndex !== -1) {
      state.workflowResponses[existsAtIndex] = response;
    } else {
      state.workflowResponses.push(response);
    }
    // Clone to prompt
    state.workflowResponses = [...state.workflowResponses];
  },
  popWorkflowResponse(state, workflowResponseId) {
    const existsAtIndex = state.workflowResponses.findIndex((w) => w.id === workflowResponseId);
    if (existsAtIndex !== -1) {
      state.workflowResponses.splice(existsAtIndex);
    }
  },
  putCodeWordGroups(state, codeWordGroups) {
    if (codeWordGroups && Array.isArray(codeWordGroups)) {
      codeWordGroups.forEach((group) => {
        Vue.set(state.codeWordGroups, group.id, group);
      });
    }
  },
  putCodeWordGroup(state, codeWordGroup) {
    if (codeWordGroup) {
      Vue.set(state.codeWordGroups, codeWordGroup.id, codeWordGroup);
    }
  },
  putCodeWordCollection(state, data) {
    if (
      !isDefined(data) ||
      !isDefined(data.groupId) ||
      !isDefined(data.collection)
    ) return;
    const collectionIndex = state.codeWordGroups[data.groupId]
      .collections.findIndex((a) => a.id === data.collection.id);
    if (collectionIndex === -1) {
      state.codeWordGroups[data.groupId].collections.push(data.collection);
    } else {
      state.codeWordGroups[data.groupId].collections[collectionIndex] = data.collection;
    }
  },
  putWord(state, data) {
    if (
      !isDefined(data) ||
      !isDefined(data.groupId) ||
      !isDefined(data.collectionId) ||
      !isDefined(data.word)
    ) return;
    const collectionIndex = state.codeWordGroups[data.groupId]
      .collections.findIndex((a) => a.id === data.collectionId);
    if (collectionIndex === -1) return;
    const wordIndex = state.codeWordGroups[data.groupId].collections[collectionIndex]
      .words.findIndex((w) => w.id === data.word.id);
    if (wordIndex === -1) {
      state.codeWordGroups[data.groupId].collections[collectionIndex].words.push(data.word);
    } else {
      state.codeWordGroups[data.groupId].collections[collectionIndex].words[wordIndex] = data.word;
    }
  },
  putPeople(state, peopleList) {
    if (peopleList && Array.isArray(peopleList)) {
      peopleList.forEach((people) => {
        Vue.set(state.people, people.id, people);
      });
    }
  },
  setLoadedPeople(state, bool) {
    state.loadedPeople = bool;
    if (bool) {
      state.loadedPeopleGroups = state.config.availablePeopleGroups;
    }
  },
  setLoadedPeopleGroup(state, groupName) {
    state.loadedPeopleGroups.push(groupName);
    state.loadedPeopleGroups = [...state.loadedPeopleGroups];
  },
  putUserMap(state, users) {
    state.userMap = users;
  },
  putActorMap(state, actors) {
    state.actorMap = actors;
  },
  putActor(state, actor) {
    Vue.set(state.actor, actor.id, actor);
  },
  putEvidence(state, evidence) {
    Vue.set(state.evidence, evidence.id, evidence);
  },
  putTranscription(state, transcription) {
    Vue.set(state.transcriptions, transcription.id, transcription);
  },
  putCase(state, caseModel) {
    Vue.set(state.cases, caseModel.id, caseModel);
  },
  putCaseSummary(state, caseSummaryModel) {
    Vue.set(state.caseSummaries, caseSummaryModel.id, caseSummaryModel);
  },
  putCaseEvidenceSummaries(state, caseEvidenceSummariesModel) {
    Vue.set(state.caseEvidenceSummaries, caseEvidenceSummariesModel.caseId, caseEvidenceSummariesModel.summaries);
  },
  putCaseClipSummaries(state, caseClipSummariesModel) {
    Vue.set(state.caseClipSummaries, caseClipSummariesModel.caseId, caseClipSummariesModel.summaries);
  },
  putCaseActorAttributions(state, caseActorAttributionsModel) {
    Vue.set(state.caseActorAttributions, caseActorAttributionsModel.caseId, caseActorAttributionsModel.actors);
  },
  putCaseUserAttributions(state, caseUserAttributionsModel) {
    Vue.set(state.caseUserAttributions, caseUserAttributionsModel.caseId, caseUserAttributionsModel.users);
  },
};

const putEach = (state, key, val, search) => {
  search[key].push(val.id);
  let obj;
  switch (key) {
    case "evidence":
      obj = putEvidence(state, val, search);
      break;
    case "cases":
      obj = putCase(state, val, search);
      break;
    case "transcriptions":
      obj = putTranscription(state, val, search);
      break;
    // case "actors":
    //   obj = putActor(state, val, search);
    //   break;
    default: break;
  }
  console.info(obj);
};

// Model Mappers
const putCase = (state, data, search, enforceIds) => {
  if (!data) return null;
  if (search.cases.indexOf(data.id) === -1) search.cases.push(data.id);
  const eCase = {
    highlight: 0,
    id: data.id,
    name: data.name,
    evidenceIds: enforceIds ? enforceIds : [],
  };
  if (data.evidence && data.evidence.length > 0) {
    data.evidence
      .map((evidence) => putEvidence(state, evidence, search))
      .filter((evidence) => evidence !== null)
      .forEach((evidence) => {
        if (eCase.evidenceIds.indexOf(evidence.id) === -1) {
          eCase.evidenceIds.push(evidence.id);
        }
      });
  }

  if (state.cases[data.id]) {
    state.cases[data.id].evidenceIds.forEach((id) => {
      if (eCase.evidenceIds.indexOf(id) === -1) {
        eCase.evidenceIds.push(id);
      }
    });
  }
  Vue.set(state.cases, data.id, eCase);

  return eCase;
};

const putEvidence = (state, data, search) => {
  if (!data) return null;
  if (search.evidence.indexOf(data.id) === -1) search.evidence.push(data.id);
  const evidence = {
    highlight: 0,
    id: data.id,
    name: data.name,
    language: data.language,
    storagePath: data.storagePath,
    caseIds: [],
  };

  if (data.cases && data.cases.length > 0) {
    data.cases
      .map((eCase) => putCase(state, eCase, search, [data.id]))
      .filter((eCase) => eCase !== null)
      .forEach((eCase) => {
        if (evidence.caseIds.indexOf(eCase.id) === -1) {
          evidence.caseIds.push(eCase.id);
        }
      });
  }

  if (state.evidence[data.id]) {
    state.evidence[data.id].caseIds.forEach((id) => {
      if (evidence.caseIds.indexOf(id) === -1) {
        evidence.caseIds.push(id);
      }
    });
  }
  Vue.set(state.evidence, data.id, evidence);

  return evidence;
};

const putTranscription = (state, data, search) => {
  if (!data) return null;
  if (search.transcriptions.indexOf(data.id) === -1) search.transcriptions.push(data.id);
  if (data.evidence) {
    putEvidence(state, data.evidence, search);
  }

  const transcription = {
    highlight: 0,
    id: data.id,
    name: data.name,
    language: data.language,
    content: data.content,
    evidenceId: data.evidenceId,
    parentTranscriptionId: data.parentTranscriptionId,
    workflowId: data.workflowId,
    workflow: workflowFromId(state, data.workflowId),
    signedUrl: data.signedUrl ?? "",
  };
  Vue.set(state.transcriptions, data.id, transcription);
  return transcription;
};

const workflowFromId = (state, id) => {
  let matchingName = id;
  if (id === null || id === undefined || !state || !state.config || !state.config.availableWorkflows) return id;
  state.config.availableWorkflows.forEach((g) => {
    g.workflows.forEach((w) => {
      if (w.replace(/\W/g, "") === id) matchingName = w;
    });
  });
  return matchingName;
};

export default mutations;
