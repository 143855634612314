
import {attributesOf} from "../../../api";
import {isDefined} from "../../../api/helpers";

const actions = {
  async loadCasePeopleAttributions({commit, state}, payload) {
    if (!isDefined(payload.caseId)) throw new Error("Need caseId");
    const response = await attributesOf("case", payload.caseId);
    const actors = response.filter((r) => isDefined(r.actorId));
    const users = response.filter((r) => isDefined(r.userId));
    commit("putCaseActorAttributions", {caseId: payload.caseId, actors});
    commit("putCaseUserAttributions", {caseId: payload.caseId, users});
  },
};

export default actions;
