<template>
  <card
    class="p-5"
    @click="$emit('click')"
  >
    <h6 class="font-weight-bold">
      {{ caseItem.title }} #{{ caseItem.name }}
    </h6>
    <p class="case-description font-16 my-3 text-lightblue prewrap">
      {{ caseItem.description }}
    </p>
    <div class="d-flex case-stats px-2 py-1 bg-dark-100">
      <!-- <div class="flex-1 text-lightblue">
        Events: 2
      </div> -->
      <div class="flex-1 text-lightblue">
        Evidence: {{ evidenceCount }}
      </div>
      <div class="flex-1 text-lightblue">
        People: {{ peopleCount }}
      </div>
      <div class="flex-1 text-lightblue">
        Keywords: {{ keywordCount }}
      </div>
      <div
        v-if="0"
        class="flex-1 text-lightblue"
      >
        New Evidence: {{ newEvidenceCount }}
      </div>
      <!-- <div class="flex-1 text-lightblue">
        Timelines: 2
      </div> -->
    </div>
  </card>
</template>

<script>
import Card from "../../Cards/Card.vue";

export default {
  props: {
    caseItem: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {Card},
  computed: {
    evidenceCount() {
      return this.caseItem.evidenceCount;
    },
    keywordCount() {
      return this.caseItem.caseKeywordCount;
    },
    peopleCount() {
      return this.caseItem.userAttributionCount + this.caseItem.actorAttributionCount;
    },
    newEvidenceCount() {
      return 0;
      // / Replace with a rollup?
      // return (this.caseItem.evidence ?? []).filter((e) => e.status === "new").length;
    },
  },
};
</script>

<style lang="scss">
.case-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-stats {
  border-radius: 8px;
  div {
    text-align: center;
    color: white;
  }
}
.case-card--active {
  background: black !important;
  border: 1px solid var(--primary) !important;
}
</style>
