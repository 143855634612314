<template>
  <modal-v-2
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    @close="onClose()"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <base-button
        tag="a"
        size="xs"
        class="m-0"
        link
        @click="onClose(true)"
      >
        <ArrowLeftIcon class="text-white" />
      </base-button>
      <h6 class="text-white mb-0">
        LEO
      </h6>
    </div>
    <div class="d-flex flex-column">
      <label
        for="people-groups"
        class="form-label text-lightblue"
      >
        Select from Registry
      </label>
      <el-select
        v-model="form.userId"
        class="select-primary dark"
        effect="dark"
        popper-class="select-primary"
        filterable
      >
        <el-option
          v-for="item in users"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      v-if="!associate"
      class="d-flex flex-column"
    >
      <label
        for="people-groups"
        class="form-label text-lightblue"
      >
        Select Role
      </label>
      <el-select
        v-model="form.role"
        class="select-primary dark"
        effect="dark"
        popper-class="select-primary"
        filterable
      >
        <el-option
          v-for="item in roles"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      v-if="associate"
      class="d-flex mt-2"
    >
      <base-button
        class="flex-1 m-0"
        :disabled="!hasSelected"
        simple
        wide
        @click="assignSingle"
      >
        Assign Single
      </base-button>
      <base-button
        class="flex-1 m-0 ml-4"
        :disabled="!hasSelected"
        wide
        @click="assignAll"
      >
        Assign To All
      </base-button>
    </div>
    <div
      v-else
      class="text-right mt-8 mb-3"
    >
      <base-button
        class="m-0"
        wide
        @click="createUserCaseAttribute"
      >
        Add
      </base-button>
    </div>
  </modal-v-2>
</template>

<script>
import {ArrowLeftIcon} from "vue-feather-icons";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {attributeUser, getUsersOverview} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import BaseButton from "../../BaseButton.vue";
import ModalV2 from "../ModalV2.vue";
export default {
  components: {
    ModalV2,
    BaseButton,
    ArrowLeftIcon,
  },
  props: {
    caseId: {
      type: [String, Number],
      default: null,
    },
    evidenceId: {
      type: [String, Number],
      default: null,
    },
    incidentId: {
      type: [String, Number],
      default: null,
    },
    associate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      form: {
        userId: "",
        role: "",
        mapAll: false,
      },
    };
  },
  computed: {
    ...mapGetters("data", ["availableCaseRoles", "getUserMap"]),
    roles() {
      return this.availableCaseRoles;
    },
    caseIdInt() {
      const id = this.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    evidenceIdInt() {
      const id = this.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    incidentIdInt() {
      const id = this.incidentId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    isCaseOnly() {
      return !isDefined(this.evidenceIdInt) && !isDefined(this.incidentIdInt);
    },
    users() {
      if (!isDefined(this.getUserMap)) return [];
      return Object.entries(this.getUserMap).map(([k, v]) => {
        return {name: v.fullName, value: k};
      });
    },
    hasSelected() {
      return isDefined(this.form.userId) && this.form.userId.length > 0;
    },
  },
  mounted() {
    if (
      Object.keys(this.getUserMap).length === 0 ) {
      this.loadUsers();
    } else {
      const userIds = Object.keys(this.getUserMap);
      if (userIds.length === 1) this.form.userId = userIds.pop();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("data", ["loadCasePeopleAttributions"]),
    ...mapMutations("data", ["putUserMap"]),
    loadUsers() {
      this.loading = true;
      getUsersOverview().then((response) => {
        const usersById = {};
        response.forEach((e) => usersById[e.id] = e);
        this.putUserMap(usersById);
        if (response.length === 1) {
          this.form.userId = response[0].id;
        }
      }).catch((ex) => {
        this.$notifyError("Loading LEO Data Failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    createUserCaseAttribute() {
      if (this.associate) this.form.role = "0";
      if (
        !isDefined(this.form.userId) ||
        this.form.userId === "" ||
        !isDefined(this.form.role) ||
        this.form.role === ""
      ) {
        this.$notifyWarn("Please select both Person and Role");
        return;
      }
      this.loading = true;
      if (!isDefined(this.caseIdInt)) {
        this.$notifyError("Failed to assigning LEO", ex);
      } else {
        this.doCreateUserCaseAttribute(this.caseIdInt);
      }
    },
    doCreateUserCaseAttribute(caseId) {
      attributeUser(
        this.form.userId,
        this.form.role,
        undefined,
        caseId,
        this.evidenceIdInt,
        this.incidentIdInt
      ).then(() => {
        this.$emit("created", {userId: this.form.userId, mapAll: this.form.mapAll});
        this.loadCasePeopleAttributions({caseId});
        if (this.$route.name === ethosRouteNames.CasePeople ) {
          this.reset();
          return;
        }
        this.onClose(false);
        if (this.isCaseOnly) {
          this.$router.push({name: ethosRouteNames.CasePeople, params: {caseId}});
        }
      }).catch((ex) => {
        this.$notifyError("Failed to assigning LEO", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    assignSingle() {
      this.form.mapAll = false;
      this.createUserCaseAttribute();
    },
    assignAll() {
      this.form.mapAll = true;
      this.createUserCaseAttribute();
    },
    reset() {
      this.showModal = false;
      this.loading = false;
      this.form.userId = "";
      this.form.role = "";
      this.form.mapAll = false;
    },
    onClose(showParent) {
      this.reset();
      this.$emit("close", showParent);
    },
  },
};
</script>

<style></style>
