import {IncidentType} from "../util/enums";
import {jsonRequest, jsonRequestPaginated, isDefined, GET, POST, PUT} from "./helpers";


export const getIncident = async (incidentId) => {
  if (!isDefined(incidentId)) throw new Error("Incident Id Required");
  return await jsonRequest(GET, `Incidents/${incidentId}/`);
};

export const getIncidents = async (
  pageNumber, pageSize, filter, archived,
  evidenceId, noteId, peopleId, projectId
) => {
  let query = archived === true ? "&Archived=true" : "";

  if (isDefined(evidenceId)) query += `&evidenceId=${evidenceId}`;
  if (isDefined(noteId)) query += `&noteId=${noteId}`;
  if (isDefined(peopleId)) query += `&peopleId=${peopleId}`;
  if (isDefined(projectId)) query += `&projectId=${projectId}`;

  return await jsonRequestPaginated(
    GET,
    "Incidents",
    {pageNumber, pageSize, filter},
    query
  );
};

export const getAllIncidents = async () => {
  return await jsonRequest(GET, "Incidents/GetAll");
};

export const postIncident = async (
  title, type, description, color, startTime, endTime, startOffset, endOffset, transcriptionId, address,
  createClip, startTimeOffset, endTimeOffset
) => {
  if (!isDefined(title)) throw new Error("Title Required");
  if (!isDefined(type)) throw new Error("Type Required");

  if (type !== IncidentType.transcript && type !== IncidentType.timeline) throw new Error("Type Invalid");
  if (type === IncidentType.timeline) {
    if (!isDefined(startTime) || !isDefined(endTime)) throw new Error("Invalid Times");
    if (new Date(endTime) <= new Date(startTime)) throw new Error("End is before start");
  }
  if (type === IncidentType.transcript) {
    if (!isDefined(transcriptionId)) throw new Error("Missing Id");
    if (!isDefined(startOffset) || !isDefined(endOffset)) throw new Error("Invalid Offsets");
    if (endOffset <= startOffset) throw new Error("End is before start");
  }

  const data = {
    title,
    type,
    description,
    color,
    startTime: isDefined(startTime) ? startTime : null,
    endTime: isDefined(endTime) ? endTime : null,
    startOffset: isDefined(startOffset) ? startOffset : null,
    endOffset: isDefined(endOffset) ? endOffset : null,
    transcriptionId: isDefined(transcriptionId) ? transcriptionId : null,
    address,
  };
  if (createClip === true) {
    data.createClip = true;
    if (!isDefined(startTimeOffset) || !isDefined(endTimeOffset) || endTimeOffset <= 0) throw new Error("Invalid Time Offsets");
    data.startTimeOffset = startTimeOffset;
    data.endTimeOffset = endTimeOffset;
  }
  return await jsonRequest(POST, "Incidents", {
    body: JSON.stringify(data),
  });
};

export const putIncident = async (
  incidentId, title, description, color, startTime, endTime, startOffset, endOffset, address
) => {
  if (!isDefined(incidentId)) throw new Error("Incident Id Required");

  const data = {};
  if (isDefined(title)) data.title = title;
  if (isDefined(description)) data.description = description;
  if (isDefined(color)) data.color = color;
  if (isDefined(startTime)) data.startTime = startTime;
  if (isDefined(endTime)) data.endTime = endTime;
  if (isDefined(startOffset)) data.startOffset = startOffset;
  if (isDefined(endOffset)) data.endOffset = endOffset;
  if (isDefined(address)) data.address = address;
  if (Object.keys(data).length < 1) throw new Error("No updates");

  return await jsonRequest(PUT, `Incidents/${incidentId}`, {
    body: JSON.stringify(data),
  });
};

export const archiveIncident = async (incidentId, archive) => {
  if (!isDefined(incidentId)) throw new Error("Incident Id Required");
  if (!isDefined(archive)) throw new Error("achive(true|false) required");
  const data = {
    archive,
  };
  return await jsonRequest(POST, `Incidents/${incidentId}/ArchiveIncident`, {
    body: JSON.stringify(data),
  });
};

export const getIncidentArchiveCount = async () => {
  return await jsonRequest(GET, "Incidents/Archived/Count");
};

export const linkIncidentTo = async (incidentId, evidenceIds, noteIds, peopleIds, projectIds) => {
  if (!isDefined(incidentId)) throw new Error("Incident Id Required");
  const hasEvidence = isDefined(evidenceIds) && evidenceIds.length > 0;
  const hasNotes = isDefined(noteIds) && noteIds.length > 0;
  const hasPeople = isDefined(peopleIds) && peopleIds.length > 0;
  const hasProjects = isDefined(projectIds) && projectIds.length > 0;
  if (!hasEvidence && !hasNotes && !hasPeople && !hasProjects) throw new Error("changes required");
  const data = {};
  if (hasEvidence) data.evidenceIds = evidenceIds;
  if (hasNotes) data.noteIds = noteIds;
  if (hasPeople) data.peopleIds = peopleIds;
  if (hasProjects) data.projectIds = projectIds;
  return await jsonRequest(POST, `Incidents/${incidentId}/Link`, {
    body: JSON.stringify(data),
  });
};
