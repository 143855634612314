<template>
  <div class="ethos-container no-bg m-3 border-top-0 bg-content">
    <el-tabs
      v-model="activeName"
      type="border-card"
    >
      <!-- TODO: - Re-Add transcription searches AND make sure panes only load when visible -->
      <el-tab-pane
        label="Transcriptions"
        name="transcriptions"
      >
        <transcriptions />
      </el-tab-pane>
      <el-tab-pane
        :label="evidenceNameLabel"
        name="evidence"
      >
        <evidence :reuse="true" />
      </el-tab-pane>
      <el-tab-pane
        :label="casesNameLabel"
        name="cases"
      >
        <cases :reuse="true" />
      </el-tab-pane>
      <el-tab-pane
        :label="projectNameLabel"
        name="projects"
      >
        <projects-list />
      </el-tab-pane>
      <el-tab-pane
        :label="peopleNameLabel"
        name="people"
      >
        <people-list />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Cases from "../Cases/Cases.vue";
import Evidence from "../Evidence/Evidence.vue";
import Transcriptions from "../Evidence/Transcriptions.vue";
import PeopleList from "../People/PeopleList.vue";
import ProjectsList from "../Projects/ProjectsList.vue";

export default {
  components: {Cases, Evidence, Transcriptions, ProjectsList, PeopleList},
  name: "ethos-search",
  data() {
    return {
      activeName: "transcriptions",
    };
  },
  computed: {
    ...mapGetters("data", [
      "evidenceNameLabel",
      "casesNameLabel",
      "peopleNameLabel",
      "projectNameLabel",
    ]),
  },
  methods: {
    ...mapMutations("data", [
      "setSearchKeyword",
    ]),
  },
  destroyed() {
    this.setSearchKeyword({keyword: ""});
    const searchBar = document.querySelector(".ethosSearch");
    if (searchBar) {
      searchBar.value = "";
    }
  },
};
</script>
