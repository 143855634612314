<template>
  <card class="case-evidence p-5 mb-5">
    <div class="d-flex">
      <div class="photo">
        <img src="img/mike.jpg" />
      </div>
      <div class="pl-3 flex-grow-1">
        <p class="case-evidence-title font-16 font-weight-bold">Jim Smith</p>
        <p class="case-evidence-description mb-0 font-weight-normal">
          New evidence update 00/00/0000 00:00AM
        </p>
        <p class="case-evidence-description mb-0">
          Description of what was added goes here. If the description reaches
          the max limit, truncate with [...]
        </p>
      </div>
      <div>
        <base-button type="primary" class="m-0" link>View</base-button>
      </div>
    </div>
  </card>
</template>

<script>
import BaseButton from "../../BaseButton.vue";
import Card from "../../Cards/Card.vue";
export default {
  components: { Card, BaseButton },
};
</script>

<style lang="scss">
.case-evidence {
  .photo {
    min-width: 36px;
    img {
      height: 36px;
      border-radius: 36px !important;
    }
  }
  width: 100%;
  &-description {
    overflow: hidden;
  }
}
</style>
