import {jsonRequest, isDefined, GET, PUT, POST} from "./helpers";

export const addNote = async (evidenceId, projectId, title, content) => {
  if (!isDefined(evidenceId) && !isDefined(projectId)) throw new Error("At least one of evidenceId or projectId is Required");
  if (!isDefined(title)) throw new Error("Title Required");
  if (!isDefined(content)) throw new Error("Content Required");
  const data = {
    evidenceId,
    projectId,
    title,
    content: JSON.stringify(content),
  };
  return await jsonRequest(POST, "Notes", {body: JSON.stringify(data)});
};

export const getAllNotes = async () => await jsonRequest(GET, "Notes");
export const getProjectNotes = async (projectId) => {
  if (!isDefined(projectId)) throw new Error("projectId required");
  return await jsonRequest(GET, `Notes/Project/${projectId}`);
};
export const getEvidenceNotes = async (evidenceId) => {
  if (!isDefined(evidenceId)) throw new Error("evidenceId required");
  return await jsonRequest(GET, `Notes/Evidence/${evidenceId}`);
};
export const getNoteById = async (id) => {
  if (!isDefined(id)) throw new Error("Id Required");
  return await jsonRequest(GET, `Notes/${id}`);
};

export const updateNote = async (
  noteId, evidenceId, projectId, title, content
) => {
  if (!isDefined(noteId)) throw new Error("id Required");
  if (!isDefined(evidenceId) && !isDefined(projectId) && !isDefined(title) && !isDefined(content)) throw new Error("Nothing to update");
  const data = {};
  if (isDefined(evidenceId)) data.evidenceId = evidenceId;
  if (isDefined(projectId)) data.projectId = projectId;
  if (isDefined(title)) data.title = title;
  if (isDefined(content)) data.content = content;

  // We use JSON.stringify to convert the data of title and content to something we can send
  return await jsonRequest(PUT, `Notes/${noteId}`, {body: JSON.stringify(data)});
};

export const getNoteIncidents = async (noteId) => {
  if (!isDefined(noteId)) throw new Error("Note Id Required");
  return await jsonRequest(GET, `Notes/${noteId}/Incidents`);
};
