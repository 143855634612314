<template>
  <div
    v-if="transcript"
    class="audio-transcript flex-grow-1"
  >
    <div class="d-flex col h-100">
      <div class="transcript col-9 d-flex flex-column">
        <div
          v-if="$refs.transcription && $refs.transcription.isEditMode"
          class="edit-toolbar"
        >
          <base-button
            class="mr-2"
            size="xxs"
            icon
            simple
            @click="() => $refs.transcription && $refs.transcription.saveEdits()"
          >
            <save-icon size="1.1x" />
          </base-button>
          <base-button
            size="xxs"
            icon
            simple
            type="danger"
            @click="() => $refs.transcription && $refs.transcription.cancelEdits()"
          >
            <x-icon size="1.1x" />
          </base-button>
        </div>
        <tokenized-transcription-content
          ref="transcription"
          :content="transcript.content"
          :metadata="transcript.metadata"
          :transcription-id="transcript.id"
          :transcription-name="transcript.name"
          :evidence-id="evidenceId"
          :incidents="transciptionIncidents"
          :current-playtime="currentPlaytime"
          :keyword-indices="keywordIndices"
          :keyword-current-index="keywordCurrentIndex"
          :code-word-indices="codeWordIndices"
          :clip="clip"
          :signal-value="removeSignal"
          :unclear-value="removeUnclear"
          @updated="transcriptUpdated"
          @incident="updatedIncident"
          @seek="seekTo"
          @contentUpdated="contentUpdated"
          @selectSpeaker="selectSpeaker"
          @index="(ind) => $refs.contentSearch && $refs.contentSearch.setCurrent(ind)"
          @updatedClip="updatedClip"
          @spanPlaying="maybeScrollView"
        />
        <template
          v-if="hasSignedUrl"
        >
          <div
            v-if="isAutoScrollPaused == true && $refs.controls && $refs.controls.isPlaying == true"
            class="as-indicator"
          >
            <base-button
              size="xxs"
              class="as-indicator-button mt-1"
              :loading="loading"
              @click="resumeAutoScroll"
            >
              <pause-icon
                size="2x"
                class="pr-2"
              />
              Auto scroll paused
              <x-icon
                size="1.7x"
                class="pl-2"
              />
            </base-button>
          </div>
          <div class="h-divider mt-1" />
          <div class="audio-player mt-2">
            <custom-media-controls
              v-if="transcript.signedUrl"
              ref="controls"
              :key="transcript.signedUrl"
              :sources="[{
                id: transcript.id,
                source: transcript.signedUrl,
                isAudio: true,
                offset: 0,
              }]"
              :play-back="transcript.playback"
              :incidents="mappedIncidents"
              :signals="codeWordTimeStamps"
              :can-clip="!isClip"
              :clip-offset="clip ? clip.startOffset : -1"
              @created="appendMediaElement"
              @destroying="removeMediaElement"
              @time="updateTime"
              @incident="(i) => $refs.transcription && $refs.transcription.editIncident(i)"
              @saveClip="(c) => $refs.transcription && $refs.transcription.saveClip(c)"
              @export="(e) => $refs.transcription && $refs.transcription.export(e)"
              @editMetadata="(e) => $refs.transcription && $refs.transcription.editMetadata(e)"
              @onClipRange="(i) => $refs.transcription && $refs.transcription.onClipRange(i)"
            />
          </div>
        </template>
      </div>
      <div
        v-if="hasTranscript"
        class="col-3 d-flex flex-column overflow-auto p-0"
      >
        <el-tabs
          v-model="activeName"
          type="border-card"
          class="flex-grow-1 pt-3"
        >
          <el-tab-pane
            label="Signals"
            name="signals"
          >
            <transcription-signals
              :key="transcript.content"
              :transcript-content="transcript.content"
              :metadata="transcript.metadata"
              class="col"
              @indices="(i) => codeWordIndices = i"
              @current="(i) => codeWordCurrentIndex = i"
              @removeSignal="onRemoveSignal"
            />
          </el-tab-pane>

          <el-tab-pane
            label="Keyword Search"
            name="keywordSearch"
          >
            <content-search
              ref="contentSearch"
              :key="transcript.content"
              :transcript-content="transcript.content"
              class="col"
              @scrollToNext="scrollToNext"
              @indices="(i) => keywordIndices = i"
              @current="(i) => keywordCurrentIndex = i"
            />
          </el-tab-pane>

          <el-tab-pane
            :label="`Unclear Words(${unclearWordsCount})`"
            name="unclearWords"
          >
            <unclear-words
              :key="transcript.content"
              :content="transcript.content"
              :metadata="transcript.metadata"
              :transcription-id="transcript.id"
              class="col"
              @count="setUnclearWordsCount"
              @removeUnclear="onRemoveUnclear"
            />
          </el-tab-pane>
        </el-tabs>
        <div class="h-divider mx-4 mb-4" />
        <div class="assistant-bottom">
          <div class="row mx-5 align-items-baseline">
            <div class="avatar avatar-sm">
              {{ transcript.metadata.confidence }}
            </div>
            <div class="avatar-label">
              Confidence Score
            </div>
          </div>
          <div class="row ml-4 mr-1 align-items-baseline d-none">
            <base-button
              type="primary"
              style="font-weight:400;"
              :disabled="isClip"
              link
              @click="() => $refs.evidenceForm.display()"
            >
              Reprocess Analytics
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <add-new-evidence
      ref="evidenceForm"
      :evidence-id="evidenceId"
      :evidence-name="evidenceName"
      just-workflows
      header="ReProcess !!!"
    />
    <people-form
      ref="speakerForm"
      header="Assign Speaker"
      single
      allow-map-matching
      :evidence-id="evidenceId"
      @created="speakerChanged"
      @updated="speakerChanged"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {getAudioTranscript, getClip, getEvidenceIncidents} from "../../../../api";
import TokenizedTranscriptionContent from "../AudioTranscript/TokenizedTranscriptionContent.vue";
import UnclearWords from "./UnclearWords.vue";
import ContentSearch from "./ContentSearch.vue";
import TranscriptionSignals from "./TranscriptionSignals.vue";
import CustomMediaControls from "./CustomMediaControls.vue";
import PeopleForm from "../../People/PeopleForm/PeopleForm.vue";
import AddNewEvidence from "../AddNewEvidence.vue";
import "src/assets/sass/custom/audio-transcript.scss";
import {isDefined} from "../../../../api/helpers";
import {ethosRouteNames} from "../../../../routes/routeNames";
import {PauseIcon, XIcon, SaveIcon} from "vue-feather-icons";

export default {
  name: "audio-transcript",
  components: {
    TokenizedTranscriptionContent,
    ContentSearch,
    ContentSearch,
    UnclearWords,
    AddNewEvidence,
    PeopleForm,
    TranscriptionSignals,
    CustomMediaControls,
    PauseIcon,
    XIcon,
    SaveIcon,
  },
  data() {
    return {
      transcript: null,
      clip: null,
      loading: false,
      hasTranscript: false,
      updateInterval: null,
      activeName: "keywordSearch",
      unclearWordsCount: 0,
      originalSpeaker: null,
      selectSpeakerCallback: () => {},
      currentPlaytime: 0,
      incidents: [], // TODO: - Add these to store
      keywordIndices: [],
      keywordCurrentIndex: -1,
      codeWordIndices: [],
      codeWordCurrentIndex: -1,
      isScrolling: false,
      lastUserInvokedScrollAt: null,
      lastScrollTopPos: 0,
      isAutoScrollPaused: false,
      now: Date.now(),
      nowInterval: null,
      removeSignal: false,
      removeUnclear: false,
    };
  },
  watch: {
    reloadKey: {
      handler(n, o) {
        if (isDefined(n) && (!isDefined(o) || n !== o)) this.init();
      },
      immediate: true,
    },
    hasSignedUrl(to, from) {
      if (to && !from) {
        this.$nextTick(this.bindRefs);
      }
    },
    shouldAutoScrollPause(n, o) {
      if (n != o && n == true ) {
        this.isAutoScrollPaused = true;
      } else {
        this.isAutoScrollPaused = false;
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getTranscriptions",
      "visibleTranscriptions",
      "evidenceNameLabel",
      "incidentNameLabel",
      "breadcrumbHints",
    ]),
    shouldAutoScrollPause() {
      if (this.$refs.controls && !this.$refs.controls.isPlaying) return false;
      // console.log("computed", this.now - this.lastUserInvokedScrollAt, 1000 * 10, this.$refs.transcription && this.$refs.transcription.editingSpeakerAt);
      const withinThreshold = this.now - this.lastUserInvokedScrollAt <= 1000 * 10; // 10 secs
      const isEditing = this.$refs.transcription && this.$refs.transcription.editingSpeakerAt != -1;
      return withinThreshold || isEditing;
    },
    evidenceId() {
      const id = this.$route.params.evidenceId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    clipId() {
      const id = this.$route.params.clipId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    isClip() {
      return isDefined(this.clipId);
    },
    hasSignedUrl() {
      return this.transcript && this.transcript.signedUrl;
    },
    codeWordTimeStamps() {
      if (!this.hasTranscript) return [];
      const spans = this.$refs.transcription && this.$refs.transcription.spans;
      if (!spans) return [];
      return this.codeWordIndices.map((cwi) => {
        const matchingSpan = spans.find((s) => s.start <= cwi && cwi <= s.end);
        if (!matchingSpan) return null;
        return matchingSpan;
      }).filter((t) => t !== null);
    },
    reloadKey() {
      if (!isDefined(this.evidenceId)) return undefined;
      return this.evidenceId + "___" + this.clipId;
    },
    evidenceName() {
      return this.breadcrumbHints[ethosRouteNames.ViewEvidence];
    },
    transciptionIncidents() {
      // If incident is archived (archive = true), then it doesn't get shown. Only show if archive = false.
      // For some reason, the archive resets each time we reload the page
      return this.incidents.filter((i) => {
        return (
          i.transcriptionId == this.transcript.id &&
          i.archive === false &&
          (!isDefined(this.clip) || (i.end > this.clip.startOffset && i.start < this.clip.endOffset))
        );
      });
    },
    mappedIncidents() {
      if (!this.hasTranscript) return [];
      const spans = this.$refs.transcription && this.$refs.transcription.spans;
      if (!spans) return [];
      return (isDefined(this.clip) ? this.transciptionIncidents : this.incidents).map((i) => {
        if (i.type === "timeline") {
          i.start = i.startTime;
        } else {
          const matchingSpans = spans.filter((s) => s.start < i.endOffset && s.end > i.startOffset);
          let start = -1;
          matchingSpans.forEach((s) => {
            if (start === -1 || s.startOffset < start) start = s.startOffset;
          });
          i.start = start;
        }
        return i;
      });
    },
  },
  onDestroy() {
    if (this.updateInterval) window.clearInterval(this.updateInterval);
    if (this.nowInterval) clearInterval(this.nowInterval);
  },
  mounted() {
    this.startNowInterval();
  },
  beforeDestroy() {
    const scrollerEl = document.querySelector(".evidence-content");
    if (scrollerEl) {
      scrollerEl.removeEventListener("mousewheel", this.onMouseWheel);
    }
    window.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    ...mapMutations("data", [
      "putTranscription",
    ]),
    init() {
      // not sure why this was here, it checks for transcriptions with an evidenceId
      // which is incorrect since getTranscriptions is keyed by transcriptionId.
      // commenting out for now to fix clips not working on fulton instance
      // jk - 6/24/23
      // if (this.hasParentTranscription()) {
      //   this.transcript = this.getTranscriptions[this.evidenceId];
      // } else {
      this.loadTranscriptions().then(() => {
        // All Good
        const scrollerEl = document.querySelector(".evidence-content");
        if (scrollerEl) {
          scrollerEl.addEventListener("mousewheel", this.onMouseWheel);
        }
        window.addEventListener("keyup", this.onKeyUp);
      }).catch((ex) => {
        this.loading = false;
        this.$notifyError("Audio Transcript request failed", ex);
      });
      //  }
    },
    hasParentTranscription() {
      return (
        Object.keys(this.getTranscriptions).indexOf(`${this.evidenceId}`) > -1
      );
    },
    async loadTranscriptions() {
      if (this.loading) return;
      this.loading = true;
      if (isDefined(this.clipId)) {
        this.clip = await getClip(this.evidenceId, this.clipId);
      }
      const transcript = await getAudioTranscript(this.evidenceId);
      this.putTranscription(transcript); // put the original
      if (isDefined(this.clip)) {
        // If there's a clip - manipulate the transcript metadata
        const clipEntries = transcript.metadata.entries.filter((e) => {
          return (
            this.clip.startOffset < (e.et * 1000) + e.eTm &&
            this.clip.endOffset > (e.st * 1000) + e.sTm
          );
        });
        const first = clipEntries.length > 0 ? clipEntries[0].s : 0;
        const last = clipEntries.length > 1 ? clipEntries[clipEntries.length - 1].e : transcript.content.length;
        transcript.metadata.entries = clipEntries;

        // trim the content - padding blank at start to keep indicies
        transcript.content = transcript.content.slice(first, last).padStart(last);

        // Update the speakers too
        const clipSpeakers = transcript.metadata.speakers.filter((s) => s.s < last);
        let firstSpeaker = 0;
        for (let s = 0; s < clipSpeakers.length; s++) {
          // Get rid of excess speakers
          if (clipSpeakers[s].s <= first) firstSpeaker = s;
        }
        transcript.metadata.speakers = clipSpeakers.slice(firstSpeaker);
        transcript.signedUrl = this.clip.signedUrl;
      }
      // Store it locally
      this.transcript = transcript;
      this.loading = false;
      this.loadIncidents();
    },
    loadIncidents() {
      getEvidenceIncidents(this.evidenceId)
        .then((incidents) => {
          this.incidents = incidents;
        })
        .catch((ex) => {
          this.$notifyError(`Failed to load ${incidentNameLabel}`, ex);
        });
    },
    bindRefs() {
      if (this.hasTranscript) return;
      if (!this.$refs.transcription) return;
      this.hasTranscript = true;
    },
    bindTranscriptionClick() {
      this.$refs.transcription.bindClicks();
    },
    seekTo(offset) {
      this.$refs.controls.seekTo(offset);
    },
    updateTime(t) {
      this.currentPlaytime = t;
    },
    setUnclearWordsCount(count) {
      this.unclearWordsCount = count;
    },
    async transcriptUpdated(transcript) {
      if (!transcript || !this.transcript) return;
      this.putTranscription(transcript);
      this.transcript = transcript;

      // Just populate what we need to keep the audio element around
      // this.transcript.content = transcript.content;
      // this.transcript.metadata = transcript.metadata;
      // this.transcript.modifiedOn = transcript.modifiedOn;
      // this.transcript.modifiedBy = transcript.modifiedBy;
    },
    contentUpdated(newContent) {
      this.transcript.content = newContent;
    },
    selectSpeaker(options) {
      this.originalSpeaker = options.id;
      this.selectSpeakerCallback = (p, shouldMapMatching) => {
        options.callback(options, p, shouldMapMatching);
      };
      this.$refs.speakerForm.display();
    },
    speakerChanged(p) {
      if (this.selectSpeakerCallback) {
        this.selectSpeakerCallback(p, this.$refs.speakerForm && this.$refs.speakerForm.shouldMapMatching);
        this.originalSpeaker = null;
        this.selectSpeakerCallback = () => {};
      }
    },
    appendMediaElement(element) {
      const el = element && element.el;
      if (el) {
        if (el.parentNode) el.parentNode.removeChild(el);
        el.controls = false;
        document.body.appendChild(el); // TODO: - Choose container when there's video
      }
    },
    removeMediaElement(element) {
      const el = element && element.el;
      if (el) {
        if (el.parentNode) el.parentNode.removeChild(el);
      }
    },
    updatedIncident(incident) {
      let matching = false;
      for (let i = 0; i < this.incidents.length; i++) {
        if (!matching && this.incidents[i].id === incident.id) {
          this.incidents[i] = Object.assign(this.incidents[i], incident);
          matching = true;
        }
      }
      if (!matching) {
        this.incidents.push(incident);
      }
    },
    updatedClip(clip) {
      if (this.$refs.controls) this.$refs.controls.endClipping();
      console.log("Handle", clip);
    },
    scrollToNext(e) {
      const currentIndex = this.keywordIndices[this.keywordCurrentIndex];
      const element = document.getElementById(currentIndex);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    },
    maybeScrollView(el) {
      if (this.isScrolling || this.isAutoScrollPaused) return;
      const scrollerEl = document.querySelector(".evidence-content");
      if (scrollerEl) {
        const dim = this.getVisibility(el, scrollerEl);
        if (!dim.isVisible) {
          this.scrollTo(scrollerEl, scrollerEl.scrollTop + dim.nodeTop - scrollerEl.clientHeight * 0.85);
        } else if (dim.nearBottom > 0.93) {
          this.scrollTo(scrollerEl, scrollerEl.scrollTop + scrollerEl.clientHeight * 0.85);
        }
      }
    },
    scrollTo(el, scrollTo, scrollDuration) {
      this.isScrolling = true;
      const anchorHeightAdjust = 30;
      if (scrollTo > anchorHeightAdjust) {
        scrollTo = scrollTo - anchorHeightAdjust;
      }

      // Set a default for the duration
      if ( typeof scrollDuration !== "number" || scrollDuration < 0 ) {
        scrollDuration = 750;
      }
      const cosParameter = (el.scrollTop - scrollTo) / 2;
      let scrollCount = 0;
      let oldTimestamp = window.performance.now();

      const step = (newTimestamp) => {
        let tsDiff = newTimestamp - oldTimestamp;

        // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
        // on the first go-through than we want so I'm adjusting the difference down here.
        // Regardless, we would rather have a slightly slower animation than a big jump so a good
        // safeguard, even if we're not using the polyfill.
        if (tsDiff > 100) {
          tsDiff = 30;
        }
        scrollCount += Math.PI / (scrollDuration / tsDiff);
        // As soon as we cross over Pi, we're about where we need to be
        if (scrollCount >= Math.PI) {
          this.isScrolling = false;
          return;
        }

        const moveStep = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));
        el.scrollTo(0, moveStep);
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      };

      window.requestAnimationFrame(step);
    },
    getVisibility(node, referenceNode) {
      referenceNode = referenceNode || node.parentNode;
      const pos = node.getBoundingClientRect();
      const containerPos = referenceNode.getBoundingClientRect();
      let isVisible = false;
      let nearTop = pos.y <= containerPos.top ? 1 : 0;
      let nearBottom = pos.y >= containerPos.bottom ? 1 : 0;
      const nodeTop = pos.y;
      if (pos.y >= containerPos.top &&
        pos.y <= containerPos.bottom &&
        pos.x >= containerPos.left &&
        pos.y <= containerPos.right)
      // eslint-disable-next-line
      {
        isVisible = true;
        nearTop = 1 - Math.abs(pos.y - containerPos.top)/containerPos.height;
        nearBottom = 1 - Math.abs(pos.y - containerPos.bottom)/containerPos.height;
      }
      return {
        "nearTop": nearTop,
        "nearBottom": nearBottom,
        "isVisible": isVisible,
        "nodeTop": nodeTop,
      };
    },
    onKeyUp(e) {
      if (e.key == "ArrowUp" || e.key == "ArrowDown" || e.key == "PageUp" || e.key == "PageDown") {
        const scrollerEl = document.querySelector(".evidence-content");
        if (this.isPlaying() && scrollerEl != null && scrollerEl.scrollTop != this.lastScrollTopPos) {
          this.lastUserInvokedScrollAt = Date.now();
        }
        this.lastScrollTopPos = scrollerEl.scrollTop;
      }
    },
    onMouseWheel(e) {
      if (this.isPlaying()) {
        this.lastUserInvokedScrollAt = Date.now();
      }
    },
    startNowInterval() {
      if (!this.nowInterval) {
        this.nowInterval = setInterval(this.updateNow.bind(this), 1000);
      }
    },
    updateNow() {
      this.now = Date.now();
    },
    resumeAutoScroll() {
      this.lastUserInvokedScrollAt = null;
      if (this.$refs.transcription && this.$refs.transcription.editingSpeakerAt != -1) {
        this.$notifyWarn("To resume auto scroll, please exit editing mode.");
      }
    },
    isPlaying() {
      return this.$refs.controls && this.$refs.controls.isPlaying;
    },
    onRemoveSignal(e) {
      this.removeSignal = !this.removeSignal;
    },
    onRemoveUnclear(e) {
      this.removeUnclear = !this.removeUnclear;
    },
  },
};
</script>

<style lang="scss">
  .edit-toolbar {
    position: sticky;
    width: 100%;
    z-index: 1000;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
</style>
