<template>
  <div>
    <case-back-div>
      <add-new-evidence-modal
      ref="addEvidence"
      :case-id="caseId"
    />
      <base-button type="simple" @click="() => $refs.addEvidence.display()">
        <PlusIcon class="mr-2" size="18"></PlusIcon>
        Add Evidence
      </base-button>
      <base-button
        link
        @click="expandAll()"
      >
        Expand All
        <LayersIcon
          class="ml-2"
          size="18"
        />
      </base-button>
    </case-back-div>
    <collapse-v-2
      :multiple-active="true"
      :active-index="2"
    >
       <collapse-v-2-item
        ref="new"
        title="New Evidence"
      >
        <badge
          slot="count"
          class="text-dark mb-0 mr-2"
          type="warning"
        >
          {{
            newTableData.length
          }}
        </badge>
        <case-evidence-actions
          v-if="newEvidenceSelected.length > 0"
          slot="actions"
          @move="() => $refs.newDirModal.showModal = true"
          @delete="() => deleteCaseEvidence('new')"
        />
        <case-evidence-list-table
          v-model="newEvidenceSelected"
          :data="newTableData"
        />
        <case-evidence-dir-modal
          ref="newDirModal"
          :case-id="caseId"
          :evidence-ids="newSelectedEvidenceIds"
          :dirs="dirs"
          @close="() => reset('new')"
        />
      </collapse-v-2-item>
      <collapse-v-2-item
        ref="recent"
        title="Recently Viewed"
      >
        <badge
          slot="count"
          class="text-dark mb-0 mr-2"
          type="warning"
        >
          {{
            recentTableData.length
          }}
        </badge>
        <case-evidence-actions
          v-if="recentEvidenceSelected.length > 0"
          slot="actions"
          @move="() => $refs.recentDirModal.showModal = true"
          @delete="() => deleteCaseEvidence('recent')"
        />
        <case-evidence-list-table
          v-model="recentEvidenceSelected"
          :data="recentTableData"
        />
        <case-evidence-dir-modal
          ref="recentDirModal"
          :case-id="caseId"
          :evidence-ids="recentSelectedEvidenceIds"
          :dirs="dirs"
          @close="() => reset('recent')"
        />
      </collapse-v-2-item>
      <collapse-v-2-item
        ref="all"
        title="All Evidence"
      >
        <badge
          slot="count"
          class="text-dark mb-0 mr-2"
          type="warning"
        >
          {{
            allTableData.length
          }}
        </badge>
        <case-evidence-actions
          v-if="allEvidenceSelected.length > 0"
          slot="actions"
          hide-move
          hide-delete
        />
        <case-evidence-table
          v-model="allEvidenceSelected"
          :data="allTableData"
        />
      </collapse-v-2-item>
    </collapse-v-2>
  </div>
</template>

<script>
import CollapseV2Item from "../../../components/DashboardV2/CollapseV2/CollapseV2Item.vue";
import CollapseV2 from "../../../components/DashboardV2/CollapseV2/CollapseV2.vue";
import CaseEvidenceTable from "../../../components/DashboardV2/Case/CaseEvidenceTable.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import BaseButton from "../../../components/BaseButton.vue";
import { LayersIcon, PlusIcon } from "vue-feather-icons";
import Badge from "../../../components/Badge.vue";
import CaseEvidenceActions from "../../../components/DashboardV2/Case/CaseEvidenceActions.vue";
import {deleteCaseEvidenceSummary, getCaseEvidenceSummaries} from "../../../api";
import {isDefined} from "../../../api/helpers";
import CaseEvidenceListTable from "../../../components/DashboardV2/Case/CaseEvidenceListTable.vue";
import {DEFAULT_EVIDENCE_DIRNAME} from "../../../util/consts";
import {mapGetters, mapMutations} from "vuex";
import {getRecentCaseEvidence} from "../../../util/util";
import CaseEvidenceDirModal from "../../../components/DashboardV2/Case/CaseEvidenceDirModal.vue";
import AddNewEvidenceModal from '../Evidence/AddNewEvidenceModal.vue';

export default {
  components: {
    CollapseV2,
    CollapseV2Item,
    CaseEvidenceTable,
    CaseBackDiv,
    BaseButton,
    LayersIcon,
    Badge,
    CaseEvidenceActions,
    CaseEvidenceListTable,
    CaseEvidenceDirModal,
    PlusIcon,
    AddNewEvidenceModal
  },
  data() {
    return {
      accord: [],
      recentEvidenceSelected: [],
      newEvidenceSelected: [],
      allEvidenceSelected: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseEvidenceSummaries"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseEvidenceSummaries() {
      if (!isDefined(this.getCaseEvidenceSummaries) || !isDefined(this.caseId)) return [];
      return this.getCaseEvidenceSummaries[this.caseId] || [];
    },
    dirs() {
      return this.caseEvidenceSummaries.reduce((a, ces) => {
        if (a.indexOf(ces.dir) === -1) a.push(ces.dir);
        return a;
      }, []);
    },
    recentEvidence() {
      const ids = getRecentCaseEvidence(this.caseId);
      return this.caseEvidenceSummaries.filter((ces) => ids.indexOf(`${ces.evidenceId}`) !== -1);
    },
    recentTableData() {
      return this.mapCseToTable(this.recentEvidence);
    },
    recentSelectedEvidence() {
      const tdata = this.recentTableData;
      return this.recentEvidenceSelected.map((i) => tdata[i].data);
    },
    recentSelectedEvidenceIds() {
      return this.recentSelectedEvidence.map((ces) => ces.evidenceId);
    },
    newEvidence() {
      return this.caseEvidenceSummaries.filter((ces) => ces.elementStatus === 0);
    },
    newTableData() {
      return this.mapCseToTable(this.newEvidence);
    },
    newSelectedEvidence() {
      const tdata = this.newTableData;
      return this.newEvidenceSelected.map((i) => tdata[i].data);
    },
    newSelectedEvidenceIds() {
      return this.newSelectedEvidence.map((ces) => ces.evidenceId);
    },
    evidenceByDir() {
      return this.caseEvidenceSummaries.reduce((a, cse) => {
        let dirName = cse.dir;
        if (!isDefined(dirName) || dirName.length === 0) {
          dirName = DEFAULT_EVIDENCE_DIRNAME;
        }
        if (isDefined(a[dirName])) {
          a[dirName].push(cse);
        } else {
          a[dirName] = [cse];
        }
        return a;
      }, {});
    },
    allTableData() {
      return Object.entries(this.evidenceByDir).map(([key, value]) => {
        return {
          name: key,
          // createdBy: "Cannot know",
          // createdOn: "Mar 22, 2023 at 12:00 PM",
          caseEvidence: value,
          caseEvidenceCount: value.length,
          exhibits: value.reduce((a, c) => a + c.clipCount, 0),
          signals: value.reduce((a, c) => a + c.incidentCount, 0),
          people: value.reduce((a, c) => a + c.actorAttributionCount + c.userAttributionCount, 0),
        };
      }).sort((a, b) => a.name === DEFAULT_EVIDENCE_DIRNAME ? 1 : -1);
    },
    allSelectedEvidence() {
      const tdata = this.allTableData;
      return this.allEvidenceSelected.map((i) => tdata[i].data);
    },
    allSelectedEvidenceIds() {
      return this.allSelectedEvidence.map((ces) => ces.evidenceId);
    },
  },
  mounted() {
    this.loadCaseEvidence();
  },
  methods: {
    ...mapMutations("data", ["putCaseEvidenceSummaries"]),
    loadCaseEvidence() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseEvidenceSummaries(this.caseId).then((response) => {
        this.putCaseEvidenceSummaries({
          caseId: this.caseId,
          summaries: response,
        });
      }).catch((ex) => {
        this.$notifyError("Failed to gather Evidence for Case", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    mapCseToTable(cses) {
      if (!isDefined(cses) || !Array.isArray(cses) || cses.length === 0) return [];
      return cses.map((cse) => {
        return {
          name: cse.name,
          data: cse,
          createdBy: cse.evidenceMetadata.createdBy,
          createdOn: cse.evidenceMetadata.createdOn,
          exhibits: cse.clipCount,
          signals: cse.incidentCount,
          people: cse.actorAttributionCount + cse.userAttributionCount,
        };
      });
    },
    expandAll() {
      this.$refs.recent.open();
      this.$refs.new.open();
      this.$refs.all.open();
    },
    reset(key) {
      this[`${key}EvidenceSelected`] = [];
      this.loadCaseEvidence();
    },
    deleteCaseEvidence(key) {
      const evidenceIds = this[`${key}SelectedEvidenceIds`].slice();
      const requests = evidenceIds.map((eid) => deleteCaseEvidenceSummary(this.caseId, eid));
      Promise.allSettled(requests).then((r) => {
        this.$notifySuccess("Removed Evidence from Case");
      }).catch((ex) => {
        this.$notifyError("Unable to delete an Evidence from the Case", ex);
      }).finally(() => {
        this.reset(key);
      });
    },
  },
};
</script>

<style></style>